import React from "react";
import MainFrontend from "./template/Main";

export default function Careers() {

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Cơ hội nghề nghiệp</h2>
                        </div>
                    </div>
                    <img src={require('./tmp/sample-header.jpg')} alt="" />
                </div>
            </header>

            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-7 col-lg-9">
                                <h3 className="entry-title">Tìm kiếm một nơi coi trọng sự phát triển và tính linh hoạt?</h3>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-12 col-md-6 col-xl-4 mt-3 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-weather-sunny"></em>
                                    <h5 className="featured-box-title">Đội ngũ</h5>
                                    <p className="featured-box-content">Chuyên gia, lãnh đạo trẻ với hơn 10 năm kinh nghiệm trong lĩnh vực bất động sản.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 mt-3 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-playlist-star"></em>
                                    <h5 className="featured-box-title">Văn hóa</h5>
                                    <p className="featured-box-content">“Brain – Hand – Sharing” đề cao năng lực tư duy sáng tạo, khả năng hành động, sự chia sẻ trong nội bộ.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 mt-3 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-school-outline"></em>
                                    <h5 className="featured-box-title">Chế độ</h5>
                                    <p className="featured-box-content">Đãi ngộ không giới hạn thu nhập với chính sách chia sẻ lợi nhuận trực tiếp.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 mt-4 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-spa-outline"></em>
                                    <h5 className="featured-box-title">Thời gian làm việc</h5>
                                    <p className="featured-box-content">Thứ 2 – Thứ 7 (1/2 ngày). 8h30: 18h00</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 mt-4 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-airplane-takeoff"></em>
                                    <h5 className="featured-box-title">Hoạt động</h5>
                                    <p className="featured-box-content">Team building, training định kỳ, bảo hiểm, du lịch, cuộc thi nội bộ...</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4 mt-4 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-account-group"></em>
                                    <h5 className="featured-box-title">Ngoài giờ</h5>
                                    <p className="featured-box-content">Phụ cấp ngoài giờ / Nghỉ bù thời gian OT</p>
                                </div>
                            </div>
                        </div>
                        <h3 className="entry-title mt-5">Về chúng tôi</h3>
                        <div className="video-wrapper status-pause dotted-pattern dotted-pattern-top-right">
                            <img src={require('./tmp/building-to-the-sky.jpg')} alt="" />
                            {/* <video id="sample-video" src="tmp/sample-video.mp4" poster="tmp/sample-video.jpg"></video> */}
                            <h4 className="video-title">Video giới thiệu Fmax</h4>
                            <a href="#sample-video" className="video-playback large"><span className="progress"></span><em className="mdi mdi-play"></em><em className="mdi mdi-pause"></em></a>
                        </div>
                        <h3 className="entry-title mt-5">Cơ hội nghề nghiệp</h3>
                        
                        <div className="career-position">
                            <div className="career-description">
                                <h4 className="career-title">CI/CD, Quản trị &amp; triển khai vận hành dự án Fmax</h4>
                                <mark className="career-required text-small">3-5 năm kinh nghiệm</mark>
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <span>Phân tích thiết kế yêu cầu nghiệp vụ, sản phẩm, thiết kế giải pháp triển khai hệ thống trên Amazon Web Service. Lập kế hoạch, quản trị triển khai hệ thống.</span>
                                    </div>
                                    <div className="col-md-3 col-lg-2 actions">
                                        <a href="/#" className="button button-primary button-small toggle-details"><em className="mdi mdi-arrow-down"></em> Ứng tuyển</a>
                                    </div>
                                </div>
                            </div>
                            <div className="career-details">
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <h5>Mô tả công việc</h5>
                                        <ul>
                                            <li>Phân tích yêu cầu nghiệp vụ, thiết kế giải pháp triển khai hệ thống trên Amazon Web Service.</li>
                                            <li>Lập kế hoạch và quản lý tiến độ của dự án.</li>
                                            <li>Quản lý phạm vi và khối lượng công việc ban đầu, các yêu cầu thay đổi của dự án.</li>
                                            <li>Triển khai cài đặt hệ thống, phát triển liên tục - tích hợp liên tục (Continous Integration -  Continuous Development).</li>
                                            <li>Vận hành hệ thống: đảm bảo hệ thống chạy liên tục 99,9% thời gian, tối ưu hệ thống để giảm chi phí vận hành, xử lý khi có sự cố.</li>
                                            <li>Đảm bảo các phương án bảo mật, an ninh và an toàn cho hệ thống.</li>
                                            <li>Báo cáo tiến độ, chất lượng và các vấn đề liên quan của dự án. </li>
                                            <li>Quản lý mã nguồn, cơ sở dữ liệu và các phiên bản hệ thống. </li>
                                            <li>Quản lý các giấy tờ, tài liệu và hồ sơ của dự án.</li>
                                        </ul>

                                        <h5>Yêu cầu công việc</h5>
                                        <ul>
                                            <li>Có kinh nghiệm quản trị dự án.</li>
                                            <li>Có kinh nghiệm về phân tích nghiệp vụ.</li>
                                            <li>Có kinh nghiệm về thiết kế hệ thống phần mềm.</li>
                                            <li>Có kinh nghiệm triển khai vận hành hệ thống hạ tầng công nghệ lớn hoặc hệ thống hạ tầng tài chính.</li>
                                            <li>Có chứng chỉ của Amazon Web Service.</li>
                                        </ul>
                                       
                                        <h6>Ứng tuyển vị trí</h6>
                                        <form action="careers.html">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="sr-only">Tên:</label>
                                                    <input type="text" name="first-name" placeholder="Tên..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Họ tên đệm:</label>
                                                    <input type="text" name="last-name" placeholder="Họ tên đệm..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Email:</label>
                                                    <input type="email" name="email" placeholder="Email..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Số điện thoại:</label>
                                                    <input type="tel" name="phone" placeholder="Số điện thoại..." />
                                                </div>
                                                <div className="col-12">
                                                    <label className="sr-only">Nội dung ghi chú:</label>
                                                    <textarea name="notes" rows="5" placeholder="Additional notes..."></textarea>
                                                </div>
                                                <div className="col-12">
                                                    <label>Upload CV:</label>
                                                    <input type="file" name="resume" />
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit"><em className="mdi mdi-check"></em> Ứng tuyển</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-3 col-lg-2 actions">
                                        <a href="/#" className="button button-primary button-small toggle-details"><em className="mdi mdi-close"></em> <span className="close-text"> Close details</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="career-position">
                            <div className="career-description">
                                <h4 className="career-title">Lập trình Backend Python</h4>
                                <mark className="career-required text-small">2-3 năm kinh nghiệm</mark>
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <span>Phân tích thiết kế Cơ sở dữ liệu, lập trình backend, API bằng ngôn ngữ Python cho hệ thống của Fmax.</span>
                                    </div>
                                    <div className="col-md-3 col-lg-2 actions">
                                        <a href="/#" className="button button-primary button-small toggle-details"><em className="mdi mdi-arrow-down"></em> Ứng tuyển</a>
                                    </div>
                                </div>
                            </div>
                            <div className="career-details">
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <h5>Mô tả công việc</h5>
                                        <ul>
                                            <li>Lập trình hệ thống backend.</li>
                                            <li>Viết API kết nối giữa các hệ thống, và phục vụ trao đổi dữ liệu với mobile & front-end.</li>
                                            <li>Xây dựng code có thể sử dụng lại và các thư viện để thuận tiện cho việc sử dụng trong tương lai.</li>
                                            <li>Thu thập và xử lí các yêu cầu thiết kế và kĩ thuật.</li>
                                            <li>Tham gia vào quá trình phân tích và thiết kế hệ thống.</li>
                                            <li>Nghiên cứu và áp dụng các công nghệ mới để tối ưu hóa hiệu quả phát triển sản phẩm.</li>
                                        </ul>

                                        <h5>Yêu cầu công việc</h5>
                                        <ul>
                                            <li>Kinh nghiệm phát triển ứng dụng/services về phía backend.</li>
                                            <li>Thành thạo lập trình với ngôn ngữ lập trình Python.</li>
                                            <li>Nắm rõ quá trình phát triển phần mềm (hiểu thiết kế chi tiết, lập trình, unit test).</li>
                                            <li>Có hiểu biết cơ bản về cơ sở dữ liệu và hệ thống: MySQL hoặc MongoDB.</li>
                                            <li>Có kiến thức về lập trình hướng đối tượng.</li>
                                        </ul>
                                       
                                        <h6>Ứng tuyển vị trí</h6>
                                        <form action="careers.html">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="sr-only">Tên:</label>
                                                    <input type="text" name="first-name" placeholder="Tên..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Họ tên đệm:</label>
                                                    <input type="text" name="last-name" placeholder="Họ tên đệm..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Email:</label>
                                                    <input type="email" name="email" placeholder="Email..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Số điện thoại:</label>
                                                    <input type="tel" name="phone" placeholder="Số điện thoại..." />
                                                </div>
                                                <div className="col-12">
                                                    <label className="sr-only">Nội dung ghi chú:</label>
                                                    <textarea name="notes" rows="5" placeholder="Additional notes..."></textarea>
                                                </div>
                                                <div className="col-12">
                                                    <label>Upload CV:</label>
                                                    <input type="file" name="resume" />
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit"><em className="mdi mdi-check"></em> Ứng tuyển</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-3 col-lg-2 actions">
                                        <a href="/#" className="button button-primary button-small toggle-details"><em className="mdi mdi-close"></em> <span className="close-text"> Close details</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="career-position">
                            <div className="career-description">
                                <h4 className="career-title">Lập trình frontend (Reactjs)</h4>
                                <mark className="career-required text-small">1-2 năm kinh nghiệm</mark>
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <span>Tham gia phát triển các dự án về Web, xây dựng các chức năng front-end của Website, Web application.</span>
                                    </div>
                                    <div className="col-md-3 col-lg-2 actions">
                                        <a href="/#" className="button button-primary button-small toggle-details"><em className="mdi mdi-arrow-down"></em> Ứng tuyển</a>
                                    </div>
                                </div>
                            </div>
                            <div className="career-details">
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <h5>Mô tả công việc</h5>
                                        <ul>
                                            <li>Tham gia phát triển các dự án về Web, xây dựng các chức năng front-end của Website, Web application.</li>
                                            <li>Triển khai giao diện HTML/CSS Javascript theo yêu cầu của khách hàng trên hệ thống website xây dựng sẵn.</li>
                                            <li>Phối hợp với các lập trình viên back-end và người thiết kế web để cải thiện tính khả dụng.</li>
                                            <li>Đảm bảo tiêu chuẩn đồ họa chất lượng cao.</li>
                                            <li>Thu thập ý kiến phản hồi và xây dựng các hướng giải quyết cho người sử dụng và khách hàng.</li>
                                            <li>Nghiên cứu, tìm hiểu các công nghệ về HTML/CSS Javascript mới nhất để áp dụng cái tiến sản phẩm.</li>
                                        </ul>

                                        <h5>Yêu cầu công việc</h5>
                                        <ul>
                                            <li>Thành thạo HTML, CSS, Boostrap, JavaScript và React.</li>
                                            <li>Nắm rõ quá trình phát triển web (thiết kế, phát triển và thực thi).</li>
                                            <li>Có kiến thức về Responsive Design.</li>
                                            <li>Có kiến thức về các quy tắc trong SEO.</li>
                                            <li>Biết sử dụng Photoshop (Hoặc Sketch).</li>
                                            <li>Có kiến thức cơ bản về UX/UI.</li>
                                        </ul>
                                       
                                        <h6>Ứng tuyển vị trí</h6>
                                        <form action="careers.html">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="sr-only">Tên:</label>
                                                    <input type="text" name="first-name" placeholder="Tên..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Họ tên đệm:</label>
                                                    <input type="text" name="last-name" placeholder="Họ tên đệm..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Email:</label>
                                                    <input type="email" name="email" placeholder="Email..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Số điện thoại:</label>
                                                    <input type="tel" name="phone" placeholder="Số điện thoại..." />
                                                </div>
                                                <div className="col-12">
                                                    <label className="sr-only">Nội dung ghi chú:</label>
                                                    <textarea name="notes" rows="5" placeholder="Additional notes..."></textarea>
                                                </div>
                                                <div className="col-12">
                                                    <label>Upload CV:</label>
                                                    <input type="file" name="resume" />
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit"><em className="mdi mdi-check"></em> Ứng tuyển</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-3 col-lg-2 actions">
                                        <a href="/#" className="button button-primary button-small toggle-details"><em className="mdi mdi-close"></em> <span className="close-text"> Close details</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="career-position">
                            <div className="career-description">
                                <h4 className="career-title">Tester</h4>
                                <mark className="career-required text-small">1-2 năm kinh nghiệm</mark>
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <span>Xem xét yêu cầu phần mềm và chuẩn bị các kịch bản thử nghiệm.</span>
                                    </div>
                                    <div className="col-md-3 col-lg-2 actions">
                                        <a href="/#" className="button button-primary button-small toggle-details"><em className="mdi mdi-arrow-down"></em> Ứng tuyển</a>
                                    </div>
                                </div>
                            </div>
                            <div className="career-details">
                                <div className="row">
                                    <div className="col-md-9 col-lg-10">
                                        <h5>Mô tả công việc</h5>
                                        <ul>
                                            <li>Xem xét yêu cầu phần mềm và chuẩn bị các kịch bản thử nghiệm.</li>
                                            <li>Thực hiện các bài kiểm tra về khả năng sử dụng phần mềm.</li>
                                            <li>Phân tích kết quả kiểm tra về tác động cơ sở dữ liệu hoặc lỗi và khả năng sử dụng.</li>
                                            <li>Chuẩn bị báo cáo về tất cả các khía cạnh liên quan đến thử nghiệm phần mềm và gửi cho nhóm thiết kế.</li>
                                            <li>Tương tác với khách hàng để hiểu các yêu cầu sản phẩm.</li>
                                            <li>Tham gia đánh giá phần mềm và cung cấp đầu vào theo yêu cầu, thiết kế sản phẩm và phát hiện các vấn đề tiềm ẩn.</li>
                                        </ul>

                                        <h5>Yêu cầu công việc</h5>
                                        <ul>
                                            <li>Kiến thức về thiết kế, kiểm thử phần mềm và thành thạo các phương pháp thử nghiệm. </li>
                                            <li>Thành thạo các kỹ thuật kiểm thử phần mềm cũng như đánh giá khả năng tương thích với các chương trình phần mềm khác nhau. </li>
                                            <li>Có kiến thức về lập trình. </li>
                                            <li>Giao tiếp tốt và kỹ năng tư duy phản biện. </li>
                                            <li>Kỹ năng tổ chức tốt và tư duy định hướng chi tiết.</li>
                                            <li>Có khả năng tự động hoá các hoạt động kiểm thử bằng cách sử dụng công cụ hoặc lập trình.</li>
                                        </ul>
                                       
                                        <h6>Ứng tuyển vị trí</h6>
                                        <form action="careers.html">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="sr-only">Tên:</label>
                                                    <input type="text" name="first-name" placeholder="Tên..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Họ tên đệm:</label>
                                                    <input type="text" name="last-name" placeholder="Họ tên đệm..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Email:</label>
                                                    <input type="email" name="email" placeholder="Email..." />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="sr-only">Số điện thoại:</label>
                                                    <input type="tel" name="phone" placeholder="Số điện thoại..." />
                                                </div>
                                                <div className="col-12">
                                                    <label className="sr-only">Nội dung ghi chú:</label>
                                                    <textarea name="notes" rows="5" placeholder="Additional notes..."></textarea>
                                                </div>
                                                <div className="col-12">
                                                    <label>Upload CV:</label>
                                                    <input type="file" name="resume" />
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit"><em className="mdi mdi-check"></em> Ứng tuyển</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-3 col-lg-2 actions">
                                        <a href="/#" className="button button-primary button-small toggle-details"><em className="mdi mdi-close"></em> <span className="close-text"> Close details</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="site-section section-call-to-action has-media dotted-pattern dotted-pattern-top-right">
                    <div className="container">
                        <h2 className="entry-title">Kiếm thêm thu nhập trong khi đầu tư vào các công việc kinh doanh có ý nghĩa.</h2>
                        <p className="no-margin-bottom">
                            <a href="/san-pham-dau-tu" className="button button-primary"><em className="mdi mdi-briefcase-outline"></em> Đầu tư ngay</a>
                            <a href="/lien-he" className="button button-light button-outline"><em className="mdi mdi-email"></em> Liên hệ ngay</a>
                        </p>
                    </div>
                    <img src={require('./tmp/sample-video.jpg')} alt="" />
                        {/* <!-- <video id="sample-video" src="tmp/sample-video.mp4" poster="tmp/sample-video.jpg" autoplay="" loop="" muted=""></video> --> */}
                </div>
            </div>
        </MainFrontend>
    );
}
