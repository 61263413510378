
import React, { useState } from "react";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import { Link, useParams } from "react-router-dom";

export default function DanhSachBangHang() {
  const { id } = useParams();
  const [params, setParam] = useState("product_id="+id);
  const [code, setCode] = useState("");
  const [filterShow, setFilterShow] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = "product_id="+id
    if (code !== '') _param += "&code=" + code
    setParam(_param);
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "code", label: "Mã căn" },
    { name: "area", label: "Diện tích (m²)" },
    { name: "don_giagoc", label: "Đơn giá gốc (/m²)" },
    { name: "giagoc", label: "Giá gốc" },
    { name: "status", label: "Trạng thái" },
    { name: "datecreate", label: "Ngày tạo" }
  ];

  return (<AkkhorLayout idrole="customer">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Danh sách bảng hàng
        </li>
      </ul>
    </div>

    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã căn</label>
              <input type="text" placeholder="Mã sản phẩm" value={code} onChange={(e) => setCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="banghang" idrole="banghang" linkaction="banghang" params={params} title="Sản phẩm" defaultLimit={20} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
