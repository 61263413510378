import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { URL_LIST_ALL } from "../configs/api";
import MainFrontend from "./template/Main";

export default function VerifyEmail() {
    const { code } = useParams();
    const [textVerify, setTextVerify] = useState(null)
    const [success, setSuccess] = useState(false)

    useEffect(
        function () {
            axios.post(URL_LIST_ALL + "verify-email", { code: code }).then((res) => {
                if (res.data.status === "success") {
                    setTextVerify(res.data.message)
                    setSuccess(true)
                } else {
                    setSuccess(false)
                    setTextVerify(res.data.message)
                    console.log(res.data.message);
                }
            }).catch((err) => console.log(err));
        }, [code]
    );

    return (
        <MainFrontend >
            <h3 className="w-100 text-center mt-3">{textVerify ? textVerify : "Xác thực tài khoản"}</h3>
            {success && <p className="w-100 text-center mt-3">Vui lòng đăng nhập để tiếp tục</p>}
        </MainFrontend>
    );
}
