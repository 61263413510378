import moment from "moment";

export const checkRole = (user, idrole, action) => {
    try {
        if (action && action !== '') {
            const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')
            if (MyRole.hasOwnProperty(idrole) && MyRole[idrole].checked === true) {
                if (MyRole[idrole].hasOwnProperty('children') && MyRole[idrole].children.hasOwnProperty(action) && MyRole[idrole].children[action].checked === true)
                    return true;
            }
        }else{
            const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')
            if (MyRole.hasOwnProperty(idrole) && MyRole[idrole].checked === true) {
                return true;
            }
        }

    } catch (err) {
        return false;
    }
    return false;
}

export const countdate = (date) => {
    let datum = Date.parse(date);
    let startDate = moment()
    let enddate = moment(datum)
    const diff = enddate.diff(startDate,'days');
    return diff;
}

export const convertPrice = (price) => {
    let res = price + " VNĐ"
    if(price >= 1000000 && price < 1000000000){
        res = (price/1000000) + " triệu VNĐ" 
    }
    if(price >= 1000000000){
        res = (price/1000000000) + " tỷ VNĐ" 
    }
    return res
}

export const countInterest = (detail) =>{
    let result = detail.interest_rate
    if(detail.type_interest ===  'Cam kết lãi suất tối thiểu'){
        result = (result + detail.ls_thuong) + '%'
    }
    return result
}