import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { URL_LIST_ALL } from "../configs/api";
import MainFrontend from "./template/Main";
import NumberFormat from "react-number-format";

export default function AccountInvestments() {
    const { user } = useSelector((state) => state.auth);
    const [listTransaction, setListTransaction] = useState(null);
    const [detail, setDetail] = useState(null);



    useEffect(() => {
        if (user && user.data.typeaccount === 'customer') {
            axios.get(URL_LIST_ALL + "dadautu", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setListTransaction(res.data.data)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
            axios.get(URL_LIST_ALL + "laisuatcanhan", user.config).then(async (res) => {
                if (res.data.status === "success") {
                   setDetail(res.data.data)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }, [user])


    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Các khoản đầu tư</h2>
                        </div>
                    </div>
                    <div className="w-100 pb-4">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <div className="card pt-3">
                                        <h6 className="pl-2 pr-2 w-100 text-center">Tổng số tiền đã đầu tư</h6>
                                        <h4 className="p-2 w-100 text-center ">
                                            <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.total_price : 0} />
                                        </h4>
                                    </div>

                                </div>
                                <div className="col-12 col-sm-4">
                                    <div className="card pt-3">
                                        <h6 className="pl-2 pr-2 w-100 text-center">Số tiền lãi hiện tại</h6>
                                        <h4 className="p-2 w-100 text-center ">
                                            <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.tonglaihientai : 0} />
                                        </h4>
                                    </div>

                                </div>
                                <div className="col-12 col-sm-4">
                                    <div className="card pt-3">
                                        <h6 className="pl-2 pr-2 w-100 text-center">Tổng giá trị thực tại</h6>
                                        <h4 className="p-2 w-100 text-center ">
                                            <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.giatrihientai : 0} />
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {user && user.data.typeaccount === "customer" ?
                <div id="content" className="site-content">
                    <main className="site-section section-main">
                        <div className="container">
                            <div className="row">
                                
                                <div className="col-12">
                                    <table className="text-center table-invest">
                                        <thead>
                                            <tr>
                                                <th>Tên sản phẩm</th>
                                                <th>Mã GD</th>
                                                <th>Số tiền đã đầu tư</th>
                                                <th>Trạng thái</th>
                                                <th>Thời gian GD</th>
                                                <th>#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listTransaction && listTransaction.map(function (item, i) {
                                                let datecreate = <Moment format="HH:mm:ss DD/MM/YYYY">{item.datecheck}</Moment>

                                                return (
                                                    <tr key={i}>
                                                        <td className="text-left"><a href={"/ca-nhan/cac-khoan-dau-tu/"+item.id}>{item.product_name}</a></td>
                                                        <td><a href={"/ca-nhan/cac-khoan-dau-tu/"+item.id}>{item.code}</a></td>
                                                        <td className="text-center"><a href={"/ca-nhan/cac-khoan-dau-tu/"+item.id}><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={item.total_price} /></a></td>
                                                        <td><a href={"/ca-nhan/cac-khoan-dau-tu/"+item.id}>{item.status}</a></td>
                                                        <td className="text-center"><a href={"/ca-nhan/cac-khoan-dau-tu/"+item.id}>{datecreate}</a></td>
                                                        <td><a href=""><i className="fas fa-eye"></i></a></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {/* <div className="pagination">
                                        <a href="account-statement.html" className="button button-outline">Xem thêm</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                :
                <Redirect to="/" />
            }
        </MainFrontend>
    );
}
