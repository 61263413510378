
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function DashboardAdmin() {
  const { user } = useSelector((state) => state.auth);
  const info = user && user.data ? user.data : null;


  return (<div className="home-dashboad">
    {info &&
      <div className="w-100 mt-1 form-info">
        <div className="row">
          <div className="col-12 bg-white-border pd-15 mb-2">
            <h3 className="w-100 text-center">Thông tin cá nhân</h3>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td><b>Họ tên: </b></td>
                  <td>{info.fullname}</td>
                </tr>
                <tr>
                  <td><b>Email: </b></td>
                  <td>{info.email}</td>
                </tr>
                <tr>
                  <td><b>Số điện thoại: </b></td>
                  <td>{info.mobile}</td>
                </tr>

              </tbody>
            </table>
            <div className="w-100 mt-2 justify-content-center">
              <Link to="/my-profile" className="btn btn-sm btn-success mr-2 mt-2">
                Thông tin chi tiết
              </Link>
              {/* <Link to="/lay-mat-khau" className="btn btn-sm btn-warning mr-2 mt-2 text-white">
                Lấy mật khẩu ứng dụng
              </Link> */}
              {/* <Link to="/doi-mat-khau" className="btn btn-sm btn-info mr-2 mt-2 text-white">
                Đổi mật khẩu ứng dụng
              </Link> */}
            </div>
          </div>
         
        </div>
      </div>
    }
  </div>)
}