
import React, { useState } from "react";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link } from "react-router-dom";

export default function DanhSachSanPham() {

  const [params, setParam] = useState();
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [title, setTitle] = useState('');
  const [code, setCode] = useState("");
  const [filterShow, setFilterShow] = useState(false)

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (title !== '') _param += "&title=" + title
    if (code !== '') _param += "&code=" + code
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên sản phẩm" },
    { name: "code", label: "Mã sản phẩm" },
    { name: "soluong", label: "Tổng số lượng" },
    { name: "number_invested", label: "Số lượng đã đầu tư" },
    { name: "status", label: "Trạng thái" },
    { name: "datecreate", label: "Ngày tạo" }

  ];

  return (<AkkhorLayout idrole="customer">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Danh sách sản phẩm
        </li>
      </ul>
    </div>

    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
              <label>Tên sản phẩm</label>
              <input type="text" placeholder="Tên sản phẩm" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã sản phẩm</label>
              <input type="text" placeholder="Mã sản phẩm" value={code} onChange={(e) => setCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>

            <div className="col-md-12 col-12 form-group">
              <label> Thời gian tạo </label>
              <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: "", }}
                onCallback={handleCallback}
              >
                <div className='d-flex align-items-center'>
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Từ ngày'
                    readOnly={true}
                    onChange={(e) => setStartDate(e)}
                    value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                  />
                  <span className='fas fa-arrow-right mx-4' />
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Đến ngày'
                    readOnly={true}
                    onChange={(e) => setEndDate(e)}
                    value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                  />
                </div>
              </DateRangePicker>
            </div>
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="sanpham" idrole="san-pham" linkaction="san-pham" params={params} title="Sản phẩm" defaultLimit={20} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
