import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import Avatar from "../../assets/img/figure/admin.jpg";
import { resetRedux } from "../../redux/Actions";
import { actions } from "../../redux/authRedux";
const _ = require('lodash');
//904832009
export default function SiderBar(props) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const info = user && user.data ? user.data : null;
    const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')
    const location = useLocation();
    let localsegment = location.pathname;

    let listMenus = [
        { title: 'Bảng điều khiển', icon: 'flaticon-dashboard', link: '/dashboard' },
        {
            title: 'Sản phẩm', icon: 'fas fa-cogs', link: '', active: ['/san-pham','/du-an','product_meta'].includes(localsegment), children: [
                { title: 'Danh sách sản phẩm', icon: 'fas fa-angle-right', link: '/san-pham' },
                { title: 'Danh sách dự án', icon: 'fas fa-angle-right', link: '/du-an' },
                // { title: 'Danh sách số liệu', icon: 'fas fa-angle-right', link: '/product_meta' },
                { title: 'Quản lý bảng hàng', icon: 'fas fa-angle-right', link: '/banghang' },
                { title: 'Quản lý chi phí', icon: 'fas fa-angle-right', link: '/chiphi' },
            ]
        },
       
        { title: 'Khách hàng', icon: 'fas fa-users', link: '/customer' },
        { title: 'Quản lý lãi suất', icon: 'fas fa-cogs', link: '/tongquan-laisuat' },
        { title: 'Giao dịch', icon: 'fas fa-users', link: '/transaction' },
        {
            title: 'Tin tức', icon: 'fas fa-cogs', link: '', active: ['/danh-muc-tin-tuc','/tin-tuc'].includes(localsegment), children: [
                { title: 'Danh mục tin', icon: 'fas fa-angle-right', link: '/danh-muc-tin-tuc' },
                { title: 'Danh sách tin', icon: 'fas fa-angle-right', link: '/tin-tuc' },
            ]
        },
        { title: 'Tài khoản', icon: 'flaticon-user', link: '/list-users' },
        {
            title: 'Cài đặt hệ thống', icon: 'fas fa-cogs', link: '', active: ['/list-settings','/test-email','/danh-sach-gui-mail','/email-body'].includes(localsegment), children: [
                { title: 'Cài đặt thông số', icon: 'fas fa-angle-right', link: '/list-settings' },
                { title: 'Phân quyền', icon: 'fas fa-angle-right', link: '/list-roles' },
                { title: 'Nội dung email', icon: 'fas fa-angle-right', link: '/email-body' },
                { title: 'Test gửi mail', icon: 'fas fa-angle-right', link: '/test-email' },
                { title: 'Danh sách gửi mail', icon: 'fas fa-angle-right', link: '/danh-sach-gui-mail' },
            ]
        },
    ];

    const checkRole = (idparent, menu) => {
        let isrole = false;
        let id = menu.id;
        if (!id) id = _.trimStart(menu.link, ' /');
        if (id) {
            if (idparent && MyRole.hasOwnProperty(idparent) && MyRole[idparent].children.hasOwnProperty(id) && MyRole[idparent].children[id].checked === true) isrole = true
            else if (MyRole.hasOwnProperty(id) && MyRole[id].checked === true) isrole = true
        } else {
            if (menu.hasOwnProperty('children') && menu.children && menu.children.length > 0) {
                menu.children.map((sub) => {
                    let idsub = _.trimStart(sub.link, ' /');
                    if (idparent && MyRole.hasOwnProperty(idparent) && MyRole[idparent].children.hasOwnProperty(idsub) && MyRole[idparent].children[idsub].checked === true) isrole = true
                    else if (idsub && MyRole.hasOwnProperty(idsub) && MyRole[idsub].checked === true) isrole = true
                    return sub;
                })
            }
        }
        // if(menu.link === '/marktests') console.log(MyRole);
        return isrole
    }
    //Hàm xử lý đăng xuất
    const dologout = () => {
        confirmAlert({
        title: "Đăng xuất",
        message: "Bạn có chắc muốn đăng xuất",
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
            {
            label: "Đăng xuất",
            onClick: () => {
                dispatch(resetRedux());
                dispatch(actions.login(null));
            },
            },
            { label: "Không" },
        ],
        });
    };

    const subMenu = (idparent, list, active) => {
        return <>
            {list && list.length > 0 ? <ul className={`nav sub-group-menu`}>
                {list.map((item, i) => {
                    if (checkRole(idparent, item)) {
                        return <li className="nav-item" key={i}>
                            <Link to={'/admin'+item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}>
                                <i className="fas fa-angle-right"></i>
                                {item.title}
                            </Link>
                        </li>
                    } else return null
                })}
            </ul> : null}
        </>
    }
    return (
        <>
            {/* Sidebar Area Start Here */}
            <div className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color">
                <div className="mobile-sidebar-header d-md-none">
                    <div className="w-100 d-flex  u-info">
                        <div className="admin-img">
                            <img src={ info && info.avatar ? info.avatar : Avatar} alt="Admin"/>
                        </div>

                        <div className="admin-title">
                            <h5 className="item-title">
                            {info.fullname} {" "}
                            </h5>
                            <span>{info.chucvu}</span>
                        </div>
                    
                    </div>
                </div>
                <div className="sidebar-menu-content">
                    <ul className="nav nav-sidebar-menu sidebar-toggle-view">
                        {listMenus.map((item, i) => {
                            if (checkRole(null, item) || item.role) {
                                return <li key={i} className={`nav-item ${item.children ? 'sidebar-nav-item' : ''} `}>
                                    {!item.children  ? <Link to={'/admin'+item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}><i
                                        className={item.icon}></i><span>{item.title}</span></Link>:
                                    <div to={item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}><i
                                        className={item.icon}></i><span>{item.title}</span></div>}
                                    {subMenu(item.id, item.children, item.active)}
                                </li>
                            }else return null
                        })}
                        <li className="nav-item">
                            <a href="#logout" onClick={dologout} className="nav-link">
                                <i className="flaticon-turn-off"></i><span>Đăng xuất</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Sidebar Area End Here */}
        </>
    );
}
