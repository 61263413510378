
import React, { useEffect, useState } from "react";

import AkkhorLayout from "../layout/akkhor";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";

export default function ChiTietLaiSuat() {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const [params, setParam] = useState('');
    const [detail, setDetail] = useState(null)
    const [listLS, setListLs] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const [listBangHang, setListBangHang] = useState([]);
    const [datecheck, setDateCheck] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(moment(datecheck).format('YYYY-MM-DD'));
        let _param = ''
        if (datecheck) _param += "&datecheck=" + moment(datecheck).format('YYYY-MM-DD')
        setParam(_param);
    };

    useEffect(
        function () {
            Axios.get(URL_LIST_ALL + "dadautu/" + id, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    let data = res.data.data
                    setDetail(data)
                    Axios.get(URL_LIST_ALL + "listbanghang?limit=1000&product_id=" + data.product_id, user.config).then(async (res) => {
                        if (res.data.status === "success") {
                            setListBangHang(res.data.data)
                        } else console.log(res.data.message);
                    }).catch((err) => console.log(err));
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        },
        [id, user]
    );
    useEffect(() => {
        if (detail) {
            Axios.get(URL_LIST_ALL + "laisuathangngay?limit=1&" + "user_id=" + detail.cus_id + "&transaction_id=" + id + params, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setListLs(res.data.data)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }, [user, params, detail])

    return (<AkkhorLayout idrole="customer">
        <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>
                    Chi tiết theo từng ngày
                </li>
            </ul>
        </div>
        {detail ?
            <>
                <div className="card card-filter mb-3">
                    <div className="card-header">

                        <div className="row">
                            <div className="col-12 pt-2 pb-2">
                                <div className="row">
                                    <div className="col-2">Khách hàng: </div>
                                    <div className="col-10"><b>{detail && detail.cus_name}</b></div>
                                </div>
                                <div className="row">
                                    <div className="col-2">Mã khách hàng: </div>
                                    <div className="col-10"><b>{detail && detail.cus_code}</b></div>
                                </div>
                                <div className="row">
                                    <div className="col-2">Mã hợp đồng: </div>
                                    <div className="col-10"><b>{detail && detail.code}</b></div>
                                </div>
                                <div className="row">
                                    <div className="col-2">Ngày ký hợp đồng: </div>
                                    <div className="col-10"><b><Moment format="DD/MM/YYYY">{new Date(detail.datecreate * 1000)}</Moment></b></div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="card pt-3 h-100">
                                    <h6 className="pl-2 pr-2 w-100 text-center mt-4">Số tiền đã đầu tư</h6>
                                    <h4 className="p-2 w-100 text-center ">
                                        <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.total_price : 0} />
                                    </h4>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="card pt-3 pb-3  ">
                                    <h6 className="pl-2 pr-2 w-100 text-center">Tổng số tiền lãi</h6>
                                    <h4 className="p-2 w-100 text-center mb-0">
                                        <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.tongSoTienLai : 0} />
                                    </h4>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 text-center">
                                                <small>Tổng tiền lãi tối thiểu</small><br />
                                                <strong><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.tongSoTienLaiToiThieu : 0} /></strong>
                                            </div>
                                            <div className="col-6 text-center">
                                                <small>Tổng tiền lãi thưởng</small><br />
                                                <strong><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.tonglaiThuong : 0} /></strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="card pt-3 h-100">
                                    <h6 className="pl-2 pr-2 w-100 text-center mt-4">Tổng giá trị tài sản</h6>
                                    <h4 className="p-2 w-100 text-center ">
                                        <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.tongGiaTriTaiSan : 0} />
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="content" className="site-content">
                    <main className="site-section section-main">
                        <div className="container">
                            <div className="row">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <button type="button" className={'nav-link' + (activeTab === 1 ? ' active' : '')} onClick={() => { setActiveTab(1) }}>Lịch sử tính lãi</button>
                                    </li>
                                    <li className="nav-item">
                                        <button type="button" className={'nav-link' + (activeTab === 2 ? ' active' : '')} onClick={() => { setActiveTab(2) }} href="#">Bảng hàng</button>
                                    </li>
                                </ul>
                                <div className="w-100">
                                    {activeTab === 1 &&
                                        <>
                                            <div className="card">
                                                <div className="card-body">
                                                    <form className="new-added-form mt-4" method="get" >
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h5>Lọc theo ngày</h5>
                                                            </div>
                                                            <div className="col-12 col-sm-3 form-group">
                                                                <DateTimePicker onChange={(value) => { setDateCheck(value) }} value={datecheck ? new Date(datecheck) : null} format="dd-MM-y" className="form-control air-datepicker"></DateTimePicker>
                                                            </div>
                                                            <div className="col-12 col-sm-3 ">
                                                                <button type="submit" onClick={handleSubmit} className="btn btn-sm btn-success">Tìm kiếm</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <table className="table table-striped table-bordered table-hover text-center">
                                                <thead>
                                                    <tr>
                                                        <th>Thời gian</th>
                                                        <th>Tiền lãi tối thiểu</th>
                                                        <th>Tiền lãi thưởng</th>
                                                        <th>Tổng tiền lãi (Lãi tối thiểu + lãi thưởng)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listLS && listLS.map(function (item, i) {
                                                        let datecreate = <Moment format="DD/MM/YYYY">{item.datecheck}</Moment>

                                                        return (
                                                            <tr key={i}>
                                                                <td className="text-center">{datecreate}</td>
                                                                <td className="text-center text-success"><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={item.tienlaitoithieu} /></td>
                                                                <td className="text-center text-success"><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={item.tienlaithuong} /></td>
                                                                <td className="text-center text-success"><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={item.total} /></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </>
                                    }
                                    {activeTab === 2 && <div>
                                        <div className="w-100 d-flex justify-content-end align-items-center mt-2 mb-5">
                                            <div className="col-auto">Chú thích: </div>
                                           <div className="btn btn-danger text-center mr-2">Đã bán</div>
                                           <div className="btn btn-success text-center">Chưa bán</div>
                                        </div>
                                        {listBangHang && Object.keys(listBangHang).map((item, i) => (
                                            <div className="row" key={i}>
                                                {item === 'nokey' ? listBangHang[item].map(function (itemBH, iBH) {
                                                    return (
                                                        <div className={"col-6 col-sm-3 col-lg-2 banghang-item "} key={iBH}>
                                                            <div className={"wrapper mt-3 mb-2 border rounded " + (itemBH.status === 1 ? 'text-white bg-danger' : 'text-white bg-success')}>
                                                                <p className="w-100 border-bottom mb-0 top-banghang p-2 text-center">
                                                                    {itemBH.code}
                                                                </p>
                                                                <p className="w-100 bottom-banghang mb-0 p-2 text-center">
                                                                    <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={itemBH.giagoc} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                    <div className="col-12 mt-3 rounded border" >
                                                        <div className="row">
                                                            <div className="col-12 text-center border-bottom d-flex align-items-center justify-content-center">
                                                                <h3 className="m-0 p-3">{item}</h3>
                                                            </div>
                                                            {Object.keys(listBangHang[item]).map(function (itemBH, iBH) {
                                                                return (<div className="w-100 mt-2 mb-2 rounded border" key={iBH}>
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-1 text-center border-right d-flex align-items-center justify-content-center">
                                                                            {itemBH}
                                                                        </div>
                                                                        <div className="col-12 col-sm-11">
                                                                            <div className="row">
                                                                                {listBangHang[item][itemBH] && listBangHang[item][itemBH].map(function (itemCan, iCan) {
                                                                                    return (
                                                                                        <div className={"col-6 col-sm-3 col-lg-2 banghang-item "} key={iCan}>
                                                                                            <div className={"wrapper mt-3 mb-2 border rounded " + (itemCan.status === 1 ? 'text-white bg-danger' : 'text-white bg-success')}>
                                                                                                <p className="w-100 border-bottom mb-0 top-banghang p-2 text-center">
                                                                                                    {itemCan.code}
                                                                                                </p>
                                                                                                <p className="w-100 bottom-banghang mb-0 p-2 text-center">
                                                                                                    <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={itemCan.giagoc} />
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            })}
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        ))}

                                    </div>}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </>
            :
            <h2 className="w-100 text-center">
                Không tìm thấy dữ liệu
            </h2>
        }

    </AkkhorLayout>);
}
