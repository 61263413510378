import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../../redux/authRedux";
import { resetRedux } from "../../redux/Actions";
import onClickOutside from "react-onclickoutside";
// import Pusher from "pusher-js";

import mainLogo from '../../assets/img/logo-fmax.png';

import Avatar from "../../assets/img/figure/admin.jpg";

function HeaderBar(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showMobile, setShowMobile] = useState(false);
  const [show, setShow] = useState(false);
  const info = user && user.data ? user.data : null;
  // const [notifi, setNotifi] = useState([])

  //Hàm xử lý đăng xuất
  const dologout = () => {
    confirmAlert({
      title: "Đăng xuất",
      message: "Bạn có chắc muốn đăng xuất",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Đăng xuất",
          onClick: () => {
            dispatch(resetRedux());
            dispatch(actions.login(null));
          },
        },
        { label: "Không" },
      ],
    });
  };
  HeaderBar["handleClickOutside_header"] = () => setShow(false);

  // const pusher = new Pusher("121162a077743bac91f8", {
  //   cluster: "ap1"
  //  });
  // const channel = pusher.subscribe('notification');
  // channel.bind('user_'+ info.code, function(data) {
  //   const newData = notifi.slice()
  //   newData.push(data)
  //   setNotifi(newData)
  //   return(data)
  // });
  
  return (
    <>
      {/* Header Menu Area Start Here */}
      <div className="navbar navbar-expand-md header-menu-one bg-light">
        <div className="nav-bar-header-one">
          <div className="header-logo">
            <Link to="/">
              <img src={mainLogo} alt="logo" />
            </Link>
          </div>
          <div className="toggle-button sidebar-toggle">
            <button type="button" className="item-link">
              <span className="btn-icon-wrap">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>
        </div>
        <div className="d-md-none mobile-nav-bar">
          <button className="navbar-toggler" type="button"  onClick={() => setShowMobile(!showMobile)} >
            <i className="fas fa-user-alt"></i>
          </button>
          <button type="button" className="navbar-toggler sidebar-toggle-mobile" >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div className={`header-main-menu collapse navbar-collapse ${showMobile ? "show" : ""}`} id="mobile-navbar">
          {info && (<>
            <ul className="hidden-xs navbar-nav">
              <li className={`navbar-item dropdown header-admin ${show ? "show" : "" }`} >
                <a
                  className="navbar-nav-link dropdown-toggle"
                  href="#shool"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setShow(!show)}
                >
                  <div className="admin-title">
                    <h5 className="item-title">
                      {info.fullname} {" "}
                    </h5>
                    <span>{info.chucvu}</span>
                  </div>
                  <div className="admin-img">
                    <img src={ info && info.avatar ? info.avatar : Avatar} alt="Admin"/>
                  </div>
                </a>
                <div className={`dropdown-menu dropdown-menu-right ${show ? "show" : "" }`} >
                  <div className="item-header">
                    <h6 className="item-title">
                      {info.fullname} {" "}
                    </h6>
                  </div>
                  <div className="item-content">
                    <ul className="settings-list">
                      <li>
                        <Link to="/my-profile">
                          <i className="flaticon-user"></i>Hồ sơ của tôi
                        </Link>
                      </li>
                      <li>
                        <a href="#logout" onClick={dologout}>
                          <i className="flaticon-turn-off"></i>Đăng xuất
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <div className="info-user-mobile">
              <div className="w-100 d-flex  u-info">
                <div className="admin-img">
                  <img src={ info && info.avatar ? info.avatar : Avatar} alt="Admin"/>
                </div>

                <div className="admin-title">
                    <h5 className="item-title">
                      {info.fullname} {" "}
                    </h5>
                    <span>{info.chucvu}</span>
                </div>
                
              </div>
              <ul className="settings-list">
                <li>
                  <Link to="/my-profile">
                    <i className="flaticon-user"></i>Hồ sơ của tôi
                  </Link>
                </li>
                <li>
                  <a href="#logout" onClick={dologout}>
                    <i className="flaticon-turn-off"></i>Đăng xuất
                  </a>
                </li>
              </ul>
                 
            </div>
            </>
          )}
        </div>
      </div>
      {/* <div className="notification">
          {notifi && notifi.map((item,i)=>{
            return <div className="item-notification" key={i}>
              <div className="alert alert-sm alert-info alert-dismissible" role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                {item}
              </div>
            </div>
          })}
      </div> */}
      {/* Header Menu Area End Here */}
    </>
  );
}
export default onClickOutside(HeaderBar, {
  handleClickOutside: () => HeaderBar["handleClickOutside_header"],
});
