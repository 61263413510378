import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { actions } from "../../redux/authRedux";
import * as Yup from "yup";

import mainLogo from '../../assets/img/logo-fmax.png';

export default function Login() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({ isAuthorized: auth.user != null }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const createConfig = (_token) => {
    console.log(_token);
    return {
      headers: {
        'Authorization': 'Bearer ' + _token,
        'Content-Type': 'application/json; charset=UTF-8',
        'search': ''
      }
    }
  }

  const ValidSchema = Yup.object().shape({
    username: Yup.string().trim().nullable().required("Vui lòng nhập tên đăng nhập"),
    password: Yup.string().trim().nullable().min(6, "Mật khẩu có độ dài tối thiểu 6 ký tự").max(150, "Mật khẩu có độ dài tối đa 150 ký tự").required("Vui lòng nhập mật khẩu"),
  });

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: ValidSchema,
    onSubmit: (values, { setSubmitting }) => {
      Axios.post(URL_LIST_ALL + "login2", values)
        .then((res) => {
          setSubmitting(false);
          if (res.data.status === "success") {
            let user = res.data;
            user.config = createConfig(res.data.access_token);
            dispatch(actions.login(user));
          } else alert(res.data.message)
        })
        .catch((err) => console.log(err));
    },
  });

  return (
    <div>
      {isAuthorized ? (
        <Redirect to="/admin" />
      ) : (
        <div className="layout-login">
          <div className="container btn-login-gr">
            <div className="mt-3 w-100 d-flex justify-content-center">
              <div className="wrap-login100 p-t-50 p-b-30">
                <div className="mb-3 w-100 d-flex justify-content-center">
                  <img src={mainLogo} width={300} alt="upload" />
                </div>
                <form className="login100-form validate-form"
                  method="post"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="wrap-input100 validate-input ">
                    <input
                      className="input100"
                      type="text"
                      name="username"
                      placeholder="Mã nhân viên hoặc email"
                      {...formik.getFieldProps("username")}
                    />
                    <span className="focus-input100"></span>
                  </div>
                  {formik.touched.username && formik.errors.username ? (
                    <div className="text-danger">{formik.errors.username}</div>
                  ) : null}

                  <div className="wrap-input100 validate-input mt-3">
                    <span className="btn-show-pass">
                      <i className="fa fa fa-eye"></i>
                    </span>
                    <input className="input100" type="password" name="password" placeholder="Mật khẩu" {...formik.getFieldProps("password")} />
                    <span className="focus-input100"></span>

                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger">{formik.errors.password}</div>
                  ) : null}
                  <div className="container-login100-form-btn mt-3">
                    <button
                      type="submit"
                      className="login100-form-btn"
                      disabled={formik.isSubmitting}
                    >
                      Đăng nhập
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
