import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainFrontend from "./template/Main";
import { URL_LIST_ALL } from "../configs/api";
import axios from "axios"
import NumberFormat from "react-number-format";

export default function AccountOverview() {
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(null);


    useEffect(() => {
        if (user && user.data.typeaccount === 'customer') {

            axios.get(URL_LIST_ALL + "laisuatcanhan", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setDetail(res.data.data)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }, [user])
    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Tổng quan</h2>
                        </div>
                    </div>
                </div>
            </header>

            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <div className="box box-summary">
                                    <h4 className="box-title">Số dư tài khoản</h4>
                                    <h3 className="box-display"> <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value="0" /></h3>
                                    <hr />
                                    <div className="data-box">
                                        <div className="data-property">Số dư khả dụng</div>
                                        <div className="data-value"><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value="0" /></div>
                                        <div className="data-property">Số tiền đã đầu tư</div>
                                        <div className="data-value pl-0"><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.total_price : 0} /></div>
                                        <div className="data-property">Các khoản sẽ thu
                                            <em className="tooltip-info"
                                                data-tooltip="Các khoản bao gồm vốn và lãi bạn sẽ nhận được trong tương lai sau khi tất toán các khoản đầu tư.">
                                            </em>
                                        </div>
                                        <div className="data-value">0.00 vnđ</div>
                                        <div className="data-property">Tiền đã rút</div>
                                        <div className="data-value">0.00 vnđ</div>
                                    </div>
                                    <a href="/nap-rut-tien" className="button button-primary">Nạp tiền</a>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="box box-summary">
                                    <h4 className="box-title">Tỷ lệ lợi nhuận
                                        {/* <em className="tooltip-info" data-tooltip="Net annualised return (NAR) measures the actual rate of return of all your investments since you started."></em> */}
                                    </h4>
                                    <h3 className="box-display">{detail && detail.tileloinhuan} %</h3>
                                    <hr />
                                    <div className="data-box">
                                        <div className="data-property">Lợi nhuận</div>
                                        <div className="data-value"><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.tonglaihientai : 0} /></div>
                                        <div className="data-property">Lợi nhuận dự kiến</div>
                                        <div className="data-value"><NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail ? detail.loinhuandukien : 0} /></div>
                                        <div className="data-property">Tỷ lệ lãi cao nhất</div>
                                        <div className="data-value">{detail && detail.laicaonhat} %</div>
                                        <div className="data-property">Phí dịch vụ </div>
                                        <div className="data-value">0.00 vnđ</div>
                                    </div>
                                    <a href="/san-pham-dau-tu" className="button button-primary">Cơ hội đầu tư</a>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="box box-summary">
                                    <h4 className="box-title">Các khoản đã đầu tư</h4>
                                    <h3 className="box-display">{detail && detail.sanphamdadautu && detail.sanphamdadautu.length}</h3>
                                    <hr />

                                    {detail && detail.sanphamdadautu.map(function (item, i) {
                                        return (<div className="data-box" key={i}>
                                            <div className="data-property">{item.title}</div>
                                            <div className="data-value pl-0">{<NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={item.tongsotiendautu} />}</div>
                                        </div>)
                                    })}
                                    <a href="/cac-khoan-dau-tu" className="button button-primary">Các khoản đã đầu tư</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </MainFrontend>
    );
}
