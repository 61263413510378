import axios from "axios";
import { convertPrice, countdate, countInterest } from "../adminpage/model";
import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../configs/api";
import MainFrontend from "./template/Main";

export default function TrangChu() {
    const [listSP, setListSP] = useState(null)
    const [listSP2, setListSP2] = useState(null)

    //Lấy sản phẩm 
    useEffect(
        function () {

            axios.get(URL_LIST_ALL + "sanpham").then(async (res) => {
                if (res.data.status === "success") {
                    setListSP(res.data.data);
                } else console.log(res.data.message);
            })
                .catch((err) => console.log(err));

            axios.get(URL_LIST_ALL + "sanpham?status=1").then(async (res) => {
                if (res.data.status === "success") {
                    setListSP2(res.data.data);
                } else console.log(res.data.message);
            })
                .catch((err) => console.log(err));
        }, []
    );


    return (
        <MainFrontend>
            <header id="featured" className="site-featured has-main-features">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h1 className="entry-title display">Đầu tư bất động sản từ 1 triệu đồng!</h1>
                            <p className="tagline">Có thu nhập thụ động ngay từ hôm nay!</p>
                            <p className="no-margin-bottom">
                                <a href="https://www.youtube.com/embed/UBufeh1yv2c?autoplay=1" className="button button-filled button-primary no-margin-bottom" data-fancybox="featured-video"><span className="mdi mdi-play-circle-outline"></span>Xem video</a>
                                <span className="home-start-intro">Bạn muốn bắt đầu? <a href="/san-pham-dau-tu">Cơ hội đầu tư</a></span>
                            </p>
                        </div>
                    </div>
                    <img src={require('./assets/images/home-bg.jpg')} alt="" />
                </div>
            </header>
            <div id="content" className="site-content">
                <div className="site-section section-features-main-pitch">
                    <div className="main-features">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-3 d-flex">
                                    <div className="featured-box">
                                        <em className="featured-box-icon mdi mdi-briefcase-outline"></em>
                                        <h5 className="featured-box-title">Đơn giản</h5>
                                        <p className="featured-box-content">Tìm hiểu thông tin về sản phẩm, chọn sản phẩm bạn muốn đầu tư và đăng ký đầu tư.</p>
                                        <a href="/gioi-thieu" className="button button-primary button-small button-outline">Xem thêm <em className="mdi mdi-trending-neutral"></em></a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 d-flex">
                                    <div className="featured-box">
                                        <em className="featured-box-icon mdi mdi-currency-eur"></em>
                                        <h5 className="featured-box-title">Minh bạch</h5>
                                        <p className="featured-box-content">Giá trị đầu tư được cập nhật hàng ngày. Giá mua lại được niêm yết công khai cho tất cả nhà đầu tư.</p>
                                        <a href="/gioi-thieu" className="button button-primary button-small button-outline">Xem thêm <em className="mdi mdi-trending-neutral"></em></a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 d-flex">
                                    <div className="featured-box">
                                        <em className="featured-box-icon mdi mdi-account-tie-outline"></em>
                                        <h5 className="featured-box-title">Chuyên nghiệp</h5>
                                        <p className="featured-box-content">Giao dịch nhanh gọn và thuận tiện, có thể tiến hành trực tiếp và online theo cách thuận tiện nhất cho bạn.</p>
                                        <a href="/gioi-thieu" className="button button-primary button-small button-outline">Xem thêm <em className="mdi mdi-trending-neutral"></em></a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 d-flex">
                                    <div className="featured-box">
                                        <em className="featured-box-icon mdi mdi-security"></em>
                                        <h5 className="featured-box-title">An toàn</h5>
                                        <p className="featured-box-content">Bạn có thể chuyển nhượng lại khoản đầu tư bất kỳ lúc nào trong thời gian đầu tư.</p>
                                        <a href="/gioi-thieu" className="button button-primary button-small button-outline">Xem thêm <em className="mdi mdi-trending-neutral"></em></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-4 col-xl-4 offset-xl-1">
                                <p><img src={require('./tmp/phone-mockup.png')} alt="" /></p>
                            </div>
                            <div className="col-md-8 col-xl-6">
                                <h3 className="entry-title">Sẵn sàng trên nền tảng di động</h3>
                                <p>Với chiếc điện thoại trên tay, bạn có thể quản lý và đầu tư dễ dàng. Website được thiết kế tối ưu cho nền tảng di động, tạo cho bạn một trải nghiệm hoàn thiện không phân biệt bạn đang dùng máy tính hay smartphone.</p>
                                {/* <p><a href="about.html" className="button button-light"><em className="mdi mdi-google-play"></em> On Play Store</a> <a href="about.html" className="button button-light"><em className="mdi mdi-apple"></em> On Apple Store</a></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-section section-investment-steps dotted-pattern dotted-pattern-top-right">
                    <div className="container">
                        <h2 className="entry-title">Đầu tư thế nào?</h2>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="step-list">
                                    <div className="step-list-item">
                                        <div className="step-list-digit">
                                            <span>01.</span>
                                        </div>
                                        <div className="step-list-content">
                                            <h4 className="step-list-title">Đăng ký tài khoản</h4>
                                            <p>Đăng ký tài khoản với họ tên, email và số điện thoại.</p>
                                        </div>
                                    </div>
                                    <div className="step-list-item">
                                        <div className="step-list-digit">
                                            <span>02.</span>
                                        </div>
                                        <div className="step-list-content">
                                            <h4 className="step-list-title">Xác nhận tài khoản</h4>
                                            <p>Xác nhận tài khoản bằng email hoặc số điện thoại.</p>
                                        </div>
                                    </div>
                                    <div className="step-list-item">
                                        <div className="step-list-digit">
                                            <span>03.</span>
                                        </div>
                                        <div className="step-list-content">
                                            <h4 className="step-list-title">Lựa chọn sản phẩm bạn muốn đầu tư</h4>
                                            <p>Tìm hiểu thông tin các sản phẩm đầu tư và lựa chọn sản phẩm bạn muốn đầu tư.</p>
                                        </div>
                                    </div>
                                    <div className="step-list-item">
                                        <div className="step-list-digit">
                                            <span>04.</span>
                                        </div>
                                        <div className="step-list-content">
                                            <h4 className="step-list-title">Theo dõi khoản đầu tư của bạn</h4>
                                            <p>Giá trị các khoản đầu tư được cập nhật hàng ngày. Bạn hãy đăng nhập và theo dõi giá trị mới nhất.</p>
                                        </div>
                                    </div>
                                    <div className="step-list-item">
                                        <div className="step-list-digit">
                                            <span>05.</span>
                                        </div>
                                        <div className="step-list-content">
                                            <h4 className="step-list-title">Nhận tiền lãi cố định, lãi thực tế hoặc chuyển nhượng lại</h4>
                                            <p>
                                                Tuỳ loại hình sản phẩm, bạn được chia lãi cố định hoặc lãi thực tế theo kết quả đầu tư.
                                                Bạn cũng có thể chuyển nhượng lại khoản đầu tư nếu bạn muốn.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="step-info homestep1">
                                    <h3>Ai có thể đầu tư?</h3>
                                    <p className="step-info-text">
                                        Vào lúc này, các cơ hội đầu tư chỉ dành riêng cho các khách hàng ưu đãi và được mời.
                                        Nếu bạn quan tâm, bạn có thể đăng ký tài khoản và theo dõi các sản phẩm đầu tư.
                                        Chúng tôi sẽ mời bạn tham gia khi có sản phẩm phù hợp.
                                    </p>
                                    <p>
                                        <a href="/san-pham-dau-tu" className="button button-primary">
                                            <em className="mdi mdi-briefcase-outline"></em>
                                            Cơ hội đầu tư
                                        </a>
                                        <a href="/tro-giup" className="button">
                                            <em className="mdi mdi-face-agent"></em>
                                            Cần hỗ trợ
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="site-section section-main dotted-pattern dotted-pattern-top-left">
                    <div className="container">
                        <h2 className="entry-title">Dự án đầu tư</h2>
                        <div className="project-listing">
                            {listSP && listSP.map((infoSP, i) => {
                                return (
                                    <div className="entry-project project-status-active" key={i}>
                                        <div className="project-media">
                                            <a href={"/san-pham/" + infoSP.alias}>
                                                <img src={infoSP.thumbnail ? infoSP.thumbnail : require('./tmp/sample-project.jpg')} width="800" height="600" alt="" />
                                                <span className="project-industry" title="Investment type"><em className="mdi mdi-information"></em> Shoptel</span>
                                                <span className="project-country" title="Estonia"><img className="office-country" src={require('./assets/images/ee.svg')} width="24" alt="" />
                                                    <em className="screen-reader-text">Quảng Ninh</em></span>
                                            </a>
                                            <span className="project-status"><em className="mdi mdi-plus"></em>
                                                {infoSP.status === 0 && "Dừng hoạt động"}
                                                {infoSP.status === 1 && "Đã gọi đầu tư"}
                                                {infoSP.status === 2 && "Đang gọi đầu tư"}
                                                {infoSP.status === 3 && "Sắp gọi đầu tư"}
                                                {infoSP.status === 4 && "Đã kết thúc"}</span>
                                        </div>
                                        <div className="project-details">
                                            <h3 className="project-title"><a href={"/san-pham/" + infoSP.alias}>{infoSP.title}</a></h3>
                                            <p className="project-summary">{infoSP.description}</p>
                                            <div className="project-specs">
                                                <div className="project-return">
                                                    <span>Lãi dự kiến</span>
                                                    <strong>{countInterest(infoSP)}</strong>
                                                </div>
                                                <div className="project-term">
                                                    <span>Thời gian đầu tư</span>
                                                    <strong>{infoSP.time_invest} tháng</strong>
                                                </div>
                                                <div className="project-countdown">
                                                    <span>Thời hạn đầu tư</span>
                                                    <strong>{infoSP.status === 1 ? <>Hết hạn đầu tư</> : <>{infoSP.dateend && countdate(infoSP.dateend)} ngày</>}</strong>
                                                </div>
                                            </div>
                                            <div className="project-progress">
                                                <div className="progress-bar">
                                                    <div className="progress-current homestep2"></div>
                                                    <div className="progress-minimum homestep3">
                                                        <div className="progress-minimum-pointer">
                                                            <span className="project-minimum-goal-info">Min<span className="screen-reader-text">imum</span> goal</span>
                                                            <span className="project-minimum-goal-amount"><span className="currency-sign">VNĐ</span>30 000</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="progress-info">
                                                    <div className="progress-current-amount">Đã đầu tư
                                                        <strong><span className="currency-sign">
                                                            {convertPrice(infoSP.dadautu)}
                                                        </span> </strong>
                                                    </div>
                                                    <div className="progress-amount-left"><strong>Tổng đầu tư <span className="currency-sign">{convertPrice((infoSP.price) * infoSP.soluong)}</span></strong> </div>
                                                </div>
                                            </div>
                                            <div className="project-actions">
                                                <a href={"/san-pham/" + infoSP.alias} className="button button-primary">Chi tiết</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="spacing1"></div>
                        <h2 className="entry-title">Dự án đã gọi đầu tư</h2>
                        <div className="project-listing grid-format">
                            {listSP2 && listSP2.map((infoSP2, i) => {
                                return (
                                    <div className="entry-project project-status-funded" key={i}>
                                        <div className="project-media">
                                            <a href={"/san-pham/" + infoSP2.alias}>
                                                <img src={infoSP2.thumbnail ? infoSP2.thumbnail : require('./tmp/sample-project.jpg')} width="800" height="600" alt="" />
                                                <span className="project-industry" title="Investment type"><em className="mdi mdi-information"></em> Business &mdash; Mining</span>
                                                <span className="project-country" title="Latvia"><img className="office-country" src="assets/images/lv.svg" width="33" alt="" /> <em className="screen-reader-text">Latvia</em></span>
                                            </a>
                                            <span className="project-status"><em className="mdi mdi-check-bold"></em> Đã gọi đầu tư</span>
                                        </div>
                                        <div className="project-details">
                                            <h4 className="project-title"><a href={"/san-pham/" + infoSP2.alias}>{infoSP2.title}</a></h4>
                                            <div className="project-specs">
                                                <div className="project-total-invested">
                                                    <span>Giá trị đầu tư (tr)</span>
                                                    <strong><span className="currency-sign">{convertPrice(infoSP2.price)}</span></strong>
                                                </div>
                                                <div className="project-return">
                                                    <span>Lãi dự kiến</span>
                                                    <strong>{countInterest(infoSP2)}</strong>
                                                </div>
                                                <div className="project-term">
                                                    <span>Thời điểm trả lãi</span>
                                                    <strong>{infoSP2.time_invest} tháng</strong>
                                                </div>
                                            </div>
                                            <div className="project-actions">
                                                <a href="/#" className="button button-outline button-disabled">không có người bán lại</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                        <div className="pagination">
                            <a href="/san-pham-dau-tu" className="button"><em className="mdi mdi-dots-horizontal"></em> Xem thêm dự án</a>
                        </div>
                    </div>
                </main>
                <div className="site-section section-call-to-action has-media dotted-pattern dotted-pattern-top-right">
                    <div className="container">
                        <h2 className="entry-title">Tăng thu nhập từ đầu tư các dự án bất động sản trên toàn quốc.</h2>
                        <p className="no-margin-bottom">
                            <a href="/san-pham-dau-tu" className="button button-primary"><em className="mdi mdi-briefcase-outline"></em> Cơ hội đầu tư</a>
                            <a href="/lien-he" className="button button-light button-outline"><em className="mdi mdi-email"></em> Liên hệ ngay</a>
                        </p>
                    </div>
                    <img src={require('./tmp/sample-video.jpg')} alt="" />
                    {/* <video id="sample-video" src="tmp/sample-video.mp4" poster="tmp/sample-video.jpg" autoplay="" loop="" muted=""></video/ */}
                </div>
            </div>
        </MainFrontend>

    );
}
