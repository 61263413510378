import { useFormik } from "formik";
import React, { useState } from "react";
import MainFrontend from "./template/Main";
import * as Yup from "yup";

import axios from 'axios';
import { URL_LIST_ALL } from "../configs/api";
import { actions } from "../redux/authRedux";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "../adminpage/component/Spinner";
import AlertErrorForm from "../adminpage/component/AlertErrorForm";
import { Redirect } from "react-router-dom";

export default function DangNhap() {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const createConfig = (_token) => {
    return {
      headers: {
        'Authorization': 'Bearer ' + _token,
        'Content-Type': 'application/json; charset=UTF-8',
        'search': ''
      }
    }
  }

  const formikLogin = useFormik({
    initialValues: { username: "", password: "" },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      username: Yup.string().trim().nullable().required("Vui lòng nhập email hoặc số điện thoại"),
      password: Yup.string().trim().nullable().min(6, "Mật khẩu có độ dài tối thiểu 6 ký tự").max(150, "Mật khẩu có độ dài tối đa 150 ký tự").required("Vui lòng nhập mật khẩu"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      axios.post(URL_LIST_ALL + 'customer-login', values).then(res => {
        setLoading(false);
        setSubmitting(false);
        if (res.data.status === "success") {
          let user = res.data;
          user.config = createConfig(res.data.access_token);
          dispatch(actions.login(user));
          confirmAlert({
            title: 'Thành công',
            message: "Chúc mừng bạn đã đăng nhập thành công",
            buttons: [{
              label: 'Đồng ý', onClick: () => {

              }
            }]
          });
        } else {
          confirmAlert({
            title: 'Thông báo',
            message: res.data.message,
            buttons: [{ label: 'Đồng ý' }]
          });
        }
      }).catch(err => console.log(err));
    },
  });

  return (
    <MainFrontend>
      {user && user.data.typeaccount ==='customer' && <Redirect to="/" />}
      <div className="login-container">
        <div id="container" className="container">
          <div className="col align-items-center flex-col d-flex justify-content-center align-item-center sign-in">
            <div className="form-wrapper align-items-center">
              <form className="form sign-in" method="post" onSubmit={formikLogin.handleSubmit}>
                <h3 className="w-100 text-center">Đăng nhập</h3>
                <div className="input-group">
                  <i className='bx bxs-user'></i>
                  <input type="text" placeholder="Email hoặc số điện thoại" {...formikLogin.getFieldProps("username")} />
                  <AlertErrorForm formik={formikLogin} name="username"></AlertErrorForm>
                </div>
                <div className="input-group">
                  <i className='bx bxs-lock-alt'></i>
                  <input type="password" placeholder="Mật khẩu" {...formikLogin.getFieldProps("password")}/>
                  <AlertErrorForm formik={formikLogin} name="password"></AlertErrorForm>
                </div>
                <button className="btn" type="submit" >
                  {loading ? <Spinner /> : <>Đăng nhập</>}
                </button>
                <p>
                  <a href="/quen-mat-khau">
                    <b>
                      Quyên mật khẩu ?
                    </b>
                  </a>
                </p>
                <p>
                  <span>
                    Bạn chưa có tài khoản
                  </span>
                  <a href="/dang-ky" className="ml-1">
                    <b className="pointer signup-btn">
                      Đăng ký ngay
                    </b>
                  </a>

                </p>
              </form>
            </div>
            <div className="form-wrapper">

            </div>
          </div>
        </div>
      </div>

    </MainFrontend>
  )
}
