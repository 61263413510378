
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

export default function DoiMatKhau() {
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);

    const defaultdetail = {
        id:user.data.id,oldpassword: '', newpassword: '',renewpassword: ''
    }

    const formik = useFormik({
        initialValues: defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            oldpassword: Yup.string().trim().nullable().required('Nhập mật khẩu cũ').max(150, "Độ dài không quá 150 ký tự"),
            newpassword: Yup.string().trim().nullable().required('Nhập mật khẩu mới').max(16, "Độ dài không quá 16 ký tự").min(6,"Độ dài mật khẩu không ngắn hơn 6 ký tự"),
            renewpassword: Yup.string().trim().nullable().required('Nhập mật khẩu mới').max(16, "Độ dài không quá 16 ký tự"),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
           
            if(values.newpassword !== values.renewpassword){
                confirmAlert({
                    title: 'Lỗi',
                    message: 'Nhập lại mật khẩu không đúng',
                    buttons: [{ label: 'OK' }]
                });
            }else{
                setLoading(true)
                Axios.post(URL_LIST_ALL + 'changepassword', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Đổi mật khẩu thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
          

        },
    });

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>Lấy mật khẩu</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Lấy mật khẩu</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6 col-12 form-group">
                                <label>Mật khẩu cũ <span className="text-danger">*</span></label>
                                <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("oldpassword")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="oldpassword"></AlertErrorForm>
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-lg-6 col-12 form-group">
                                <label>Mật khẩu mới <span className="text-danger">*</span></label>
                                <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("newpassword")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="newpassword"></AlertErrorForm>
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-lg-6 col-12 form-group">
                                <label>Nhập lại mật khẩu mới <span className="text-danger">*</span></label>
                                <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("renewpassword")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="renewpassword"></AlertErrorForm>
                            </div>
                            <div className="col-xs-12"></div>
                           
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Đổi mật khẩu</>}</button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
