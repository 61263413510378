import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { URL_LIST_ALL } from "../configs/api";
import MainFrontend from "./template/Main";

export default function AccountStatement() {
	const { user } = useSelector((state) => state.auth);
	const [listHistory, setListHistory] = useState(null);

	useEffect(() => {
		if (user && user.data.typeaccount === 'customer') {
			axios.get(URL_LIST_ALL + "transaction_history", user.config).then(async (res) => {
				if (res.data.status === "success") {
					setListHistory(res.data.data)
				} else console.log(res.data.message);
			}).catch((err) => console.log(err));
		}
	}, [user])

	return (
		<MainFrontend>
			<header id="featured" className="site-featured">
				<div id="featured-media" className="featured-media">
					<div className="featured-text">
						<div className="container">
							<h2 className="entry-title">Lịch sử giao dịch</h2>
						</div>
					</div>
				</div>
			</header>
			{user && user.data.typeaccount === "customer" ?
				<div id="content" className="site-content">
					<main className="site-section section-main">
						<div className="container">
							<div className="row">
								<div id="sidebar" className="col-12 col-md-4 col-lg-3 content-filters">
									<form action="account-statement.html">
										<h5 className="widget-title">Thời gian giao dịch</h5>
										<div className="content-filter-date content-filter-interval">
											<label>
												<span>From:</span>
												<input type="date" id="date-from" name="date-from" value="2022-03-01" />
											</label>
											<label>
												<span>To:</span>
												<input type="date" id="date-to" name="date-to" value="2022-04-30" />
											</label>
										</div>

										<h5 className="widget-title">Loại giao dịch</h5>
										<div className="content-filter-type content-filter-checkboxes">
											<label>
												<input id="type-investment" name="type-investment" type="checkbox" />
												<span>Khoản đầu tư</span>
											</label>
											<label>
												<input id="type-principal-repayment" name="type-principal-repayment" type="checkbox" />
												<span>Trả gốc</span>
											</label>
											<label>
												<input id="type-interest-payment" name="type-interest-payment" type="checkbox" />
												<span>Trả lãi</span>
											</label>
											<label>
												<input id="type-deposit" name="type-deposit" type="checkbox" />
												<span>Nộp tiền</span>
											</label>
											<label>
												<input id="type-withdrawal" name="type-withdrawal" type="checkbox" />
												<span>Rút tiền</span>
											</label>
											{/* <label>
												<input id="type-referral-fee" name="type-referral-fee" type="checkbox" />
												<span>Phí giới thiệu</span>
											</label> */}
										</div>
									</form>
								</div>
								<div className="col-12 col-md-8 col-lg-9">
									<table>
										<thead>
											<tr>
												<th className="text-center">Thời gian</th>
												<th className="text-center">Mã giao dịch</th>
												<th className="text-center">Nội dung chi tiết</th>
												<th className="text-center">Trạng thái</th>
											</tr>
										</thead>
										<tbody>
											{listHistory && listHistory.map(function (item, i) {
												let time = new Date(item.datecreate * 1000);
												let datecreate = <Moment format="HH:mm:ss DD/MM/YYYY">{time}</Moment>
												let content_tran = ''

												if (item.action === 'Đã tạo giao dịch') {
													content_tran = item.cus_name + " đã đăng ký đầu tư " + item.number + " sản phẩm vào " + item.product_name
												}
												if (item.action === 'Đã duyệt giao dịch') {
													content_tran = "Giao dịch "+ item.transaction_code + " chuyển sang trạng thái " + item.status
												}
												if (item.action === 'Đã chuyển khoản') {
													content_tran = item.cus_name + " đã chuyển khoản đầu tư " + item.price + " vnđ vào " + item.product_name + ' mã giao dịch là ' + item.transaction_code+'. '
													if(item.status === 'Chuyển khoản thiếu'){
														let tienthieu = item.total_price - item.sotiendanop
														content_tran += 'Bạn còn thiếu '+tienthieu+' vnđ. Vui lòng thanh toán thêm để hoàn tất giao dịch'
													}
												}
												if (item.action === 'Chờ chuyển khoản') {
													content_tran = "Giao dịch "+ item.transaction_code + " chuyển sang trạng thái " + item.status
												}
												return (
													<tr key={i}>
														<td className="text-center">{datecreate}</td>
														<td>{item.transaction_code}</td>
														<td>{content_tran}</td>
														<td>{item.status}</td>
													</tr>
												)
											})}

										</tbody>
									</table>
									<div className="pagination">
										<a href="account-statement.html" className="button button-outline">Xem thêm</a>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
				:
				<Redirect to="/" />
			}

		</MainFrontend>
	);
}
