import axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../configs/api";
import MainFrontend from "./template/Main";
import UserDropDown from "../adminpage/component/UserDropDown";
import Spinner from "../adminpage/component/Spinner";
import Pagination from "react-js-pagination";
import { convertPrice, countdate, countInterest } from "../adminpage/model";

export default function FrontendDuAn() {
    const [listSP, setListSP] = useState(null)
    const [params, setParam] = useState();
    const [productStatus, setProductStatus] = useState('');
    const [city, setCity] = useState('');
    const [lsStart, setLsStart] = useState('');
    const [lsEnd, setLsEnd] = useState('');
    const [productType, setProductType] = useState('');
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1);
    const [totalpage, setTotalpage] = useState(0);
    const limit = 10;
    const [totalRecord, setTotalRecord] = useState(10);

    //Lấy sản phẩm 
    useEffect(
        function () {
            let mounted = true;
            setLoading(true)
            axios.get(URL_LIST_ALL + "sanpham?status=1" + params)
                .then(async (res) => {
                    setLoading(false)
                    if (res.data.status === "success") {
                        if (mounted) {
                            setListSP(res.data.data);
                            setTotalRecord(res.data.total);
                            setTotalpage(res.data.totalpage)
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err)
                });
            return () => (mounted = false);

        }, [params]
    );
    const listStatus = [
        { value: "1", name: "Đã gọi đầu tư" },
        { value: "2", name: "Đang gọi đầu tư" },
        { value: "3", name: "Sắp gọi đầu tư" },
        { value: "4", name: "Đã kết thúc" },
    ]
    const listType = [
        { value: "Lãi suất cố định", name: "Lãi suất cố định" },
        { value: "Lãi suất tối thiểu + chia lãi", name: "Lãi suất tối thiểu + chia lãi" },
        { value: "Lãi suất thả nổi", name: "Lãi suất thả nổi" },
    ]

    const handleSearch = (e) => {
        let _param = ''
        if (productStatus !== '') _param += "&status=" + productStatus
        if (city !== '') _param += "&city=" + city
        if (productType !== '') _param += "&type_interest=" + productType
        if (lsStart !== '' && lsEnd !== '') _param += "&lsRange=" + lsStart + "_" + lsEnd

        setParam(_param);
    };

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Cơ hội đầu tư</h2>
                            <div className="tagline">Đầu tư với chúng tôi rất dễ dàng.</div>
                        </div>
                    </div>
                    <img src={require('./tmp/sample-header.jpg')} alt="" />
                </div>
            </header>
            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <form action="projects.html" className="project-filters">
                            <div className="filter-props">
                                <p className="project-filter filter-status">
                                    <label>Tình trạng</label>
                                    <UserDropDown data={listStatus} className="w-100" placeholder="Chọn loại tài khoản" defaultValue={productStatus} name="listType"
                                        onChange={async (e) => { setProductStatus(e); }} all={true}
                                    ></UserDropDown>
                                </p>
                                <p className="project-filter filter-return">
                                    <label>Lãi suất(%)</label>
                                    <input className="minReturn" type="text" placeholder="Từ" value={lsStart} onChange={(e) => setLsStart(e.target.value)} />
                                    <input className="maxReturn" type="text" placeholder="Đến" value={lsEnd} onChange={(e) => setLsEnd(e.target.value)} />
                                </p>
                                <p className="project-filter filter-country">
                                    <label>Tỉnh/thành phố</label>
                                    <UserDropDown apiname='params?idtype=1&idparent=0&limit=1000&orderby_esc=id' colvalue="shorttitle" colname="title" className="w-100" placeholder="Chọn tỉnh thành" defaultValue={city} name="listCity"
                                        onChange={async (e) => { setCity(e); }} all={true}
                                    ></UserDropDown>
                                </p>
                                <p className="project-filter filter-type">
                                    <label>Kiểu sản phẩm</label>
                                    <UserDropDown data={listType} className="w-100" placeholder="Chọn kiểu sản phẩm" defaultValue={productType} name="typeProduct"
                                        onChange={async (e) => { setProductType(e); }} all={true}
                                    ></UserDropDown>
                                </p>
                            </div>
                            <div className="filter-actions mt-4">
                                <button type="button" className="button-primary mr-3" onClick={() => handleSearch()}><em className="mdi mdi-filter"></em> Lọc tìm dự án</button>
                                <button type="reset"><em className="mdi mdi-restart"></em> Bỏ lọc dự án</button>
                            </div>
                        </form>
                        <div className="project-listing">
                            {loading && <div className="d-flex justify-content-center"><Spinner /></div>}
                            {listSP && !loading && listSP.map((infoSP, i) => {
                                return (
                                    <div className="entry-project project-status-active" key={i}>
                                        <div className="project-media">
                                            <a href={"/san-pham/" + infoSP.alias}>
                                                <img src={infoSP.thumbnail ? infoSP.thumbnail : require('./tmp/sample-project.jpg')} width="800" height="600" alt="" />
                                                {/* <span className="project-industry" title="Investment type"><em className="mdi mdi-information"></em> Shoptel</span> */}


                                                {/* <span className="project-country" title="Estonia"><img className="office-country" src={require('./assets/images/ee.svg').default} width="24" alt="" />
                                                    <em className="screen-reader-text">Quảng Ninh</em></span> */}

                                            </a>
                                            <span className="project-status"><em className="mdi mdi-plus"></em>
                                                {infoSP.status === 0 && "Dừng hoạt động"}
                                                {infoSP.status === 1 && "Đã gọi đầu tư"}
                                                {infoSP.status === 2 && "Đang gọi đầu tư"}
                                                {infoSP.status === 3 && "Sắp gọi đầu tư"}
                                                {infoSP.status === 4 && "Đã kết thúc"}
                                            </span>
                                        </div>
                                        <div className="project-details">
                                            <h3 className="project-title"><a href={"/san-pham/" + infoSP.alias}>{infoSP.title}</a></h3>
                                            <p className="project-summary">{infoSP.description}</p>
                                            <div className="project-specs">
                                                <div className="project-return">
                                                    <strong>{countInterest(infoSP)}</strong>
                                                    <span>Lãi dự kiến</span>
                                                </div>
                                                <div className="project-term">
                                                    <strong>{infoSP.time_invest} tháng</strong>
                                                    <span>Thời gian đầu tư</span>
                                                </div>
                                                <div className="project-countdown">
                                                    <strong>{infoSP.status === 1 ? <>Hết hạn đầu tư</>:<>{infoSP.dateend && countdate(infoSP.dateend)} ngày</>}</strong>
                                                    <span>Nhận đầu tư</span>
                                                </div>
                                            </div>
                                            <div className="project-progress">
                                                <div className="progress-bar">
                                                    <div className="progress-current homestep2"></div>
                                                    <div className="progress-minimum homestep3">
                                                        <div className="progress-minimum-pointer">
                                                            <span className="project-minimum-goal-info">Min<span className="screen-reader-text">imum</span> goal</span>
                                                            <span className="project-minimum-goal-amount"><span className="currency-sign">€</span>25 000</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="progress-info">
                                                    <div className="progress-current-amount">Đã đầu tư <strong><span className="currency-sign">{convertPrice(infoSP.dadautu)}</span> </strong> </div>
                                                    <div className="progress-amount-left"><strong>Tổng đầu tư <span className="currency-sign">{convertPrice((infoSP.price) * infoSP.soluong)}</span></strong> </div>
                                                </div>
                                            </div>
                                            <div className="project-actions">
                                                <a href={"/san-pham/" + infoSP.alias} className="button button-primary">Xem chi tiết</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {listSP && totalpage > 1 &&
                            <Pagination
                                activePage={page}
                                itemsCountPerPage={limit}
                                totalItemsCount={totalRecord}
                                pageRangeDisplayed={5}
                                itemClass="page-item"
                                linkClass="page-link"
                                prevPageText="<"
                                nextPageText=">"
                                onChange={setPage}
                            />
                        }

                    </div>
                </main>
            </div>
        </MainFrontend>

    );
}
