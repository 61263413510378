import React, { useState } from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { useFormik } from "formik";
import Spinner from "../component/Spinner";
import { URL_LIST_ALL } from "../../configs/api";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

export default function UploadKhachHang(props) {
  const { user } = useSelector((state) => state.auth);
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues:{grade_id:'',subject_id:''},
    enableReinitialize:true,
   
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();
      formData.append('file',selectedFile,selectedFile.name)
      formData.append('type', 'users');
      formData.append('name', 'customer');
      for (const key in values) {
        formData.append(key, values[key]);
      }
      Axios.post(URL_LIST_ALL+'upload',formData,user.config).then(async (res) => {
        console.log(res)
        setSubmitting(false);
        if (res.data.status === "success") {
            resetForm();
            confirmAlert({
            title: 'Thành công',
            message: 'Bạn đã tải lên danh sách nhân sự thành công',
            buttons: [{ label: 'OK' }]
        }); }else{
            confirmAlert({
                title: 'Lỗi',
                message: res.data.message,
                buttons: [{ label: 'OK' }]
            });
        }
      }).catch((err) => console.log(err));
    },
});
  return (
    <AkkhorLayout idrole="list-users" >
      <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                   
                    <li>Upload khách hàng</li>
                </ul>
            </div>
    <div className="card height-auto mt-4">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Upload khách hàng</h3>
                </div>
            </div>
            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12 mg-t-8">
                  
                  <div className="my-3">
                    <input type="file" onChange={(e)=>setSelectedFile(e.target.files[0] )}></input>
                  </div>
                  <div className="alert alert-primary" role="alert">
                   
                    <div className="mt-3">
                      <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mr-2">{formik.isSubmitting ? <Spinner />:<>Upload</>}</button>
                      <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                    </div>
                  </div>
                </div>
                
              </div>
            </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
