export const TYPE_ACCOUNT = [
  "Quản trị viên",
  "Nhân viên"
];
export const ID_TYPE_ACCOUNT = {
  QUAN_TRI: 0,
  GIAO_VIEN: 1,
  HOC_SINH: 2,
  PHU_HUYNH: 3,
}
export const LIST_TABLE = [
  "schools",
  "grades",
  "class",
  "students",
  "subjects",
  "timetable",
  "users",
  "years",
];
export const LIST_ALPHA = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
export const LIST_NATION = [
  "Kinh",
  "Tày",
  "Thái",
  "Mường ",
  "Khmer",
  "Hoa ",
  "Nùng  ",
  "Mông",
  "Dao",
  "Gia Rai",
  "Ê  Đê",
  "Ba Na",
  "Sán Chay ",
  "Chăm ",
  "Cơ Ho",
  "Xơ Đăng",
  "Sán Dìu",
  "Hrê",
  "RaGlay",
  "Mnông",
  "Thổ",
  "Xtiêng",
  "Khơ mú",
  "Bru Vân Kiều",
  "Cơ Tu",
  "Giáy",
  "Tà Ôi",
  "Mạ",
  "Giẻ-Triêng",
  "Co",
  "Chơ Ro",
  "Xinh Mun",
  "Hà Nhì",
  "Chu Ru",
  "Lào",
  "La Chí",
  "Kháng",
  "Phù Lá",
  "La Hủ",
  "La Ha",
  "Pà Thẻn",
  "Lự",
  "Ngái",
  "Chứt",
  "Lô Lô",
  "Mảng",
  "Cơ Lao",
  "Bố Y",
  "Cống",
  "Si La",
  "Pu Péo",
  "Rơ Măm",
  "Brâu",
  "Ơ Đu",
];
export const COLORS_CHECK = [
  "#999",
  "#21c83d",
  "#efd732",
  "#fd7a23",
  "#db0d18",
];

export const RolesRight = {
  
  dashboard: { title: "Bảng điều khiển", checked: false },
  
  "list-users": {
    title: "Quản lý tài khoản",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      upload: { title: "Upload", checked: false },
      export: { title: "Xuất dữ liệu", checked: false },
      roles: { title: "Phân quyền", checked: false },
      login: { title: "Đăng nhập", checked: false },
      reset: { title: "Đổi mật khẩu", checked: false },
    },
  },
  "customer": {
    title: "Quản lý khách hàng",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      upload: { title: "Upload", checked: false },
      export: { title: "Xuất dữ liệu", checked: false },
    },
  },

  "tongquan-laisuat": {
    title: "Quản lý tổng quan lãi suất",
    checked: false,
    children: {
      view: { title: "Xem", checked: false },
      export: { title: "Xuất dữ liệu", checked: false },
    },
  },
  "chitiet-laisuat": {
    title: "Quản lý chi tiết lãi suất",
    checked: false,
    children: {
      view: { title: "Xem", checked: false },
      export: { title: "Xuất dữ liệu", checked: false },
    },
  },
  "san-pham": {
    title: "Quản lý sản phẩm",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false }
    },
  },
  "chiphi": {
    title: "Quản lý chi phí sản phẩm",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false }
    },
  },
  "product_meta": {
    title: "Quản lý số liệu sản phẩm",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      approve: { title: "Phê duyệt", checked: false },
    },
  },
  "banghang": {
    title: "Quản lý bảng hàng",
    checked: false,
    children: {
      view: { title: "Xem", checked: false },
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      upload: { title: "Upload", checked: false },
    },
  },
  "transaction": {
    title: "Quản lý giao dịch",
    checked: false,
    children: {
      edit: { title: "Sửa", checked: false }
    },
  },
  "du-an": {
    title: "Quản lý dự án",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false }
    },
  },
  "danh-muc-tin-tuc": {
    title: "Quản lý danh mục tin tức",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false }
    },
  },
  "tin-tuc": {
    title: "Quản lý tin tức",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false }
    },
  },
  "list-roles": {
    title: "Quản lý quyền hạn",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  
 
  "list-settings": {
    title: "Quản lý cài đặt thông số",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  
  "danh-sach-gui-mail": {
    title: "Hệ thống gửi mail",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "email-body": {
    title: "Quản lý nội dung email",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "test-email": {
    title: "Test mail",
    checked: false,
  },
};


export const CONFIG_EDITOR = {

  toolbar: {
    items: [
      'heading', 'MathType', 'ChemType',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'imageUpload',
      'mediaEmbed',
      'insertTable',
      'blockQuote',
      'undo',
      'redo'
    ]
  },
}
export const CONFIG_STEP_TEST_1 = ['Thông tin phiếu bài', 'Lựa chọn câu hỏi', 'Chọn học sinh']
export const CONFIG_STEP_TEST_2 = ['Thông tin đề kiểm tra', 'Lựa chọn câu hỏi', 'Phát đề kiểm tra']
