import { useFormik } from "formik";
import React, { useState } from "react";
import MainFrontend from "./template/Main";
import * as Yup from "yup";

import axios from 'axios';
import { URL_LIST_ALL } from "../configs/api";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "../adminpage/component/Spinner";
import AlertErrorForm from "../adminpage/component/AlertErrorForm";
import { Redirect } from "react-router-dom";


export default function DangKy() {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false)
  

  const formikRegist = useFormik({
    initialValues: { fullname: '', email: "", mobile: '', password: '', repassword: '', sex: '' },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      fullname: Yup.string().trim().nullable().required("Vui lòng nhập họ tên"),
      email: Yup.string().trim().nullable().required("Vui lòng nhập email").email("Địa chỉ email không chính xác"),
      birthday: Yup.string().trim().nullable().required("Vui lòng nhập ngày sinh"),
      mobile: Yup.string().trim().nullable().required("Vui lòng nhập số điện thoại"),
      password: Yup.string().trim().nullable().min(6, "Mật khẩu có độ dài tối thiểu 6 ký tự").max(150, "Mật khẩu có độ dài tối đa 150 ký tự").required("Vui lòng nhập mật khẩu"),
      repassword: Yup.string().trim().nullable().required("Vui lòng nhập lại mật khẩu").oneOf([Yup.ref('password'), null], 'Nhập lại mật khẩu không đúng')
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      axios.post(URL_LIST_ALL + 'customer-regist', values).then(res => {
        setLoading(false);
        setSubmitting(false);
        if (res.data.status === "success") {
          confirmAlert({
            title: 'Thông báo',
            message: "Đăng ký thành công",
            buttons: [{
              label: 'Đồng ý', onClick: () => {

              }
            }]
          });
          resetForm()
        } else {
          confirmAlert({
            title: 'Thông báo',
            message: res.data.message,
            buttons: [{ label: 'Đồng ý' }]
          });
        }
      }).catch(err => console.log(err));
    },
  });

  return (
    <MainFrontend>
       {user && user.data.typeaccount ==='customer' && <Redirect to="/" />}
      <div className="login-container">
        <div id="container" className="container">

          <div className="col align-items-center flex-col d-flex justify-content-center align-item-center sign-up">
            <div className="form-wrapper align-items-center">
              <div className="form sign-up">
                <form className="w-100" method="post" onSubmit={formikRegist.handleSubmit} autoComplete="nope">
                  <div className='col-12'>
                    <div className="input-group">
                      <label>Họ tên</label>
                      <input type="text" placeholder="Họ tên" {...formikRegist.getFieldProps("fullname")} autoComplete="off" />
                      <AlertErrorForm formik={formikRegist} name="fullname"></AlertErrorForm>
                    </div>
                    <div className="input-group">
                      <label>Email</label>
                      <input type="text" placeholder="Email" {...formikRegist.getFieldProps("email")} autoComplete="off" />
                      <AlertErrorForm formik={formikRegist} name="email"></AlertErrorForm>
                    </div>
                    <div className="input-group">
                      <label>Số điện thoại</label>
                      <input type="text" placeholder="Số điện thoại"  {...formikRegist.getFieldProps("mobile")} autoComplete="off" />
                      <AlertErrorForm formik={formikRegist} name="mobile"></AlertErrorForm>
                    </div>
                    <div className="input-group">
                      <label>Ngày sinh</label>
                      <input type="date" name="birthday" className="underline"  {...formikRegist.getFieldProps("birthday")} />
                      <AlertErrorForm formik={formikRegist} name="birthday"></AlertErrorForm>
                    </div>
                    <div className="form-group">
                      <label>Giới tính</label><br />
                      <span>
                        <input type="radio" name="picked" value="Nam" checked={formikRegist.values.sex === "Nam"} onChange={(e) => formikRegist.setFieldValue('sex', e.target.value)} />
                        Nam
                      </span>
                      <span className='ml-2'>
                        <input type="radio" name="picked" value="Nữ" checked={formikRegist.values.sex === "Nữ"} onChange={(e) => formikRegist.setFieldValue('sex', e.target.value)} />
                        Nữ
                      </span>
                    </div>
                    <div className="input-group">
                      <label>Mật khẩu</label>
                      <input type="password" placeholder="Mật khẩu" {...formikRegist.getFieldProps("password")} autoComplete="off" />
                      <AlertErrorForm formik={formikRegist} name="password"></AlertErrorForm>
                    </div>
                    <div className="input-group">
                      <label>Xác nhận mật khẩu</label>
                      <input type="password" placeholder="Xác nhận mật khẩu" {...formikRegist.getFieldProps("repassword")} autoComplete="off" />
                      <AlertErrorForm formik={formikRegist} name="repassword"></AlertErrorForm>
                    </div>
                    <button className="btn" type="submit" >
                      {loading ? <Spinner /> : <>Đăng ký</>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </MainFrontend>
  )
}
