import React, { useState, useEffect } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useSelector } from "react-redux";
// import MUIDataTable from "mui-datatables";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { checkRole } from "../model";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import FastLogin from "./FastLogin";
import PartsOfMember from "./PartsOfMember";
import ResetPass from "./ResetPass";
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";

function SRList(props) {
  const parsed = window.location.search.replace('?', '');
  let { children, idrole, name, linkaction, defaultLimit, upload, params, columns, onlyList, noAction, moresearch, refresh, TopBar, noedit, nodelete } = props;
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const limit = defaultLimit ? defaultLimit : 10;
  const [totalRecord, setTotalRecord] = useState(defaultLimit ? defaultLimit : 10);
  const [change, setChange] = useState(false);
  const allowAdd = checkRole(user, idrole, 'add');
  const allowEdit = checkRole(user, idrole, 'edit');
  const allowDelete = checkRole(user, idrole, 'delete');
  const allowUpload = checkRole(user, idrole, 'upload');
  const allowView = checkRole(user, idrole, 'view');
  const allowAccept = checkRole(user, idrole, 'approve');
  const allowExport = checkRole(user, idrole, 'export');
  // const allowRoles = checkRole(user, idrole, 'roles');
  const allowPassword = checkRole(user, idrole, 'reset');
  //console.log(parsed)
  useEffect(
    function () {
      let mounted = true;
      if (mounted) {
        setRows([]);
        setLoading(true)
      }
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.access_token,
          'Content-Type': 'application/json; charset=UTF-8',
          'search': JSON.stringify(moresearch)
        }
      }
      Axios.get(URL_LIST_ALL + name + "?&page=" + page + "&limit=" + limit + (params ? '&' + params : '') + (parsed ? '&' + parsed : ''), config)
        .then((res) => {
          if (mounted) setLoading(false)
          if (res.data.status === "success") {
            if (mounted) {
              setRows(res.data.data);
              setTotalRecord(res.data.total);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => {
          if (mounted) setLoading(false)
        });
      return () => (mounted = false);
    },
    [name, change, user, page, limit, params, parsed, moresearch, refresh]
  );

  const actionDelete = (id) => {
    confirmAlert({
      title: "Thông báo",
      message: "Bạn có chắc muốn xoá dữ liệu",
      buttons: [
        {
          label: "Có",
          onClick: () => {
            Axios.delete(URL_LIST_ALL + name + '/' + id, user.config).then(res => {
              if (res.data.status === "success") {
                setChange(!change);
              } else alert(res.data.message);
            }).catch(err => console.log(err));
          },
        },
        { label: "Không" },
      ],
    });
  }

  const actionAprove = (id, data) => {

    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn phê duyệt ?",
      buttons: [{
        label: "Có",
        onClick: () => {
          setLoading(true)
          Axios.put(URL_LIST_ALL + 'approve_' + name + '/' + id, data, user.config).then(res => {
            if (res.data.status === "success") {
              setChange(!change);
            } else alert(res.data.message);
            setLoading(false)
          }).catch(err => { console.log(err); setLoading(false) });
        },
      }, { label: "Không" },
      ],
    });
  }

  return (
    <>
      {loading && (<Loading />)}
      {!onlyList && <div className="d-flex justify-content-end">
        {TopBar && <>{TopBar}</>}
        {allowExport &&
          <Link
            to={"/admin/export-" + (linkaction ? linkaction : name)}
            className="btn btn-info m-2"
            style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
            <i className="fas fa-plus-"></i> Xuất file
          </Link>}
        {!noAction && allowAdd &&
          <Link
            to={"/admin/add-" + (linkaction ? linkaction : name)}
            className="btn btn-success m-2"
            style={{ fontSize: 13, fontWeight: '600' }}>
            <i className="fas fa-plus-"></i> Thêm mới
          </Link>}
        {!upload && allowUpload &&
          <Link
            to={"/admin/upload-" + (linkaction ? linkaction : name)}
            className="btn btn-info m-2"
            style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
            <i className="fas fa-plus-"></i> Upload
          </Link>}
      </div>}
      {children}

      {columns && columns.length > 0 &&
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                {columns.map((item, i) => {
                  return <th key={i}>{item.label}</th>
                })}
                {allowPassword && <td>Đổi mật khẩu</td>}
                {(allowDelete || allowEdit || allowView || allowAccept) && name !== 'users_onday' && <td>Thao tác</td>}
              </tr>
            </thead>
            <tbody>
              {rows && rows.length > 0 && rows.map((itemTr, iTr) => {
                // let idrow = itemTr.id;
                return (
                  <tr key={iTr}>
                    {columns.map((itemTd, iTd) => {
                      let result = itemTr[itemTd.name]
                      if (itemTd.name === 'datecreate' || itemTd.name === 'datemodified') {
                        if (itemTr[itemTd.name]) {
                          let time = new Date(itemTr[itemTd.name] * 1000);
                          result = <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>
                        }

                      }
                      if (itemTd.type && itemTd.type === 'fastlogin') {
                        result = <FastLogin id={itemTr[itemTd.name]} />;
                      }
                      if (itemTd.name === 'partofmember') {
                        result = <PartsOfMember id={itemTr[itemTd.name]} />;
                      }
                      if (itemTd.type && itemTd.type === 'resetpass') {
                        result = <ResetPass id={itemTr[itemTd.name]} />;
                      }

                      if (name === 'users_onday' && itemTd.name === 'status') {
                        result = itemTr[itemTd.name] === 1 ? "Đúng giờ" : "Muộn";
                      }
                      if (itemTd.name === 'status') {
                        result = itemTr[itemTd.name] === 1 ? "Hoạt động" : "Ngưng hoạt động";
                      }

                      if (itemTd.name === 'price' || itemTd.name === 'total_price' || itemTd.name === 'tonglaihientai' || itemTd.name === 'tongtienlaidukien' || itemTd.name === 'tran_price' || itemTd.name === 'tienlaitoithieu' || itemTd.name === 'tienlaithuong' || itemTd.name === 'giatrihientai') {
                        result = <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={itemTr[itemTd.name]} />
                      }

                      if ((name === 'product_meta') && itemTd.name === 'status') {
                        if (itemTr[itemTd.name] === 0) {
                          result = <>
                            <span className="text-warning">Chờ duyệt</span><br />
                            {allowAccept && <button className="btn btn-sm btn-success" onClick={() => actionAprove(itemTr.id, { status: 1 })}>Phê duyệt</button>}<br />
                            {allowAccept && <button className="btn btn-sm btn-danger mt-1" onClick={() => actionAprove(itemTr.id, { status: 2 })}>Từ chối</button>}
                          </>
                        }
                        if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đã duyệt</span>
                        if (itemTr[itemTd.name] === 2) result = <span className="text-danger">Không duyệt</span>
                        if (itemTr[itemTd.name] === 3) result = <span className="text-info">Đang xem xét</span>
                        if (itemTr[itemTd.name] === 4) result = <span className="text-info">Chờ gửi</span>
                        if (itemTr[itemTd.name] === 5) result = <span className="text-danger">Hết thời hạn</span>
                      }

                      if (name === 'sendmail') {
                        if (itemTr[itemTd.name] === 0) result = <span className="text-warning">Chưa gửi</span>
                        if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đã gửi</span>
                        if (itemTr[itemTd.name] === 2) result = <span className="text-danger">Gửi thất bại</span>
                      }

                      if (name === 'banghang') {
                        if (itemTd.name === 'don_giagoc' || itemTd.name === 'giagoc' || itemTd.name === 'don_giaban' || itemTd.name === 'giaban') {
                          result = <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={itemTr[itemTd.name]} />
                        }
                        if (itemTd.name === 'status') {
                          result = itemTr[itemTd.name] === 1 ? <span className="text-success">Đã bán</span> : <span className="text-warning">Chưa bán</span>
                        }
                      }


                      if (itemTd.name === 'datestart' || itemTd.name === 'date_start' || itemTd.name === 'dateend' || itemTd.name === 'date_end' || itemTd.name === 'date_check' || itemTd.name === 'datecheck') {
                        if (itemTr[itemTd.name]) {
                          let time = new Date(itemTr[itemTd.name]);
                          result = <Moment format="DD/MM/YYYY">{time}</Moment>
                        }
                      }

                      if (name === 'transaction' && itemTd.name === 'status') {
                        result = itemTr[itemTd.name]
                      }

                      return <td key={iTd}>{result}</td>
                    })}
                    {allowPassword &&
                      <td><ResetPass id={itemTr.id} /></td>
                    }
                    {(allowEdit || allowDelete || allowView || allowAccept) && name !== 'users_onday' &&
                      <td>
                        {allowEdit && !noedit && <Link to={"/admin/edit-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-warning m-1" data-toggle="tooltip" data-placement="bottom" title="Sửa"><i className="fas fa-pencil-alt"></i></Link>}
                        {allowDelete && !nodelete && <button className="btn btn-sm btn-danger m-1" onClick={() => actionDelete(itemTr.id)} data-toggle="tooltip" data-placement="bottom" title="Xóa"><i className="fas fa-trash-alt"></i></button>}
                        {allowView && name !== 'users_onday' && <Link to={"/admin/view-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-warning m-1" data-toggle="tooltip" data-placement="bottom" title="Xem"><i className="fas fa-eye"></i></Link>}
                        {allowAccept && <Link to={"/admin/approve-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-success m-1" data-toggle="tooltip" data-placement="bottom" title="Phê duyệt"><i className="fas fa-clipboard-check"></i></Link>}
                      </td>
                    }
                  </tr>
                )
              })
              }
            </tbody>
          </Table>
          {totalRecord > limit &&
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalRecord}
              pageRangeDisplayed={5}
              itemClass="page-item"
              linkClass="page-link"
              prevPageText="<"
              nextPageText=">"
              onChange={setPage}
            />
          }

        </>
      }
    </>
  );
}
export default React.memo(SRList)
//https://github.com/gregnb/mui-datatables