import React from "react";
import MainFrontend from "./template/Main";

export default function AccountFunding() {

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Nạp và rút tiền</h2>
                        </div>
                    </div>
                </div>
            </header>

            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <div className="tab-container style-minimal">
                            <ul className="tabs clearfix">
                                <li><a href="#tab-deposit" className="tab-link-active">Nạp tiền</a></li>
                                <li><a href="#tab-withdraw">Rút tiền</a></li>
                            </ul>
                            <div id="tab-deposit" className="tab-content tab-active">
                                <div className="row">
                                    <div className="col-12 col-lg-9">
                                        <p>Để bắt đầu đầu tư, bạn cần gửi tiền vào Tài khoản Nhà đầu tư của mình bằng cách chuyển khoản vào tài khoản ngân hàng được nêu 
                                            bên dưới. Quá trình xử lý có thể mất đến 2-3 ngày làm việc. Sau khi tiền đã ký gửi được thêm vào Tài khoản Nhà đầu tư của bạn, 
                                            bạn sẽ nhận được e-mail xác nhận và có thể thực hiện đầu tư.</p>
                                        <div className="data-box">
                                            <div className="data-property">Họ tên chủ tài khoản</div>
                                            <div className="data-value">Nguyễn Văn Sao</div>
                                            <div className="data-property">Số tài khoản</div>
                                            <div className="data-value">LT432000050009271982</div>
                                            <div className="data-property">Tên ngân hàng</div>
                                            <div className="data-value">Techcombank</div>
                                            <div className="data-property">Tên chi nhánh</div>
                                            <div className="data-value">Hoàng Quốc Việt</div>
                                        </div>
                                        <p className="text-small text-faded">   
                                            Chúng tôi giữ tiền của nhà đầu tư được tách biệt khỏi quỹ cho hoạt động kinh doanh. 
                                            Các khoản tiền của nhà đầu tư không được bao gồm trong báo cáo tài chính của chúng tôi. 
                                            Bạn có thể rút các khoản tiền không được đầu tư vào các khoản vay từ tài khoản nhà đầu 
                                            tư về tài khoản ngân hàng của mình bất kỳ lúc nào.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-withdraw" className="tab-content">
                                <div className="row">
                                    <div className="col-12 col-lg-9">
                                        <div className="data-box">
                                            <div className="data-property">Rút tiền</div>
                                            <div className="data-value"><input type="number" step="0.1" placeholder="Amount" /></div>
                                            <div className="data-property">Số dư khả dụng</div>
                                            <div className="data-value">VNĐ 215.50</div>
                                            <div className="data-property">Số tiền</div>
                                            <div className="data-value"><input type="text" id="account" name="account" disabled="" value="" /></div>
                                            <div className="data-actions"><button>Rút tiền</button></div>
                                        </div>
                                        <p className="text-small text-faded">
                                            Chúng tôi giữ tiền của nhà đầu tư được tách biệt khỏi quỹ cho hoạt động kinh doanh. 
                                            Bạn có thể rút các khoản tiền không được đầu tư vào các khoản vay từ tài khoản nhà 
                                            đầu tư về tài khoản ngân hàng của mình bất kỳ lúc nào.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </MainFrontend>
    );
}
