import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions } from "../redux/authRedux";


export default function DangXuat() {
  const dispatch = useDispatch();
  useEffect(function() {
    dispatch(actions.login(null));

  },[dispatch])

  return <Redirect to="/" />;
}
