import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectSearch from "react-select-search";
import { URL_LIST_ALL } from "../../configs/api";
export default function UserDropDown(props) {
    const { apiname, data, placeholder, formik, name, onChange, colvalue, colname, defaultValue, disabled, all, autoSelect, onDataList } = props;
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);
    const [list, setList] = useState(data ? data : []);

    const currenOnchange = async (e) => {
        if (formik) {
            await formik.setFieldValue(name, e)
            await formik.setFieldTouched(name)
        }
    }
    useEffect(() => {
        setValue(formik ? formik.values[name] : defaultValue)
    }, [formik, name, defaultValue])
    //Lấy danh sách dữ liệu
    useEffect(() => {
        if (!data && apiname) {
            let mounted = true;
            if (!data) {
                if (mounted) setLoading(true);
                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + (user ? user.access_token : ""),
                        'asselect': JSON.stringify([colvalue + " as value", colname + " as name"])
                    }
                }
                Axios.get(URL_LIST_ALL + apiname, config).then((res) => {
                    setLoading(false);
                    if (res.data && res.data.status === "success") {
                        if (mounted) {
                            let localList = []
                            if (all === true) localList.push({ value: "all", name: "Tất cả" })
                            localList = localList.concat(res.data.data)
                            setList(localList);
                            if (autoSelect === true && localList.length > 0 && onChange && !defaultValue) {
                                setValue(localList[0].value)
                                onChange(localList[0].value)
                            }
                            if (onDataList) {
                                onDataList(localList);
                            }
                        }
                    } else console.log(res.data.message);
                }).catch((err) => { setLoading(false); console.log(err) });
            }
            return () => (mounted = false);
        }

    }, [user, apiname, data, colvalue, colname, all]);// eslint-disable-line



    return <SelectSearch disabled={disabled || loading} value={value} search options={list} placeholder={placeholder} onChange={onChange ? onChange : currenOnchange} />;
}
//https://github.com/elrumordelaluz/reactour