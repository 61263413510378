
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import Axios from "axios";

import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Moment from "react-moment";
import AkkhorLayout from "../layout/akkhor";
import { URL_LIST_ALL } from "../../configs/api";
import Spinner from "../component/Spinner";
import NumberFormat from "react-number-format";

export default function FormGiaoDich() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const defaultdetail = { status: 0, date_start: '', checker: '' }

    useEffect(function () {
        // console.log(user.data);
        if (id > 0) {
            let mounted = true;
            Axios.get(URL_LIST_ALL + "transaction/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        if (mounted) {
                            const data = res.data.data
                            setDetail(data);
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            return () => (mounted = false);
        }
    }, [user, id, refresh]);

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'transaction/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn phê duyệt thành công',
                            buttons: [{
                                label: 'Đồng ý'
                            }
                            ]
                        });

                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="transaction">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/admin/transaction">Danh sách giao dịch</Link>
                    </li>
                    <li>Quản lý giao dịch</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Quản lý giao dịch</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <table className="table table-borderless">
                                    <tbody>
                                        {
                                            detail && <>
                                                <tr>
                                                    <td><b>Mã giao dịch:</b> </td>
                                                    <td>{detail.code ? detail.code : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Họ và tên:</b> </td>
                                                    <td>{detail.cus_name ? detail.cus_name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Mã khách hàng:</b> </td>
                                                    <td>{detail.cus_code ? detail.cus_code : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Thời gian giao dịch:</b> </td>
                                                    <td>{detail && detail.datecreate ? <Moment format="H:m:s DD/MM/YYYY">{new Date(detail.datecreate * 1000)}</Moment> : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Số lượng giao dịch:</b> </td>
                                                    <td>{detail.number ? detail.number : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Đơn giá:</b> </td>
                                                    <td>{detail.price ? <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail.price} /> : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Thành tiền:</b> </td>
                                                    <td>{detail.total_price ? <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={detail.total_price} /> : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>Trạng thái:</td>
                                                    <td>
                                                        <div className="w-100 form-group text-center">
                                                            <select className="form-select height32" {...formik.getFieldProps("status")}>
                                                                <option value="Chờ xác nhận">Chờ xác nhận</option>
                                                                <option value="Chờ ký hợp đồng">Chờ ký hợp đồng</option>
                                                                <option value="Giao dịch chờ thanh toán">Giao dịch chờ thanh toán</option>
                                                                <option value="Giao dịch thành công">Giao dịch thành công</option>
                                                                <option value="Giao dịch thất bại">Giao dịch thất bại</option>
                                                            </select>
                                                            {formik.values['status'] === 'Giao dịch thất bại' &&
                                                                <textarea className="form-control mt-2" {...formik.getFieldProps("comment")} placeholder="Lý do"></textarea>
                                                            }
                                                           
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 mt-3"></div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Cập nhập</>}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
