import React from "react";
import MainFrontend from "./template/Main";

export default function Statistics() {

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Statistics</h2>
                            <p className="tagline">Updated on 09.03.2022 18:50</p>
                        </div>
                    </div>
                    <img src={require('./tmp/sample-header.jpg')} alt="" />
                </div>
            </header>

            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9">
                                <h3 className="entry-title">Outstanding Portfolio</h3>
                                <div className="statistics style-horizontal">
                                    <div className="statistics-item" data-percent="43%">
                                        <span className="item-label">2019 Q3</span>
                                        <span className="item-value"><em>€15,685k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="45%">
                                        <span className="item-label">2019 Q4</span>
                                        <span className="item-value"><em>€16,415k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="36%">
                                        <span className="item-label">2020 Q1</span>
                                        <span className="item-value"><em>€13,132k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="34%">
                                        <span className="item-label">2020 Q2</span>
                                        <span className="item-value"><em>€12,402k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="42%">
                                        <span className="item-label">2020 Q3</span>
                                        <span className="item-value"><em>€15,321k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="56%">
                                        <span className="item-label">2020 Q4</span>
                                        <span className="item-value"><em>€20,428k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="77%">
                                        <span className="item-label">2021 Q1</span>
                                        <span className="item-value"><em>€28,088k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="83%">
                                        <span className="item-label">2021 Q2</span>
                                        <span className="item-value"><em>€30,277k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="100%">
                                        <span className="item-label">2021 Q3</span>
                                        <span className="item-value"><em>€36,478k</em></span>
                                    </div>
                                </div>
                            </div>
                            <div className="w100"></div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="featured-box style-horizontal">
                                    <em className="featured-box-icon mdi mdi-currency-eur"></em>
                                    <h5 className="featured-box-title">€36,478,611</h5>
                                    <p className="featured-box-content">Money lent</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="featured-box style-horizontal">
                                    <em className="featured-box-icon mdi mdi-brightness-percent"></em>
                                    <h5 className="featured-box-title">13.5%</h5>
                                    <p className="featured-box-content">Average return</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="featured-box style-horizontal">
                                    <em className="featured-box-icon mdi mdi-briefcase-outline"></em>
                                    <h5 className="featured-box-title">143</h5>
                                    <p className="featured-box-content">Funded projects</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="featured-box style-horizontal">
                                    <em className="featured-box-icon mdi mdi-account-multiple-outline"></em>
                                    <h5 className="featured-box-title">12824</h5>
                                    <p className="featured-box-content">Investors</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="featured-box style-horizontal">
                                    <em className="featured-box-icon mdi mdi-cash"></em>
                                    <h5 className="featured-box-title">€820,413</h5>
                                    <p className="featured-box-content">Investors earnings</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="featured-box style-horizontal">
                                    <em className="featured-box-icon mdi mdi-sack"></em>
                                    <h5 className="featured-box-title">€940,911</h5>
                                    <p className="featured-box-content">Backup Fund</p>
                                </div>
                            </div>
                            <div className="spacing1"></div>
                            <div className="col-md-10 col-lg-9">
                                <h3 className="entry-title">Interest paid to investors</h3>
                                <div className="statistics style-vertical">
                                    <div className="statistics-item" data-percent="13%">
                                        <span className="item-label">09-2018</span>
                                        <span className="item-value"><em>€591k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="18%">
                                        <span className="item-label">01-2019</span>
                                        <span className="item-value"><em>€819k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="27%">
                                        <span className="item-label">03-2019</span>
                                        <span className="item-value"><em>€1,229k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="34%">
                                        <span className="item-label">06-2019</span>
                                        <span className="item-value"><em>€1,548k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="41%">
                                        <span className="item-label">09-2019</span>
                                        <span className="item-value"><em>€1,866k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="50%">
                                        <span className="item-label">01-2020</span>
                                        <span className="item-value"><em>€2,276k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="58%">
                                        <span className="item-label">03-2020</span>
                                        <span className="item-value"><em>€2,640k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="69%">
                                        <span className="item-label">06-2020</span>
                                        <span className="item-value"><em>€3,141k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="76%">
                                        <span className="item-label">09-2020</span>
                                        <span className="item-value"><em>€3,460k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="83%">
                                        <span className="item-label">01-2021</span>
                                        <span className="item-value"><em>€3,779k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="92%">
                                        <span className="item-label">03-2021</span>
                                        <span className="item-value"><em>€4,188k</em></span>
                                    </div>
                                    <div className="statistics-item" data-percent="100%">
                                        <span className="item-label">06-2021</span>
                                        <span className="item-value"><em>€4,553k</em></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
                <div className="site-section section-call-to-action has-media dotted-pattern dotted-pattern-top-right">
                    <div className="container">
                        <h2 className="entry-title">Earn income while investing in meaningful businesses.</h2>
                        <p className="no-margin-bottom">
                            <a href="/san-pham-dau-tu" className="button button-primary"><em className="mdi mdi-briefcase-outline"></em> Invest Now</a>
                            <a href="/lien-he" className="button button-light button-outline"><em className="mdi mdi-email"></em> Get in touch</a>
                        </p>
                    </div>
                    <video id="sample-video" src="tmp/sample-video.mp4" poster="tmp/sample-video.jpg" autoplay="" loop="" muted=""></video>
                </div>
            </div>
        </MainFrontend>
    );
}
