import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Loading from "./adminpage/component/Loading";
import HomePage from "./adminpage/home";
import { actions } from "./redux/authRedux";
import FormRolesPage from "./adminpage/phan-quyen/FormRoles";
import ListRolesPage from "./adminpage/phan-quyen/ListRoles";
import FormUsersPage from "./adminpage/nhan-su/FormUsers";
import ListUsersPage from "./adminpage/nhan-su/ListUsers";
import UploadUsers from "./adminpage/nhan-su/UploadUsers";
import LayMatKhau from "./adminpage/home/LayMatKhau";
import DoiMatKhau from "./adminpage/home/DoiMatKhau";
import XuatNhanSu from "./adminpage/nhan-su/XuatNhanSu";
import Login from "./adminpage/auth/Login";
import Logout from "./adminpage/auth/Logout";
import Registration from "./adminpage/auth/Registration";
import ForgotPassword from "./adminpage/auth/ForgotPassword";
import SignUpFinish from "./adminpage/auth/SignUpFinish";
import DanhSachKhachHang from "./adminpage/khach-hang/DanhSachKhachHang";
import FormKhachHang from "./adminpage/khach-hang/FormKhachHang";
import DanhSachSanPham from "./adminpage/san-pham/DanhSachSanPham";
import FormSanPham from "./adminpage/san-pham/FormSanPham";
import DanhSachDuAn from "./adminpage/du-an/DanhSachDuAn";
import FormDuAn from "./adminpage/du-an/FormDuAn";
import TrangChu from "./frontend/TrangChu";
import FrontendDuAn from "./frontend/DuAn";
import AboutUs from "./frontend/GioiThieu";
import AccountFunding from "./frontend/account-funding";
import AccountInvestments from "./frontend/account-investments";
import AccountOverview from "./frontend/account-overview";
import AccountProfile from "./frontend/account-profile";
import AccountStatement from "./frontend/account-statement";
import Careers from "./frontend/careers";
import Contact from "./frontend/contact";
import News from "./frontend/news";
import SinglePost from "./frontend/single-post";
import SingleProject from "./frontend/single-project";
import Statistics from "./frontend/statistics";
import TroGiup from "./frontend/help";
import Error404 from "./frontend/404";
import VerifyEmail from "./frontend/VerifyEmail";
import DanhSachTin from "./adminpage/tin-tuc/DanhSachTin";
import FormTin from "./adminpage/tin-tuc/FormTin";
import DanhMucTin from "./adminpage/tin-tuc/DanhMucTin";
import FormDanhMucTin from "./adminpage/tin-tuc/FormDanhMucTin";
import DanhSachGiaoDich from "./adminpage/giao-dich/DanhSachGiaoDich";
import FormGiaoDich from "./adminpage/giao-dich/FormGiaoDich";
import DangXuat from "./frontend/dang-xuat";
import ListEmailBody from "./adminpage/email-body/ListEmailBody";
import FormEmailBody from "./adminpage/email-body/FormEmailBody";
import ListSettingsPage from "./adminpage/settings/ListSettings";
import FormSettings from "./adminpage/settings/FormSettings";
import DangNhap from "./frontend/dang-nhap";
import DangKy from "./frontend/dang-ky";
import DanhSachSLSPDaBan from "./adminpage/san-pham/DanhSachSLSPDaBan";
import FormSLSPDaBan from "./adminpage/san-pham/FormSLSPDaBan";
import DuyetSLSPDaBan from "./adminpage/san-pham/DuyetSLSPDaBan";
import TestEmail from "./adminpage/test-email/TestEmail";
import UploadKhachHang from "./adminpage/khach-hang/UploadKhachHang";
import TongQuanLaiSuat from "./adminpage/lai-suat/TongQuanLaiSuat";
import TongQuanSanPham from "./adminpage/lai-suat/TongQuanSanPham";
import ChiTietLaiSuat from "./adminpage/lai-suat/ChiTietLaiSuat";
import ChiTietDauTu from "./frontend/ChiTietDauTu";
import DanhSachBangHang from "./adminpage/banghang/DanhSachBangHang";
import UploadBangHang from "./adminpage/banghang/UploadBangHang";
import FormBangHang from "./adminpage/banghang/FormBangHang";
import DanhSachSanPhamBangHang from "./adminpage/banghang/DanhSachSanPhamBangHang";
import DanhSachChiPhiSanPham from "./adminpage/chi-phi-san-pham/DanhSachChiPhiSanPham";
import FormChiPhi from "./adminpage/chi-phi-san-pham/FormChiPhi";


function App(props) {
  const { user } = useSelector((state) => state.auth);
  const now = parseInt(new Date().getTime() / 1000) + 1 * 60 * 60;
  const dispatch = useDispatch();
  
  useEffect(function () {
    const pathname = window.location.pathname
    if(pathname === '/admin'){
      if (user && user.data.typeaccount === "customer") {
        dispatch(actions.login(null));
      }
    }
    if (user && user.data.typeaccount === "admin" && !user.data.roles) dispatch(actions.login(null));
  }, [dispatch, user])

  useEffect(function () {
    if(user && user.data && user.data.typeaccount === "customer"){

    }
  }, [user])


  return (
    <BrowserRouter basename={""}>
      <Suspense fallback={<Loading color="secondary" />} >
        <Switch>
          <Route path="/admin">
            <Switch>
              <Redirect exact={true} from="/admin" to="/admin/login" />
              <Route path="/admin/login" component={Login} />
              <Route path="/admin/logout" component={Logout} />
              <Route path="/admin/sign-up" component={Registration} />
              <Route path="/admin/sign-up-finish" component={SignUpFinish} />
              <Route path="/admin/forgot-password" component={ForgotPassword} />
            </Switch>
            {user && user.data && user.data.typeaccount === "admin" ?
              <>
                {user.expires > now ? <>
                  <Route exact path="/admin/"><Redirect to="/admin/dashboard" /></Route>
                  <Route path="/admin/dashboard"><HomePage></HomePage></Route>
                  <Route path="/admin/list-roles"><ListRolesPage></ListRolesPage></Route>
                  <Route path="/admin/edit-roles/:id"><FormRolesPage></FormRolesPage></Route>
                  <Route path="/admin/add-roles"><FormRolesPage></FormRolesPage></Route>
                  <Route path="/admin/list-users"><ListUsersPage></ListUsersPage></Route>
                  <Route path="/admin/edit-users/:id"><FormUsersPage></FormUsersPage></Route>
                  <Route path="/admin/add-users"><FormUsersPage></FormUsersPage></Route>
                  <Route path="/admin/upload-users"><UploadUsers></UploadUsers></Route>
                  <Route path="/admin/export-users"><XuatNhanSu /></Route>
                  <Route path="/admin/lay-mat-khau"><LayMatKhau /></Route>
                  <Route path="/admin/doi-mat-khau"><DoiMatKhau /></Route>

                  <Route path="/admin/customer"><DanhSachKhachHang /></Route>
                  <Route path="/admin/add-customer"><FormKhachHang /></Route>
                  <Route path="/admin/upload-customer"><UploadKhachHang /></Route>
                  <Route path="/admin/edit-customer/:id"><FormKhachHang /></Route>

                  <Route path="/admin/tongquan-laisuat/"><TongQuanLaiSuat /></Route>
                  <Route path="/admin/view-tongquanlaisuat/:id"><TongQuanSanPham /></Route>
                  <Route path="/admin/view-laisuatchitiet/:id"><ChiTietLaiSuat /></Route>

                  <Route path="/admin/san-pham"><DanhSachSanPham /></Route>
                  <Route path="/admin/add-san-pham"><FormSanPham /></Route>
                  <Route path="/admin/edit-san-pham/:id"><FormSanPham /></Route>
                  <Route path="/admin/product_meta"><DanhSachSLSPDaBan /></Route>
                  <Route path="/admin/add-product_meta"><FormSLSPDaBan /></Route>
                  <Route path="/admin/edit-product_meta/:id"><FormSLSPDaBan /></Route>
                  <Route path="/admin/approve-product_meta/:id"><DuyetSLSPDaBan /></Route>

                  <Route path="/admin/chiphi"><DanhSachChiPhiSanPham /></Route>
                  <Route path="/admin/add-chiphi"><FormChiPhi /></Route>
                  <Route path="/admin/edit-chiphi/:id"><FormChiPhi /></Route>

                  <Route path="/admin/banghang"><DanhSachSanPhamBangHang /></Route>
                  <Route path="/admin/view-banghang/:id"><DanhSachBangHang /></Route>
                  <Route path="/admin/edit-banghang/:id"><FormBangHang /></Route>
                  <Route path="/admin/upload-banghang"><UploadBangHang /></Route>

                  <Route path="/admin/du-an"><DanhSachDuAn /></Route>
                  <Route path="/admin/add-du-an"><FormDuAn /></Route>
                  <Route path="/admin/edit-du-an/:id"><FormDuAn /></Route>

                  <Route path="/admin/danh-muc-tin-tuc"><DanhMucTin /></Route>
                  <Route path="/admin/add-danh-muc-tin-tuc"><FormDanhMucTin /></Route>
                  <Route path="/admin/edit-danh-muc-tin-tuc/:id"><FormDanhMucTin /></Route>

                  <Route path="/admin/tin-tuc"><DanhSachTin /></Route>
                  <Route path="/admin/add-tin-tuc"><FormTin /></Route>
                  <Route path="/admin/edit-tin-tuc/:id"><FormTin /></Route>

                  <Route path="/admin/transaction"><DanhSachGiaoDich /></Route>
                  <Route path="/admin/edit-transaction/:id"><FormGiaoDich /></Route>

                  <Route path="/admin/email-body"><ListEmailBody /></Route>
                  <Route path="/admin/add-email-body"><FormEmailBody /></Route>
                  <Route path="/admin/edit-email-body/:id"><FormEmailBody /></Route>

                  <Route path="/admin/list-settings"><ListSettingsPage /></Route>
                  <Route path="/admin/add-settings"><FormSettings /></Route>
                  <Route path="/admin/edit-settings/:id"><FormSettings /></Route>

                  <Route path="/admin/test-email"><TestEmail /></Route>

                </> : <Loading color="secondary" expire={user.expires <= now} />}
              </>
              :
              <>
                <Redirect to="/admin/login" />
              </>
            }
          </Route>

          <Route path="/cac-khoan-dau-tu"><AccountInvestments /></Route>
          <Route path="/ca-nhan/cac-khoan-dau-tu/:id"><ChiTietDauTu /></Route>
          <Route path="/thong-tin-tong-quan"><AccountOverview /></Route>
          <Route path="/thong-tin-ca-nhan"><AccountProfile /></Route>
          <Route path="/lich-su-giao-dich"><AccountStatement /></Route>
          <Route path="/san-pham-dau-tu"><FrontendDuAn /></Route>
          <Route path="/gioi-thieu"><AboutUs /></Route>
          <Route path="/nap-rut-tien"><AccountFunding /></Route>
          <Route path="/tuyen-dung"><Careers /></Route>
          <Route path="/lien-he"><Contact /></Route>
          <Route path="/tin-tuc"><News /></Route>
          <Route path="/tin-tuc/:alias"><SinglePost /></Route>
          <Route path="/san-pham-dau-tu"><FrontendDuAn /></Route>
          <Route path="/san-pham/:alias"><SingleProject /></Route>
          <Route path="/statistics"><Statistics /></Route>
          <Route path="/tro-giup"><TroGiup /></Route>
          <Route path="/error-404"><Error404 /></Route>
          <Route path="/verify-email/:code"><VerifyEmail /></Route>
          <Route path="/dang-xuat"><DangXuat /></Route>
          <Route path="/dang-nhap"><DangNhap /></Route>
          <Route path="/dang-ky"><DangKy /></Route>
          <Route path="/"><TrangChu /></Route>

        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
