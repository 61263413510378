import React from "react";
import MainFrontend from "./template/Main";

export default function Contact() {

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Liên hệ</h2>
                        </div>
                    </div>
                    <img src={require('./tmp/sample-header.jpg')} alt="" />
                </div>
            </header>

            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <h3>Làm thế nào để chúng tôi giúp đỡ bạn?</h3>
                                <p>Được thành lập bởi BHS Group, Fmax sẽ phát triển rất nhiều các sản phẩm cho các nhà đầu tư lựa chọn phương án tối ưu nhất cho mình. 
                                   Quý nhà đầu tư hãy liên hệ ngay với chúng tôi để được tư vấn phương án đầu tư tốt nhất.</p>
                            </div>
                            <div className="spacing1"></div>
                            <div className="col-6 col-lg-3">
                                <div className="featured-box">
                                    <em className="featured-box-icon mdi mdi-face-agent"></em>
                                    <h5 className="featured-box-title">Live chat</h5>
                                    <p className="featured-box-content">Nhận trợ giúp từ nhân viên của chúng tôi</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="featured-box">
                                    <em className="featured-box-icon mdi mdi-email-outline"></em>
                                    <h5 className="featured-box-title">Email</h5>
                                    <p className="featured-box-content">contact@fmax.com.vn</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="featured-box">
                                    <em className="featured-box-icon mdi mdi-phone-outline"></em>
                                    <h5 className="featured-box-title">Gọi ngay</h5>
                                    <p className="featured-box-content">024 73098383</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="featured-box">
                                    <em className="featured-box-icon mdi mdi-newspaper-variant-outline"></em>
                                    <h5 className="featured-box-title">Đọc thêm về chúng tôi</h5>
                                    <p className="featured-box-content">Hãy theo dõi thông tin mới nhất về chúng tôi</p>
                                </div>
                            </div>
                            <div className="spacing1"></div>
                            <div className="col-12">
                                <h3>Văn phòng của chúng tôi</h3>
                            </div>
                            <div className="col-sm-6">
                                <div className="image-split-box">
                                    <img src={require('./tmp/tru-so.jpg')} alt="" />
                                    <div className="box-content">
                                        <h5>Trụ sở Hà Nội</h5>
                                        <p>Số 6 Lê Văn Thiêm, Thanh Xuân, Hà Nội <br />024 73098383 <br /><a href="mailto:contact@fmax.com.vn">contact@fmax.com.vn</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="site-section section-call-to-action has-media dotted-pattern dotted-pattern-top-right">
                    <div className="container">
                        <h2 className="entry-title">Kiếm thêm thu nhập trong khi đầu tư vào các công việc kinh doanh có ý nghĩa.</h2>
                        <p className="no-margin-bottom">
                            <a href="/san-pham-dau-tu" className="button button-primary"><em className="mdi mdi-briefcase-outline"></em> Đầu tư ngay</a>
                            <a href="/lien-he" className="button button-light button-outline"><em className="mdi mdi-email"></em> Liên hệ ngay</a>
                        </p>
                    </div>
                    <img  src={require('./tmp/sample-video.jpg')} alt="" />
                    {/* <!-- <video id="sample-video" src="tmp/sample-video.mp4" poster="tmp/sample-video.jpg" autoplay="" loop="" muted=""></video> --> */}
                </div>
            </div>
        </MainFrontend>
    );
}
