import React from "react";
import MainFrontend from "./template/Main";

export default function TroGiup() {

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Trợ giúp</h2>
                            <form action="help.html" className="searchform">
                                <label for="search-form-input" className="screen-reader-text">Search for:</label>
                                <input type="search" id="search-form-input" name="s" placeholder="Search&hellip;" />
                                    <button type="submit">
                                        <em className="mdi mdi-magnify"></em>
                                        <span className="screen-reader-text">Search</span>
                                    </button>
                            </form>
                        </div>
                    </div>
                    <img src={require('./tmp/sample-header.jpg')} alt="" />
                </div>
            </header>

            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <div className="row">
                            <div id="sidebar" className="col-12 col-md-4 col-lg-3">
                                <h4>Bắt đầu</h4>
                                <ul className="menu">
                                    <li className="current-menu-item"><a href="/#">Đăng ký như thế nào</a></li>
                                    <li><a href="/#">Đầu tư như thế nào</a></li>
                                    <li><a href="/#">Nhận tài trợ như thế nào</a></li>
                                    <li><a href="/#">FAQs</a></li>
                                </ul>
                                <h4>Nền tảng</h4>
                                <ul className="menu">
                                    <li><a href="/#">Terms &amp; conditions</a></li>
                                    <li><a href="/#">Cookie policy</a></li>
                                    <li><a href="/#">Privacy policy</a></li>
                                </ul>
                                <h4>Điều khoản</h4>
                                <ul className="menu">
                                    <li><a href="/#">Investor risk statement</a></li>
                                    <li><a href="/#">Buyback guarantee</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-8 col-lg-9">
                                <div className="breadcrumbs">
                                    <a href="index.html" className="home-url">Home</a>
                                    <span className="current">How to register</span>
                                </div>
                                <h3>How can I create an investor account?</h3>
                                <p>Our registration process is very simple – all you need to do is to fill in the registration form by providing your personal details and contact information. After that, simply become an active investor by adding funds to your investor account, as little as €30.</p>
                                <p>Keep in mind that you must be at least 18 years old, and that we only accept deposits from credit, payment or electronic money institutions within the European Union.</p>
                                <div className="helpfulness-rating">
                                    <div className="rating-form">
                                        <h5 className="helpfulness-title">Was this article helpful?</h5>
                                        <a href="/#" className="button button-small button-outline">Yes</a>
                                        <a href="/#" className="button button-small button-outline">No</a>
                                    </div>
                                    <div className="form-response">
                                        <h5 className="helpfulness-title">Thank you for your feedback!</h5>
                                        <p>We try our best.</p>
                                    </div>
                                </div>
                                <div className="related-articles">
                                    <h5>Related articles</h5>
                                    <ul className="menu">
                                        <li><a href="/#">Register as company</a></li>
                                        <li><a href="/#">What loyalty categories there are?</a></li>
                                        <li><a href="/#">How is the interest you will earn from your investments calculated?</a></li>
                                        <li><a href="/#">Current special offers</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="spacing2"></div>
                            <div className="col-12">
                                <h4>Không thể tìm thấy những gì bạn cần?</h4>
                            </div>
                            <div className="col-6 col-lg-3 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-face-agent"></em>
                                    <h5 className="featured-box-title">Live chat</h5>
                                    <p className="featured-box-content">Nhận trợ giúp từ nhân viên của chúng tôi</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-email-outline"></em>
                                    <h5 className="featured-box-title">Email</h5>
                                    <p className="featured-box-content">contact@fmax.com.vn</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-phone-outline"></em>
                                    <h5 className="featured-box-title">Gọi ngay</h5>
                                    <p className="featured-box-content">024 73098383</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 d-flex">
                                <div className="featured-box w-100">
                                    <em className="featured-box-icon mdi mdi-newspaper-variant-outline"></em>
                                    <h5 className="featured-box-title">Đọc thêm về chúng tôi</h5>
                                    <p className="featured-box-content">Hãy theo dõi thông tin mới nhất về chúng tôi</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="site-section section-call-to-action has-media dotted-pattern dotted-pattern-top-right">
                    <div className="container">
                        <h2 className="entry-title">Kiếm thêm thu nhập trong khi đầu tư vào các công việc kinh doanh có ý nghĩa.</h2>
                        <p className="no-margin-bottom">
                            <a href="/san-pham-dau-tu" className="button button-primary"><em className="mdi mdi-briefcase-outline"></em> Đầu tư ngay</a>
                            <a href="/lien-he" className="button button-light button-outline"><em className="mdi mdi-email"></em> Liên hệ ngay</a>
                        </p>
                    </div>
                    <img src={require('./tmp/sample-video.jpg')} alt="" />
                        {/* <!-- <video id="sample-video" src="tmp/sample-video.mp4" poster="tmp/sample-video.jpg" autoplay="" loop="" muted=""></video> --> */}
                </div>
            </div>
        </MainFrontend>
    );
}
