import React from "react";
import MainFrontend from "./template/Main";

export default function News() {

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Tin Tức</h2>
                            <p className="tagline">Truyền cảm hứng cho khách hàng bằng cách giúp họ kiểm soát tài chính của họ.</p>
                        </div>
                    </div>
                    <img src={require('./tmp/sample-header.jpg')} alt="" />
                </div>
            </header>

            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 col-lg-9">
                                <div className="news-listing">
                                    <div className="entry-post">
                                        <div className="categories">
                                            <span className="screen-reader-text">Danh mục: </span> <a href="news.html" rel="category tag">Tips &amp; Tricks</a>
                                        </div>
                                        <div className="entry-media entry-image">
                                            <a href="single-post.html"><img src={require('./tmp/sample-post.jpg')} alt="" /></a>
                                        </div>
                                        <header className="entry-header">
                                            <h5 className="entry-title"><a href="single-post.html" rel="bookmark">Startups disrupting the norms in 2022</a></h5>
                                        </header>
                                        <div className="entry-content">
                                            <p>Lotus root water spinach fennel kombu maize bamboo shoot green bean swiss chard onion chickpea gram corn pea coriander water chestnut…</p>
                                            <a href="single-post.html" className="read-more">Xem thêm <span className="mdi mdi-trending-neutral"></span></a>
                                        </div>
                                    </div>
                                    <div className="entry-post format-video">
                                        <div className="categories">
                                            <span className="screen-reader-text">Posted in: </span> <a href="news.html" rel="category tag">Customer Acquisition</a>
                                        </div>
                                        <div className="entry-media entry-image">
                                            <a href="single-post.html"><img src={require('./tmp/sample-post.jpg')} alt="" /></a>
                                        </div>
                                        <header className="entry-header">
                                            <h5 className="entry-title"><a href="single-post.html" rel="bookmark">Marketing strategies for a healthy ecosystem</a></h5>
                                        </header>
                                        <div className="entry-content">
                                            <p>Sea lettuce lettuce water chestnut eggplant winter purslane fennel azuki bean earthnut pea sierra leone bologi leek dicta sunt explicabo enim ipsam quia…</p>
                                            <a href="single-post.html" className="read-more">Xem thêm <span className="mdi mdi-trending-neutral"></span></a>
                                        </div>
                                    </div>
                                    <div className="entry-post format-status">
                                        <div className="categories">
                                            <span className="screen-reader-text">Posted in: </span> <a href="news.html" rel="category tag">Company News</a>
                                        </div>
                                        <div className="entry-media entry-image">
                                            <a href="single-post.html"><img src={require('./tmp/sample-post.jpg')} alt="" /></a>
                                        </div>
                                        <header className="entry-header">
                                            <h5 className="entry-title"><a href="single-post.html" rel="bookmark">CrowdInvest annual audit report</a></h5>
                                        </header>
                                        <div className="entry-content">
                                            <p>Gumbo beet greens corn soko endive gumbo gourd parsley shallot courgette tatsoi pea sprouts fava bean water chestnut eggplant winter purslane collard…</p>
                                            <a href="single-post.html" className="read-more">Xem thêm <span className="mdi mdi-trending-neutral"></span></a>
                                        </div>
                                    </div>
                                    <div className="entry-post format-audio">
                                        <div className="categories">
                                            <span className="screen-reader-text">Posted in: </span> <a href="news.html" rel="category tag">Strategy & Planning</a>
                                        </div>
                                        <div className="entry-media entry-image">
                                            <a href="single-post.html"><img src={require('./tmp/sample-post.jpg')} alt="" /></a>
                                        </div>
                                        <header className="entry-header">
                                            <h5 className="entry-title"><a href="single-post.html" rel="bookmark">Message from our CEO, J. Pigment</a></h5>
                                        </header>
                                        <div className="entry-content">
                                            <p>Lotus root water spinach fennel kombu maize bamboo shoot green bean swiss chard onion chickpea gram corn pea coriander water chestnut…</p>
                                            <a href="single-post.html" className="read-more">Xem thêm <span className="mdi mdi-trending-neutral"></span></a>
                                        </div>
                                    </div>
                                    <div className="entry-post format-image">
                                        <div className="categories">
                                            <span className="screen-reader-text">Posted in: </span> <a href="news.html" rel="category tag">Business Inteligence</a>
                                        </div>
                                        <div className="entry-media entry-image">
                                            <a href="single-post.html"><img src={require('./tmp/sample-post.jpg')} alt="" /></a>
                                        </div>
                                        <header className="entry-header">
                                            <h5 className="entry-title"><a href="single-post.html" rel="bookmark">Safe investments with our trusted partners</a></h5>
                                        </header>
                                        <div className="entry-content">
                                            <p>Lettuce water chestnut eggplant winter purslane fennel azuki bean earthnut pea sierra leone bologi leek dicta sunt explicabo enim bean water…</p>
                                            <a href="single-post.html" className="read-more">Xem thêm <span className="mdi mdi-trending-neutral"></span></a>
                                        </div>
                                    </div>
                                    <div className="entry-post format-gallery">
                                        <div className="categories">
                                            <span className="screen-reader-text">Posted in: </span> <a href="news.html" rel="category tag">Company News</a>
                                        </div>
                                        <div className="entry-media entry-image">
                                            <a href="single-post.html"><img src={require('./tmp/sample-post.jpg')} alt="" /></a>
                                        </div>
                                        <header className="entry-header">
                                            <h5 className="entry-title"><a href="single-post.html" rel="bookmark">Big update coming this following quarter</a></h5>
                                        </header>
                                        <div className="entry-content">
                                            <p>Sea lettuce lettuce water chestnut eggplant winter purslane fennel azuki bean earthnut pea sierra leone bologi leek dicta sunt explicabo enim ipsam quia…</p>
                                            <a href="single-post.html" className="read-more">Xem thêm <span className="mdi mdi-trending-neutral"></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="pagination">
                                    <span className="page-numbers current">1</span>
                                    <a href="news.html" className="page-numbers">2</a>
                                    <span className="page-numbers dots">…</span>
                                    <a href="news.html" className="page-numbers">5</a>
                                    <a href="news.html" className="page-numbers">Trang tiếp theo<span className="mdi mdi-chevron-right"></span></a>
                                </div>
                            </div>
                            <div id="sidebar" className="col-12 col-md-4 col-lg-3">
                                <div className="widget widget_search">
                                    <form action="help.html" className="searchform">
                                        <label for="search-form-input" className="screen-reader-text">Search for:</label>
                                        <input type="search" id="search-form-input" name="s" placeholder="Tìm kiếm&hellip;" />
                                        <button type="submit">
                                            <em className="mdi mdi-magnify"></em>
                                            <span className="screen-reader-text">Tìm kiếm</span>
                                        </button>
                                    </form>
                                </div>
                                <div className="widget widget_categories">
                                    <h5 className="widget-title">Danh mục</h5>
                                    <ul>
                                        <li className="cat-item"><a href="/#">Business Inteligence (5)</a></li>
                                        <li className="cat-item"><a href="/#">Customer Acquisition (3)</a></li>
                                        <li className="cat-item current-cat"><a href="/#">Tips &amp; Tricks (6)</a></li>
                                        <li className="cat-item"><a href="/#">Company News (9)</a></li>
                                        <li className="cat-item"><a href="/#">Strategy &amp; Planning (2)</a></li>
                                    </ul>
                                </div>
                                <div className="widget widget_media_gallery">
                                    <h5 className="widget-title">Thư viện ảnh</h5>
                                    <div className="gallery gallery-columns-2">
                                        <figure className="gallery-item">
                                            <div className="gallery-icon">
                                                <a href="/#"><img src={require('./tmp/sample-location.jpg')} alt="" /></a>
                                            </div>
                                        </figure><figure className="gallery-item">
                                            <div className="gallery-icon">
                                                <a href="/#"><img src={require('./tmp/sample-location.jpg')} alt="" /></a>
                                            </div>
                                        </figure><figure className="gallery-item">
                                            <div className="gallery-icon">
                                                <a href="/#"><img src={require('./tmp/sample-location.jpg')} alt="" /></a>
                                            </div>
                                        </figure><figure className="gallery-item">
                                            <div className="gallery-icon">
                                                <a href="/#"><img src={require('./tmp/sample-location.jpg')} alt="" /></a>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                                {/* <div className="widget widget_tag_cloud">
                                    <h5 className="widget-title">Từ khóa</h5>
                                    <div className="tagcloud">
                                        <a href="/abc" className="tag-cloud-link">life</a>
                                        <a href="abc" className="tag-cloud-link">power</a>
                                        <a href="abc" className="tag-cloud-link">update</a>
                                        <a href="abc" className="tag-cloud-link">seed</a>
                                        <a href="abc" className="tag-cloud-link">yes</a>
                                        <a href="abc" className="tag-cloud-link">zen</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </main>
                <div className="site-section section-call-to-action has-media dotted-pattern dotted-pattern-top-right">
                    <div className="container">
                        <h2 className="entry-title">Kiếm thêm thu nhập trong khi đầu tư vào các công việc kinh doanh có ý nghĩa.</h2>
                        <p className="no-margin-bottom">
                            <a href="/san-pham-dau-tu" className="button button-primary"><em className="mdi mdi-briefcase-outline"></em> Đầu tư ngay</a>
                            <a href="/lien-he" className="button button-light button-outline"><em className="mdi mdi-email"></em> Liên hệ ngay</a>
                        </p>
                    </div>
                    <img src={require('./tmp/sample-video.jpg')} alt="" />
                    {/* <!-- <video id="sample-video" src="tmp/sample-video.mp4" poster="tmp/sample-video.jpg" autoplay="" loop="" muted=""></video> --> */}
                </div>
            </div>
        </MainFrontend>
    );
}
