import React from "react";
import MainFrontend from "./template/Main";

export default function Error404() {

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h1 className="entry-title display"><span>Error</span> 404</h1>
                            <p className="tagline">Không tìm thấy trang yêu cầu.</p>
                        </div>
                    </div>
                    <img src={require('./tmp/sample-header.jpg')} alt="" />
                </div>
            </header>
        </MainFrontend>
    );
}
