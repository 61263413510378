import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import MyEditor2 from "../component/MyEditor2";

export default function FormDuAn() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);

    const defaultdetail = {
        author_id: user.data.id,
        title:'',
        alias:'',
        code:'',
        type:1,
        description: '',
        body_content: '',
        price: '',
        interest_rate: '',
        status: 1
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "duan/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            // console.log(res.data.data)
                            if (mounted) {
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            } 
        }, [user, id, autoRefresh]
    );
    let validForm = {
        title: Yup.string().trim().nullable().required('Nhập tên dự án').max(250, "Độ dài không quá 250 ký tự"),
        code: Yup.string().trim().nullable().required('Nhập mã dự án')
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            delete values["id"];

            setLoading(true)
            console.log(values);
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'duan/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        setAutoRefresh(!autoRefresh)
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật dự án thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'duan', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới dự án thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="san-pham">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/admin/san-pham">Danh sách dự án</Link>
                    </li>
                    <li>{id ? 'Sửa dự án' : 'Thêm mới dự án'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} Dự án</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tên dự án <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>

                            {/* <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Mã dự án</label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("code")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="code"></AlertErrorForm>
                            </div> */}
                            
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Địa chỉ</label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("address")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="address"></AlertErrorForm>
                            </div>
                            
                            <div className="col-12 form-group">
                                <label>Mô tả ngắn</label>
                                <textarea className="form-control" {...formik.getFieldProps("description")}></textarea>
                            </div>
                            <div className="col-12 form-group">
                                <label>Mô tả chi tiết dự án</label>
                                <MyEditor2 height={350} key={Math.random()} initialValue={formik.values.content}
                                    onValue={(value) => value && formik.setFieldValue("body_content", value)}
                                />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trạng thái: </label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>
                                    <option value="1" >Đang hoạt động</option>
                                    <option value="0" >Ngưng hoạt động</option>
                                </select>
                            </div>

                            <div className="col-12 mt-3" ></div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu dự án</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
