import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AlertErrorForm from "../adminpage/component/AlertErrorForm";
import Spinner from "../adminpage/component/Spinner";
import { convertPrice, countdate, countInterest } from "../adminpage/model";
import { URL_LIST_ALL } from "../configs/api";
import MainFrontend from "./template/Main";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

export default function SingleProject() {

    const { user } = useSelector((state) => state.auth);

    const { alias } = useParams();
    const [detail, setDetail] = useState(null);
    const [gallery, setGallery] = useState([]);
    const [showInvest, setShowInvest] = useState(false)
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState(null)
    const [investSuccess, setInvestSuccess] = useState(false)
    const [isNoProduct, setIsNoProduct] = useState(false)
    const [infoSuccess, setInfoSuccess] = useState(null)
    const [modalPayment, setModalPayment] = useState(false)
    const [paymentSetting, setPaymentSetting] = useState({})

    useEffect(() => {
        if (user && user.data.typeaccount === 'customer') {
            axios.get(URL_LIST_ALL + "profile/" + user.data.id, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setProfile(res.data.data)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));

            axios.get(URL_LIST_ALL + "settings?alias=payment", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    let data = res.data.data
                    let newData = {}
                    data.forEach((item) => {
                        newData[item.alias] = item.value
                    });
                    setPaymentSetting(newData)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }, [user])

    const actionInvest = () => {
        if (user && user.data.typeaccount === 'customer') {
            setShowInvest(true)
        } else {
            confirmAlert({
                title: 'Thông báo',
                message: "Bạn cần đăng nhập để sử dụng chức năng này",
                buttons: [
                    { label: 'Đồng ý', onClick: () => { } }
                ]
            });
        }
    }

    useEffect(
        function () {
            if (alias) {
                let mounted = true;
                axios.get(URL_LIST_ALL + "sanpham?alias=" + alias)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            // console.log(res.data.data)
                            if (mounted) {
                                if (res.data.data && res.data.data[0]) {
                                    let dataif = res.data.data[0]
                                    setDetail(dataif);
                                    setGallery(JSON.parse(dataif.gallery))
                                } else {
                                    setIsNoProduct(true)
                                }
                            }
                        } else { console.log(res.data.message); setIsNoProduct(true) }
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [alias]
    );

    const formikInvest = useFormik({
        initialValues: {
            cus_id: profile && profile.id,
            cus_name: profile && profile.fullname,
            cus_email: profile && profile.email,
            cus_phone: profile && profile.mobile,
            product_id: detail && detail.id,
            product_code: detail && detail.code,
            price: detail && detail.price,
            number: "",
            total_price: 0,
            status: "Đã tạo giao dịch"
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            number: Yup.number().nullable().required("Vui lòng nhập số lượng đầu tư").integer().max(detail && detail.soluong, 'Số lượng đầu tư vượt giới hạn'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (user) {
                setLoading(true);
                values.total_price = values.number * values.price
                axios.post(URL_LIST_ALL + 'transaction', values, user.config).then(res => {
                    if (res.data.status === 'success') {
                        setShowInvest(false)
                        setInvestSuccess(true)
                        setLoading(false);
                        setInfoSuccess(res.data)
                    } else {
                        setLoading(false);
                        confirmAlert({
                            title: 'Thông báo',
                            message: res.data.message,
                            buttons: [
                                { label: 'Đồng ý', onClick: () => { } }
                            ]
                        });
                    }
                }).catch(err => { console.log(err); setLoading(false); });
            } else {
                confirmAlert({
                    title: 'Thông báo',
                    message: "Bạn cần đăng nhập để sử dụng chức năng này",
                    buttons: [
                        { label: 'Đồng ý', onClick: () => { } }
                    ]
                });
            }
        },
    });

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    const XacNhan = (status) => {
        // setInvestSuccess(false)
        if (infoSuccess) {
            axios.put(URL_LIST_ALL + 'transaction/' + infoSuccess.id, { status: status }, user.config).then(res => {
                if (res.data.status === 'success') {
                    if (status === "Đã xác nhận") {
                        setInvestSuccess(false)
                        setModalPayment(true)
                    } else {

                    }
                } else {
                    setLoading(false);
                    confirmAlert({
                        title: 'Thông báo',
                        message: res.data.message,
                        buttons: [
                            { label: 'Đồng ý', onClick: () => { } }
                        ]
                    });
                }
            }).catch(err => { console.log(err); setLoading(false); });
        }
    }

    return (
        <MainFrontend>
            {detail ?
                <>
                    <header id="featured" className="site-featured">
                        <div id="featured-media" className="featured-media has-media">
                            <div className="featured-text">
                                <div className="container">
                                    <h2 className="entry-title">{detail.title}</h2>
                                    {/* <div className="tagline">Funds for increasing the efficency class rating</div> */}
                                </div>
                            </div>
                            <img src={detail.thumbnail ? detail.thumbnail : require('./tmp/sample-header.jpg')} alt="" />
                        </div>
                    </header>

                    <div id="content" className="site-content">
                        <main className="site-section section-main">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h3>Mô tả sản phẩm</h3>
                                        {renderHTML(detail.body_content)}
                                    </div>
                                    <div className="col-6 col-lg-3">
                                        <div className="featured-box">
                                            <p className="featured-box-content">Số tiền đầu tư tối thiểu</p>
                                            <h5 className="featured-box-title">{convertPrice(detail.price)}</h5>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-3">
                                        <div className="featured-box">
                                            <p className="featured-box-content">Lợi nhuận mong đợi</p>
                                            <h5 className="featured-box-title">{countInterest(detail)}</h5>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-3">
                                        <div className="featured-box">
                                            <p className="featured-box-content">Thời gian đầu tư</p>
                                            <h5 className="featured-box-title">{detail.time_invest} tháng</h5>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-3">
                                        <div className="featured-box">
                                            <p className="featured-box-content">Thời hạn đăng ký còn</p>
                                            <h5 className="featured-box-title">
                                                {detail.status === 1 ?
                                                <>
                                                    Hết hạn đầu tư
                                                </>
                                                :
                                                <>{detail.dateend && countdate(detail.dateend)} ngày</>    
                                            }</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mt-4">
                                    <div className="col-12 col-lg-6">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-6">
                                                <div className="project-progress-chart">
                                                    <svg viewBox="0 0 36 36">
                                                        <path className="circle-total" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                        <path className="circle-current" strokeDasharray={detail.interest_rate + ", 100"} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                    </svg>

                                                    <div className="percentage-text">
                                                        <strong>Lãi dự kiến </strong><br />
                                                        {countInterest(detail)}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="data-box">
                                            <div className="data-property">Loại hình đầu tư</div>
                                            <div className="data-value">{detail.type_interest}</div>
                                            <div className="data-property">Ngày phát hành</div>
                                            <div className="data-value"><Moment format="DD/MM/YYYY">{detail.datestart}</Moment></div>
                                            <div className="data-property">Ngày đến hạn</div>
                                            <div className="data-value"><Moment format="DD/MM/YYYY">{detail.dateend}</Moment></div>
                                            <div className="data-property">Ngày tất toán</div>
                                            <div className="data-value"><Moment format="DD/MM/YYYY">{detail.datesettlement}</Moment></div>
                                            <div className="data-property">Số tiền đầu tư</div>
                                            <div className="data-value">Từ {convertPrice(detail.price)} tới {convertPrice((detail.price) * detail.soluong)}</div>
                                            {detail.type_interest === 'Cam kết lãi suất tối thiểu' ?
                                                <>
                                                    <div className="data-property">Lãi tối thiểu</div>
                                                    <div className="data-value">{detail.interest_rate}%</div>
                                                    {detail.ls_thuong && detail.ls_thuong !== 0 &&
                                                        <>
                                                            <div className="data-property">Lãi thưởng</div>
                                                            <div className="data-value">{detail.ls_thuong}%</div>
                                                            <div className="data-property">Lãi dự kiến</div>
                                                            <div className="data-value">{countInterest(detail)}</div>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="data-property">Lãi dự kiến</div>
                                                    <div className="data-value">{countInterest(detail)}</div>
                                                </>
                                            }


                                            <div className="data-property">Thời gian đầu tư</div>
                                            <div className="data-value">{detail.time_invest} tháng</div>
                                            <div className="data-property"></div>
                                            {detail.status === 2 &&
                                                <div className="w-100 d-flex justify-content-end">
                                                    <button type="button" className="w-100 btn-success" onClick={() => actionInvest()}>Đầu tư ngay</button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h3>Thư viện hình ảnh</h3>

                                        <div className="gallery gallery-columns-3">
                                            {gallery && gallery.map(function (item, i) {
                                                return (
                                                    <figure className="gallery-item" key={i}>
                                                        <div className="gallery-icon">
                                                            <a href={item} data-fancybox="test-gallery"><img src={item} alt="" /></a>
                                                        </div>
                                                    </figure>
                                                )
                                            })}
                                        </div>
                                        {/*-------------------- Action đầu tư -----------------*/}
                                        {/* <div className="project-submit-investment">
                                            <h3>Bắt đầu đầu tư</h3>
                                            <p className="text-faded"><span className="mdi mdi-check-bold"></span> Buyback guarantee</p>
                                            <p>Số dư khả dụng của bạn <strong>€215.50</strong></p>
                                            <form action="single-project.html">
                                                <label className="sr-only">Amount:</label>
                                                <input type="number" id="amount" name="amount" placeholder="Enter the amount" />
                                                <label><input type="checkbox" id="confirm" name="confirm" /> Tôi xác nhận rằng tôi đã đọc và đồng ý với  <a href="/#">Thỏa thuận đầu tư</a></label>
                                                <button type="submit">Đầu tư</button>
                                                <div className="clear"></div>
                                            </form>
                                        </div> */}
                                        {/*-------------------- Action đầu tư -----------------*/}
                                        {/* <h3>Lịch sử đầu tư</h3> */}

                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <Modal show={showInvest} onHide={() => setShowInvest(false)} animation={true} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title className='w-100 text-center'>Đầu tư vào {detail.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="body-image-box">
                            <form className="w-100" method="post" onSubmit={formikInvest.handleSubmit}>
                                <div className='col-12'>

                                    <div className="col-12 form-group mt-3">
                                        <label>
                                            {detail.type_interest === 'Lãi suất cố định' ?
                                                <>Lãi suất dự kiến: {detail.interest_rate}% </>
                                                :
                                                <>{detail.type_interest}: {detail.interest_rate}</>
                                            }
                                        </label>
                                    </div>
                                    <div className="col-12 form-group mt-3">
                                        <label>Thời gian đầu tư: {detail.time_invest} tháng</label>
                                    </div>
                                    <div className="col-12 form-group mt-3">
                                        <label>Đơn giá: <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={formikInvest.values['price']} /></label>
                                    </div>
                                    <div className="col-12 form-group mt-3">
                                        <label>Số lượng đầu tư (Tối đa {detail.soluong - detail.number_invested})</label>
                                        <input type="number" min="0" max={detail.soluong - detail.number_invested} placeholder="Nhập số lượng" className="form-control height32" {...formikInvest.getFieldProps("number")} />
                                        <AlertErrorForm formik={formikInvest} name="number"></AlertErrorForm>
                                    </div>

                                    {formikInvest.values['number'] && formikInvest.values['number'] > 0 &&
                                        <div className="col-12 form-group mt-3">
                                            <label>Tổng số tiền đầu tư: </label>
                                            <strong> <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={formikInvest.values['number'] * formikInvest.values['price']} className="height32" /></strong>
                                        </div>
                                    }
                                    <div className='col-12 d-flex justify-content-end'>
                                        <button type="submit" className="w-100 btn-sm btn-success text-white">{loading ? <Spinner /> : <>Đầu tư</>}</button>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    {infoSuccess &&
                        <>
                            <Modal show={investSuccess} onHide={() => setInvestSuccess(false)} animation={true} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title className='w-100 text-center'>Bạn xác nhận đầu tư <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={infoSuccess.data.total_price} /> cho sản phẩm:</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="body-modal">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Hợp tác đầu tư dự án:</td>
                                                <td><strong>{detail.title}</strong></td>
                                            </tr>
                                            <tr>
                                                {detail.type_interest === 'Lãi suất cố định' ?
                                                    <><td>Lãi suất dự kiến:</td><td><strong>{detail.interest_rate} %</strong></td>  </>
                                                    :
                                                    <><td>{detail.type_interest}:</td><td><strong>{detail.interest_rate}</strong></td> </>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Thời gian đầu tư:</td>
                                                <td><strong>{infoSuccess.data.time_invest} tháng</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className='col-12 d-flex justify-content-between'>
                                        <button type="button" className="btn btn-sm btn-warning text-white" onClick={() => { XacNhan("Không xác nhận") }}>Không xác nhận</button>
                                        <button type="button" className="btn btn-sm btn-success text-white" onClick={() => { XacNhan("Đã xác nhận") }}>Xác nhận</button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                            {/* modal thanh toán */}
                            <Modal show={modalPayment} onHide={() => setModalPayment(false)} animation={true} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title className='w-100 text-center'>Chúc mừng bạn đã đầu tư thành công <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} suffix=" vnđ" value={infoSuccess.data.total_price} /> cho sản phẩm: {detail.title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="body-modal">
                                    <h5>Chọn hình thức thanh toán:</h5>

                                    <div className="app-plan-container">
                                        <div className="w-100 d-block d-sm-flex justify-content-center">
                                            {paymentSetting && paymentSetting.payment_vnpay && paymentSetting.payment_vnpay === 'on' &&
                                                <div className="app-plan-payment-item app-plan-item">
                                                    <img src="https://rsstv.gviet.vn/on/payment/icon_vnpay_small.png" alt="payment-img" className="app-plan-payment-img" />
                                                    <div className="app-plan-payment-title">
                                                        <h3 className="app-plan-payment-name">VNPAY</h3>
                                                        {/* <h5>GIẢM 30%</h5> */}
                                                    </div>
                                                </div>
                                            }

                                            {paymentSetting && paymentSetting.payment_shopee && paymentSetting.payment_shopee === 'on' &&
                                                <div className="app-plan-payment-item app-plan-item">
                                                    <img src="https://assets-vtvcab.gviet.vn/images/hq/posters/logoshopee147x1471.jpg" alt="payment-img" className="app-plan-payment-img" />
                                                    <div className="app-plan-payment-title">
                                                        <h3 className="app-plan-payment-name">ShopeePay</h3>
                                                        {/* <h5>GIẢM 30K</h5> */}
                                                    </div>
                                                    {/* <div className="app-plan-payment-auto-renew">Gia hạn tự động</div> */}
                                                </div>
                                            }
                                            {paymentSetting && paymentSetting.payment_momo && paymentSetting.payment_momo === 'on' &&
                                                <div className="app-plan-payment-item app-plan-item">
                                                    <img src="https://rsstv.gviet.vn/on/payment/icon_momo_small.png" alt="payment-img" className="app-plan-payment-img" />
                                                    <div className="app-plan-payment-title">
                                                        <h3 className="app-plan-payment-name">MoMo</h3>
                                                    </div>
                                                    {/* <div className="app-plan-payment-auto-renew">Gia hạn tự động</div> */}
                                                </div>
                                            }
                                        </div>
                                        <div className="w-100 d-block d-sm-flex justify-content-center">
                                            {paymentSetting && paymentSetting.payment_zalo && paymentSetting.payment_zalo === 'on' &&
                                                <div className="app-plan-payment-item app-plan-item">
                                                    <img src="https://rsstv.gviet.vn/on/payment/icon_zalopay_small.png" alt="payment-img" className="app-plan-payment-img" />
                                                    <div className="app-plan-payment-title">
                                                        <h3 className="app-plan-payment-name">ZaloPay</h3>
                                                        {/* <h5>HOÀN 10K</h5> */}
                                                    </div>
                                                    {/* <div className="app-plan-payment-auto-renew">Gia hạn tự động</div> */}
                                                </div>
                                            }
                                            {paymentSetting && paymentSetting.payment_9pay && paymentSetting.payment_9pay === 'on' &&
                                                <a className="app-plan-payment-item app-plan-item-active app-plan-item" target="_blank" href={"https://pay.fmax.com.vn/9Pay/" + infoSuccess.data.code}>
                                                    <img src="https://rsstv.gviet.vn/on/payment/v3/icon_ninepay_small.png" alt="payment-img" className="app-plan-payment-img" />
                                                    <div className="app-plan-payment-title">
                                                        <h3 className="app-plan-payment-name">Chuyển Khoản</h3>
                                                    </div>
                                                </a>
                                            }
                                            {paymentSetting && paymentSetting.payment_atm && paymentSetting.payment_atm === 'on' &&
                                                <div className="app-plan-payment-item app-plan-item"><img src="https://rsstv.gviet.vn/on/payment/v3/icon_atm_small.png" alt="payment-img" className="app-plan-payment-img" />
                                                    <div className="app-plan-payment-title">
                                                        <h3 className="app-plan-payment-name">Thẻ ATM</h3>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                    }


                </>
                :
                <>
                    {isNoProduct ? <h3>Không tìm thấy trang</h3> :
                        <div className="w-100 d-flex justify-content-center mt-3 mb-3 ">
                            <Spinner />
                        </div>
                    }
                </>
            }

        </MainFrontend>
    );
}
