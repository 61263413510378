import Axios from "axios";
import React from "react";
import { actions } from "../../redux/authRedux";
import { useDispatch, useSelector } from "react-redux";
import { URL_LIST_ALL } from "../../configs/api";

export default function FastLogin(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const login = () => {
      const stt = window.confirm('Bạn có chắc muốn đăng nhập tài khoản này')
      if(stt){
        Axios.post(URL_LIST_ALL+"fastlogin", { id }, user.config)
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            let user = res.data;
            user.config={
              headers: {
                'Authorization': 'Bearer ' + res.data.access_token,
                'Content-Type' : 'application/json; charset=UTF-8',
                'search':''
              }
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token
            dispatch(actions.login(user));

          }else alert(res.data.message)
        })
        .catch((err) => console.log(err));
      }
  }
  

  return (
    <button className="btn btn-success btn-sm" onClick={login}>
      Đăng nhập
    </button>
  );
}
