import React from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { useFormik } from "formik";
import Spinner from "../component/Spinner";
import { URL_LIST_ALL } from "../../configs/api";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import SearchBoxModal from "../component/SearchBoxModal";
import AlertErrorForm from "../component/AlertErrorForm";
import * as Yup from "yup";

export default function XuatNhanSu(props) {
    const { user } = useSelector((state) => state.auth);
    
    const formik = useFormik({
        initialValues: {month: new Date().getMonth, year: new Date().getFullYear},
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
           
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            let _url = URL_LIST_ALL + 'users?export=true&limit=10000000'
            if (values && values.part_id) _url = _url + '&part_id=' + values.part_id
            if (values && values.company_id) _url = _url + '&company_id=' + values.company_id
            Axios.get(_url, user.config).then(async (res) => {
                console.log(res)
                setSubmitting(false);
                if (res.data.status === "success") {
                    resetForm();
                    window.open(res.data.data, "_blank")
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch((err) => console.log(err));
        },
    });

   

    return (
        <AkkhorLayout idrole="list-users" >
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-users">Danh sách nhân sự</Link>
                    </li>
                    <li>Xuất danh sách nhân sự</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Xuất danh sách nhân sự</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 mg-t-8">
                                <div className="col-12 col-sm-4 form-group">
                                    <label>Xuất theo Công ty</label>
                                    <SearchBoxModal apiname={"company"} placeholder="Chọn công ty" all={true} colvalue={'id'} colname={'title'} formik={formik} name='company_id'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('company_id', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="company_id"></AlertErrorForm>
                                </div>
                                <div className="col-12"></div>
                                <div className="col-12 col-sm-4 form-group">
                                    <label>Xuất theo Phòng ban</label>
                                    <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" all={true} colvalue={'id'} colname={'title'} formik={formik} name='part_id'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('part_id', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="part_id"></AlertErrorForm>
                                </div>
                                <div className="col-12"></div>

                                <div className="alert alert-primary" role="alert">
                                    <div className="mt-3">
                                        <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mr-2">{formik.isSubmitting ? <Spinner /> : <>Xuất File</>}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
