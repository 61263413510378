import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mainLogo from '../../assets/img/logo-fmax.png';
import { Dropdown, Modal } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from "yup";
import AlertErrorForm from '../../adminpage/component/AlertErrorForm';
import Spinner from '../../adminpage/component/Spinner';
import { URL_LIST_ALL } from '../../configs/api';
import axios from 'axios';
import { actions } from '../../redux/authRedux';
import { confirmAlert } from 'react-confirm-alert';
import ReCAPTCHA from "react-google-recaptcha";

export default function MainFrontend(props) {
    const { user } = useSelector((state) => state.auth);
    let { children } = props;
    const [showLogin, setShowLogin] = useState(false)
    const [showRegist, setShowRegist] = useState(false)
    const [loading, setLoading] = useState(false)
    const [captcha, setCaptcha] = useState(false)
    const [listNotification, setListNotification] = useState([])
    const [notifiNoSeen, setNotifiNoseen] = useState(0)
    const [showDropdown, setShowDropdown] = useState(false)

    const dispatch = useDispatch();

    const createConfig = (_token) => {
        return {
            headers: {
                'Authorization': 'Bearer ' + _token,
                'Content-Type': 'application/json; charset=UTF-8',
                'search': ''
            }
        }
    }

    let validLogin = {
        username: Yup.string().trim().nullable().required("Vui lòng nhập email hoặc số điện thoại"),
        password: Yup.string().trim().nullable().min(6, "Mật khẩu có độ dài tối thiểu 6 ký tự").max(150, "Mật khẩu có độ dài tối đa 150 ký tự").required("Vui lòng nhập mật khẩu"),
    }
    let validRegist = {
        fullname: Yup.string().trim().nullable().required("Vui lòng nhập họ tên"),
        email: Yup.string().trim().nullable().required("Vui lòng nhập email").email("Địa chỉ email không chính xác"),
        birthday: Yup.string().trim().nullable().required("Vui lòng nhập ngày sinh"),
        mobile: Yup.string().trim().nullable().required("Vui lòng nhập số điện thoại"),
        password: Yup.string().trim().nullable().min(6, "Mật khẩu có độ dài tối thiểu 6 ký tự").max(150, "Mật khẩu có độ dài tối đa 150 ký tự").required("Vui lòng nhập mật khẩu"),
        repassword: Yup.string().trim().nullable().required("Vui lòng nhập lại mật khẩu").oneOf([Yup.ref('password'), null], 'Nhập lại mật khẩu không đúng')
    }

    const formikLogin = useFormik({
        initialValues: { username: "", password: "" },
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validLogin),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            axios.post(URL_LIST_ALL + 'customer-login', values).then(res => {
                setLoading(false);
                setSubmitting(false);
                if (res.data.status === "success") {
                    let user = res.data;
                    user.config = createConfig(res.data.access_token);
                    dispatch(actions.login(user));
                    confirmAlert({
                        title: 'Thành công',
                        message: "Chúc mừng bạn đã đăng nhập thành công",
                        buttons: [{ label: 'Đồng ý', onClick: () => { setShowLogin(false) } }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'Đồng ý' }]
                    });
                }
            }).catch(err => console.log(err));
        },
    });
    const formikRegist = useFormik({
        initialValues: { fullname: '', email: "", mobile: '', password: '', repassword: '', sex: '' },
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validRegist),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (captcha) {
                setLoading(true);
                axios.post(URL_LIST_ALL + 'customer-regist', values).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    if (res.data.status === "success") {
                        confirmAlert({
                            title: 'Thông báo',
                            message: "Đăng ký thành công",
                            buttons: [{ label: 'Đồng ý', onClick: () => { setShowRegist(false) } }]
                        });
                        resetForm()
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'Đồng ý' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                confirmAlert({
                    title: 'Lỗi',
                    message: "Vui lòng nhập Captcha",
                    buttons: [{ label: 'Đồng ý' }]
                });
            }

        },
    });

    useEffect(() => {
        if (user && user.data.typeaccount === 'customer') {
            let timenow = parseInt(new Date().getTime() / 1000) + 1 * 60 * 60;
            if (user.expires < timenow) {
                axios.get(URL_LIST_ALL + "refresh", { headers: { 'Authorization': 'Bearer ' + user.refresh_token } }).then((res) => {
                    if (res.data.status === 'success') {
                        let user = res.data;
                        user.config = { headers: { 'Authorization': 'Bearer ' + res.data.access_token } }
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token
                        dispatch(actions.login(user));
                    } else {
                        dispatch(actions.login(null));
                    }
                })
            }
        }
    }, [user])

    const onCapchaChange = (value) => {
        setCaptcha(value);
    }


    const loadNotification = () => {
        if (user && user.data.typeaccount === 'customer') {
            axios.get(URL_LIST_ALL + "notifications", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setListNotification(res.data.data)
                    setNotifiNoseen(res.data.counter)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }

    const showDropdownbtn = (e) => {
        if (!showDropdown) {
            loadNotification()
            setShowDropdown(!showDropdown)
        } else {
            setShowDropdown(!showDropdown)
        }
    }

    const handleSeen = (id) => {
        if (user && user.data.typeaccount === 'customer') {
            axios.put(URL_LIST_ALL + "notifications/" + id, { seen: 1 }, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    loadNotification()
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }

    const seenAllHandle = () => {
        if (user && user.data.typeaccount === 'customer') {
            axios.post(URL_LIST_ALL + "checknallnotifi", { seen: 1 }, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    loadNotification()
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }

    useEffect(() => {
        if (user && user.data.typeaccount === 'customer') {
            axios.get(URL_LIST_ALL + "countnotifi", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setNotifiNoseen(res.data.data)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
            loadNotification()
        }
    }, [user])

    return (<>
        <div id="wrapper">
            <input type="hidden" id='dataIdUser' value={user && user.data ? user.data.id : 0} />
            <header id="top-header" className="site-header">
                <div className="container">
                    <div className="site-identity">
                        <a href="/" className="site-title"><img src={mainLogo} className="custom-logo" width="209" height="18" alt="CrowdInvest" /></a>
                        <p className="site-description screen-reader-text">Business crowdfunding platform</p>
                    </div>
                    <nav className="language-navigation">
                        {/* <ul id="language-menu" className="menu">
                            <li className="menu-item-has-children">
                                <a href="index.html"><img src={require('../assets/images/en.svg').default} width="17" alt="" /><span>En<em className="mdi mdi-chevron-down"></em></span></a>
                                <ul className="sub-menu">
                                    <li className="current-menu-item"><a href="/">English</a></li>
                                    <li><a href="/">Tiếng Việt</a></li>
                                </ul>
                            </li>
                        </ul> */}
                    </nav>
                    <nav className="main-navigation">
                        <ul id="menu-main" className="menu">

                            <li><a href="/san-pham-dau-tu">Cơ hội đầu tư</a></li>
                            <li className="menu-item-has-children">
                                <a href="/gioi-thieu">Giới thiệu<em className="mdi mdi-chevron-down"></em></a>
                                <ul className="sub-menu">
                                    <li><a href="/gioi-thieu">Về chúng tôi</a></li>
                                    <li><a href="/tuyen-dung">Tuyển dụng</a></li>
                                </ul>
                            </li>
                            <li><a href="/tin-tuc">Tin tức</a></li>

                            <li className="menu-item-has-children">
                                <a href="/tro-giup">Trợ giúp<em className="mdi mdi-chevron-down"></em></a>
                                <ul className="sub-menu">
                                    <li><a href="/tro-giup">Trợ giúp &amp; hỗ trợ</a></li>
                                    <li><a href="/lien-he">Liên hệ</a></li>
                                </ul>
                            </li>

                            <li>
                                <div className='dropdown-notification'>
                                    <button className='btn-notification' onClick={(e) => { showDropdownbtn() }}>
                                        <i className='fas fa-bell'></i>
                                        <span className="badge badge-danger">{notifiNoSeen}</span>
                                    </button>
                                    {showDropdown && <>
                                        <div className='outner-dropdown'>
                                            <div className='dropdown-menu show'>
                                                {listNotification && listNotification.length > 0 ?
                                                    <>
                                                        <div className='w-100 d-flex justify-content-end'>
                                                            <span type='button' className='btn-seen-all' onClick={() => seenAllHandle()}>Đánh tất cả đã đọc</span>
                                                        </div>
                                                        {listNotification.map((itemNoti, i) => {
                                                            return (<div className={itemNoti.seen && itemNoti.seen === 1 ? 'outner-notifi seen' : 'outner-notifi'} key={i}><a className='dropdown-item' href={itemNoti.node_url}>
                                                                <p className='w-100 mb-0 notifi-title'><strong>{itemNoti.node_type}</strong></p>
                                                                <p className='w-100 mb-0 notifi-message'>{itemNoti.message}</p>
                                                            </a>{(!itemNoti.seen || itemNoti.seen === 0) && <button className={'btn-read'} type='button' data-id={itemNoti.id} data-bs-toggle="tooltip" data-bs-placement="right" title="Đã xem" onClick={(e) => handleSeen(itemNoti.id)}></button>}</div>)
                                                        })}
                                                    </>
                                                    :
                                                    <span className='dropdown-item no-notifi col-12'>Không có thông báo nào</span>
                                                }
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </li>

                            {user && user.data.typeaccount && user.data.typeaccount === 'customer' ?
                                <li className="menu-account menu-item-has-children">
                                    <a href="/thong-tin-tong-quan">
                                        <em className="mdi mdi-account-outline"></em>
                                        <span className="account-details">
                                            <span className="account-name">{user.data.fullname}</span>
                                            {/* <span className="account-balance">
                                                <em className="screen-reader-text">Total balance: </em>
                                                <strong className="account-balance-amount">€215.50</strong>
                                            </span> */}
                                        </span>
                                        <em className="mdi mdi-chevron-down"></em>
                                    </a>
                                    <ul className="sub-menu">
                                        <li><a href="/thong-tin-tong-quan">Tổng quan</a></li>
                                        <li><a href="/cac-khoan-dau-tu">Các khoản đã đầu tư</a></li>
                                        <li><a href="/lich-su-giao-dich">Lịch sử giao dịch</a></li>
                                        <li><a href="/nap-rut-tien">Nạp và rút tiền</a></li>
                                        <li><a href="/thong-tin-ca-nhan">Thông tin cá nhân</a></li>
                                        <li className="account-logout"><a href="/dang-xuat">Đăng xuất</a></li>
                                    </ul>
                                </li>
                                :
                                <>
                                    <li><button className='btn btn-sm btn-auth nohover' onClick={() => setShowLogin(true)}><i className='fas fa-user-alt mr-1'></i>Đăng nhập</button></li>
                                    <li><button className='btn btn-sm btn-auth nohover' onClick={() => setShowRegist(true)}><i className='fas fa-pencil-alt mr-1'></i>Đăng ký</button></li>
                                </>
                            }
                        </ul>
                    </nav>
                    <div id="menu-toggle">
                        <a href="#menu-main" title="Toggle menu">
                            <em className="mdi mdi-menu"></em><em className="mdi mdi-close"></em>
                            <span className="screen-reader-text">Menu</span>
                        </a>
                    </div>
                </div>
            </header>

            <div>
                <>{children}</>
            </div>

            <footer className="site-footer">
                <a className="to-the-top" href="/abc" title="To the top"><em className="mdi mdi-chevron-double-up"></em>
                    <svg width="150" height="50" viewBox="0 0 39.687499 13.229167">
                        <path d="M-.0035 296.999c9.007 0 11.8302-13.1833 19.8324-13.1852C27.8372 283.8118 30.636 297 39.683 297c9.047 0-48.6935-.001-39.6864-.001z"
                            transform="translate(0,-283.77081)" />
                    </svg>
                </a>
                <div className="container">
                    <div className="widget-area">
                        <div className="widget widget_text widget_logo">
                            <h4 className="widget-title"><img src={mainLogo} width="209" height="18" alt="CrowdInvest" /><span className="sr-only">CrowdInvest</span></h4>
                            <div className="textwidget">
                                <p>Chúng tôi cung cấp các sản phẩm đầu tư bất động sản độc đáo và khác biệt. Bạn có thể đầu tư bất động sản với số tiền từ 1 triệu đồng và có thể bán lại bất kỳ lúc nào.</p>
                                <nav className="social-navigation">
                                    <ul id="social-menu" className="menu">
                                        <li><a href="https://linkedin.com"><span className="screen-reader-text">Instagram profile</span></a></li>
                                        <li><a href="https://facebook.com"><span className="screen-reader-text">Facebook profile</span></a></li>
                                        <li><a href="https://twitter.com"><span className="screen-reader-text">Twitter profile</span></a></li>
                                        <li><a href="https://youtube.com"><span className="screen-reader-text">YouTube page</span></a></li>
                                        <li><a href="https://instagram.com"><span className="screen-reader-text">Instagram profile</span></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="widget widget_text">
                            <h4 className="widget-title">Liên hệ với <span className="low-opacity">chúng tôi</span></h4>
                            <div className="textwidget">
                                <p>
                                    <strong>Trụ sở Hà Nội:</strong> Số 6 Lê Văn Thiêm, Thanh Xuân, Hà Nội.
                                    <br />
                                    <strong>Số điện thoại:</strong> 024 73098383
                                    <br />
                                    <strong>Email:</strong> <a href="mailto:contact@fmax.com.vn">contact@fmax.com.vn</a>
                                    {/* <img className="office-country" src={require('../../frontend/assets/images/ee.svg').default} width="24" alt="" /> 
                                    <strong>Estonia:</strong> Str. 2, Tallinn, +372 6891 1199
                                    <br />
                                    <img className="office-country" src={require('../../frontend/assets/images/lt.svg').default} width="24" alt="" /> 
                                    <strong>Lithuania:</strong> Str. 4, Vilnius, +370 6891 1199 */}
                                </p>
                                <p>
                                    <strong>Company:</strong> Fmax Tech
                                    <br />
                                </p>
                            </div>
                        </div>
                        <div className="widget widget_nav_menu">
                            <h4 className="widget-title">Site <span className="low-opacity">map</span></h4>
                            <nav className="footer-useful-links">
                                <ul className="menu">
                                    <li><a href="/du-an-dau-tu">Đầu tư</a></li>
                                    <li><a href="/gioi-thieu">Giới thiệu</a></li>
                                    <li><a href="/tuyen-dung">Tuyển dụng</a></li>
                                    <li><a href="/tin-tuc">Tin tức</a></li>
                                    <li><a href="/">Thống kê</a></li>
                                </ul>
                                <ul className="menu">
                                    <li><a href="/tro-giup">Hướng dẫn</a></li>
                                    {/* <li><a href="help.html">Tìm hiểu</a></li> */}
                                    <li><a href="statistics.html">Báo cáo</a></li>
                                    {/* <li><a href="affiliate.html">Chương trình liên kết</a></li> */}
                                    <li><a href="/lien-he">Liên hệ</a></li>
                                    <li><a href="/chinh-sach-bao-mat">Chính sách bảo mật</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <aside className="site-footer-bottom">
                        <div className="site-footer-credits">Công ty Cổ phần Đầu tư Công nghệ Fmax</div>
                        <div className="site-footer-cookies">Bằng cách sử dụng trang web này, bạn đồng ý <a href="/abc">sử dụng cookie</a> theo chính sách cookie.</div>
                    </aside>
                </div>
            </footer>


        </div>
        <Modal show={showLogin} onHide={() => setShowLogin(false)} animation={true} size="md">
            <Modal.Header closeButton>
                <Modal.Title className='w-100 text-center'>Đăng nhập</Modal.Title>
            </Modal.Header>
            <Modal.Body className="body-image-box">
                <form className="w-100" method="post" onSubmit={formikLogin.handleSubmit}>
                    <div className='col-12'>
                        <div className="col-12 form-group mt-3">
                            <label>Tên đăng nhập</label>
                            <input type="text" placeholder="Email hoặc số điện thoại" className="form-control height32" {...formikLogin.getFieldProps("username")} />
                            <AlertErrorForm formik={formikLogin} name="username"></AlertErrorForm>
                        </div>
                        <div className="col-12 form-group mt-3">
                            <label>Mật khẩu</label>
                            <input type="password" placeholder="Mật khẩu" className="form-control height32"  {...formikLogin.getFieldProps("password")} />
                            <AlertErrorForm formik={formikLogin} name="password"></AlertErrorForm>
                        </div>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type="submit" className="btn btn-sm btn-success text-white mr-1">{loading ? <Spinner /> : <>Đăng nhập</>}</button>
                            <button type="button" className="btn btn-sm btn-info text-white" onClick={() => { setShowRegist(true); setShowLogin(false) }}>Đăng ký</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showRegist} onHide={() => setShowRegist(false)} animation={true} size="md">
            <Modal.Header closeButton>
                <Modal.Title className='w-100 text-center'>Đăng ký</Modal.Title>
            </Modal.Header>
            <Modal.Body className="body-image-box">
                <form className="w-100" method="post" onSubmit={formikRegist.handleSubmit} autoComplete="nope">
                    <div className='col-12'>
                        <div className="col-12 form-group mt-3">
                            <label>Họ tên</label>
                            <input type="text" placeholder="Họ tên" className="form-control height32" {...formikRegist.getFieldProps("fullname")} autoComplete="off" />
                            <AlertErrorForm formik={formikRegist} name="fullname"></AlertErrorForm>
                        </div>
                        <div className="col-12 form-group mt-3">
                            <label>Email</label>
                            <input type="text" placeholder="Email" className="form-control height32" {...formikRegist.getFieldProps("email")} autoComplete="off" />
                            <AlertErrorForm formik={formikRegist} name="email"></AlertErrorForm>
                        </div>
                        <div className="col-12 form-group mt-3">
                            <label>Số điện thoại</label>
                            <input type="text" placeholder="Số điện thoại" className="form-control height32"  {...formikRegist.getFieldProps("mobile")} autoComplete="off" />
                            <AlertErrorForm formik={formikRegist} name="mobile"></AlertErrorForm>
                        </div>
                        <div className="col-12 form-group mt-3">
                            <label>Ngày sinh</label>
                            <input type="date" name="birthday" className="underline"  {...formikRegist.getFieldProps("birthday")} />
                            <AlertErrorForm formik={formikRegist} name="birthday"></AlertErrorForm>
                        </div>
                        <div className="col-12 form-group mt-3">
                            <label>Giới tính</label><br />
                            <span>
                                <input type="radio" name="picked" value="Nam" checked={formikRegist.values.sex === "Nam"} onChange={(e) => formikRegist.setFieldValue('sex', e.target.value)} />
                                Nam
                            </span>
                            <span className='ml-2'>
                                <input type="radio" name="picked" value="Nữ" checked={formikRegist.values.sex === "Nữ"} onChange={(e) => formikRegist.setFieldValue('sex', e.target.value)} />
                                Nữ
                            </span>
                        </div>
                        <div className="col-12 form-group mt-3">
                            <label>Mật khẩu</label>
                            <input type="password" placeholder="Mật khẩu" className="form-control height32"  {...formikRegist.getFieldProps("password")} autoComplete="off" />
                            <AlertErrorForm formik={formikRegist} name="password"></AlertErrorForm>
                        </div>
                        <div className="col-12 form-group mt-3">
                            <label>Xác nhận mật khẩu</label>
                            <input type="password" placeholder="Xác nhận mật khẩu" className="form-control height32"  {...formikRegist.getFieldProps("repassword")} autoComplete="off" />
                            <AlertErrorForm formik={formikRegist} name="repassword"></AlertErrorForm>
                        </div>
                        <ReCAPTCHA
                            sitekey="6LfwSF0hAAAAAC-adDEFRrqHps2Na9Xk-8DPzB-V"
                            onChange={onCapchaChange}
                        />
                        <div className='col-12 d-flex justify-content-end'>
                            <button type="submit" className="btn btn-sm btn-success text-white">{loading ? <Spinner /> : <>Đăng ký</>}</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>


    </>
    );
}
