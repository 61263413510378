import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainFrontend from "./template/Main";
import * as Yup from "yup";
import { URL_LIST_ALL } from "../configs/api";
import axios from "axios";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import { confirmAlert } from "react-confirm-alert";

export default function AccountProfile() {
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(null)
    const [dateSelect, setDateSelect] = useState({
        birthday: null, cccd_date: null
    });

    useEffect(() => {
        if (user && user.data) {
            let mounted = true;
            axios.get(URL_LIST_ALL + "profile/" + user.data.id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        // console.log(res.data.data)
                        if (mounted) {
                            if (res.data.data) {
                                setDetail(res.data.data)
                                const dataif = res.data.data
                                setDateSelect({
                                    birthday: dataif.birthday, cccd_date: dataif.cccd_date
                                })
                            }
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            return () => (mounted = false);
        }

    }, [user]);

    const defaultValue = {
        fullname: "",
        email: "",
        mobile: "",
        sex: "",
        address: "",
        nation: "",
        birthday: "",
        cccd: "",
        cccd_date: "",
        cccd_address: "",
        address_tc: "",
        bank_id: "",
        bank_name: "",
        bank_address: "",
        bank_master: ''
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultValue,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            axios.put(URL_LIST_ALL + "profile/" + user.data.id, values, user.config).then((res) => {
                if (res.data.status === "success") {
                    confirmAlert({
                        title: 'Thông báo',
                        message: 'Bạn cập nhập thông tin cá nhân thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else{
                    console.log(res.data.message);
                    confirmAlert({
                        title: 'Thông báo',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                } 
            }).catch((err) => console.log(err));
        }
    })

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };


    return (
        <MainFrontend>
            {user && user.data ?
                <>
                    <header id="featured" className="site-featured">
                        <div id="featured-media" className="featured-media">
                            <div className="featured-text">
                                <div className="container">
                                    <h2 className="entry-title">Thông tin cá nhân</h2>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div id="content" className="site-content">
                        <main className="site-section section-main">
                            <div className="container">
                                <h3>Bạn có thể xem và sửa thông tin cá nhân</h3>
                                <div className="">
                                    <form className="row new-added-form" method="post" onSubmit={formik.handleSubmit}>
                                        <div className="w100 spacing1"></div>

                                        <div className="w100 spacing1"></div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h5>Thông tin cá nhân</h5>
                                        </div>
                                        <div className="col-12 col-md-8 col-lg-6">
                                            <label>Họ và tên</label>
                                            <input type="text" id="first-name" name="first-name" className="underline"  {...formik.getFieldProps("fullname")} />

                                            <label>Ngày sinh</label>
                                            {/* <input type="date" name="birthday" className="underline"  {...formik.getFieldProps("birthday")} /> */}
                                            <DateTimePicker locale={"vi-VI"} onChange={(value) => { handleDate('birthday', value) }} dayPlaceholder="ngày" monthPlaceholder="tháng" yearPlaceholder="năm" value={dateSelect.birthday ? new Date(dateSelect.birthday) : null} format="dd/MM/y" className="air-datepicker underline"></DateTimePicker>

                                            <label>Giới tính</label><br />
                                            <div className="underline">
                                                <span>
                                                    <input type="radio" name="picked" value="Nam" checked={formik.values.sex === "Nam"} onChange={(e) => formik.setFieldValue('sex', e.target.value)} />
                                                    Nam
                                                </span>
                                                <span className='ml-2'>
                                                    <input type="radio" name="picked" value="Nữ" checked={formik.values.sex === "Nữ"} onChange={(e) => formik.setFieldValue('sex', e.target.value)} />
                                                    Nữ
                                                </span>
                                                <span className='ml-2'>
                                                    <input type="radio" name="picked" value="Khác" checked={formik.values.sex === "Khác"} onChange={(e) => formik.setFieldValue('sex', e.target.value)} />
                                                    Khác
                                                </span>
                                            </div>

                                            <label className="mt-3">Số CMND / CCCD / Hộ chiếu </label>
                                            <input type="text" name="cccd" className="underline" {...formik.getFieldProps("cccd")} />

                                            <label>Ngày cấp </label>
                                            {/* <input type="date" name="cccd_date" className="underline" {...formik.getFieldProps("cccd_date")} /> */}
                                            <DateTimePicker locale={"vi-VI"} onChange={(value) => { handleDate('cccd_date', value) }} dayPlaceholder="ngày" monthPlaceholder="tháng" yearPlaceholder="năm" value={dateSelect.cccd_date ? new Date(dateSelect.cccd_date) : null} format="dd/MM/y" className="air-datepicker underline"></DateTimePicker>
                                            <label>Nơi cấp </label>
                                            <input type="text" name="cccd_address" className="underline" {...formik.getFieldProps("cccd_address")} />

                                            <label>Địa chỉ thường trú</label>
                                            <input type="text" name="address" className="underline" {...formik.getFieldProps("address_tc")} />

                                            <label>Địa chỉ liên hệ</label>
                                            <input type="text" id="address" name="address" className="underline" {...formik.getFieldProps("address")} />

                                            <label>Email</label>
                                            <input type="email" id="email" name="email" className="underline" {...formik.getFieldProps("email")} />

                                            <label>Phone</label>
                                            <input type="tel" id="phone" name="phone" className="underline" {...formik.getFieldProps("mobile")} />
                                        </div>
                                        {/* <div className="w100 spacing1"></div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h5>Thông tin liên hệ</h5>
                                        </div>
                                        <div className="col-12 col-md-8 col-lg-6"> */}

                                        {/* <label for="country">Country</label>
                                            <select id="country" name="country" className="underline">
                                                <option value="lv">Latvia</option>
                                                <option value="es" selected="">Estonia</option>
                                                <option value="lt">Lithuania</option>
                                            </select> */}
                                        {/* <label for="city">City</label>
                                            <input type="text" id="city" name="city" className="underline" value="Tallinn" /> */}

                                        {/* </div> */}
                                        <div className="w100 spacing1"></div>
                                        <div className="col-12 col-md-4 col-lg-3">
                                            <h5>Thông tin ngân hàng</h5>
                                        </div>
                                        <div className="col-12 col-md-8 col-lg-6">
                                            <label>Chủ tài khoản</label>
                                            <input type="text" id="bank_master" name="bank_master" className="underline" {...formik.getFieldProps("bank_master")} />
                                            <label>Số tài khoản</label>
                                            <input type="text" id="bank_id" name="bank_id" className="underline" {...formik.getFieldProps("bank_id")} />
                                            <label>Tên ngân hàng</label>
                                            <input type="text" id="bank_name" name="bank_name" className="underline" {...formik.getFieldProps("bank_name")} />
                                            <label>Tên chi nhánh</label>
                                            <input type="text" id="bank_address" name="bank_address" className="underline" {...formik.getFieldProps("bank_address")} />
                                        </div>
                                        <div className="w100 spacing1"></div>
                                        {/* <div className="col-12 col-md-4 col-lg-3">
                                            <h5>Thay đổi mật khẩu</h5>
                                        </div> */}
                                        {/* <div className="col-12 col-md-8 col-lg-6">
                                            <label for="current-password">Mật khẩu hiện tại</label>
                                            <input type="password" id="current-password" name="current-password" className="underline" />
                                            <label for="new-password">Mật khẩu mới</label>
                                            <input type="password" id="new-password" name="new-password" className="underline" />
                                            <label for="confirm-password">Nhập lại mật khẩu mới</label>
                                            <input type="password" id="confirm-password" name="confirm-password" className="underline" />
                                        </div> */}
                                        <div className="w100 spacing1"></div>
                                        <div className="col-12 col-md-8 col-lg-6 offset-md-4 offset-lg-3">
                                            <button type="submit">Lưu thay đổi</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </main>
                    </div>
                </>
                :
                <h3 className="w-100 text-center mt-3">Bạn cần đăng nhập để sử dụng chức năng này</h3>
            }

        </MainFrontend>
    );
}
