import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import SearchBoxModal from "../component/SearchBoxModal";
import AlertErrorForm from "../component/AlertErrorForm";

export default function FormSLSPDaBan() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);

    const defaultdetail = {
        author_id: user.data.id,
        product_id: '',
        meta_name: 'Đã bán',
        alias: 'daban',
        meta_value: '',
        status: 0
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "product_meta/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            // console.log(res.data.data)
                            if (mounted) {
                                let data = res.data.data
                                if(!data.checker_comment) data.checker_comment = ''
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );
    let validForm = {
        product_id: Yup.string().trim().nullable().required('Vui lòng chọn sản phẩm'),
        meta_value: Yup.string().trim().nullable().required('Nhập số lượng')
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            delete values["id"];
            setLoading(true)
            console.log(values);
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'product_meta/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        setAutoRefresh(!autoRefresh)
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'product_meta', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });


    return (
        <AkkhorLayout idrole="customer">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/admin/product_meta">Quản lý số lượng sản phẩm đã bán</Link>
                    </li>
                    <li>{id ? 'Sửa số lượng đã bán' : 'Thêm mới số lượng đã bán'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} số lượng đã bán</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            {id > 0 && detail && detail.status === 1 ?
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td><b>Tên sản phẩm:</b> </td>
                                            <td>{detail.title ? detail.title : ''}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Mã sản phẩm:</b> </td>
                                            <td>{detail.code ? detail.code : ''}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Số lượng đã bán:</b> </td>
                                            <td>{detail.meta_value ? detail.meta_value : ''}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Người nhập liệu:</b> </td>
                                            <td>{detail.author_name ? detail.author_name : ''}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Người phê duyệt:</b> </td>
                                            <td>{detail.check_name ? detail.check_name : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <>
                                    <div className="col-xl-6 col-lg-6 col-12 form-group">
                                        <label>Sản phẩm</label>
                                        <SearchBoxModal apiname={"sanpham"} placeholder="Chọn sản phẩm" colvalue={'sanpham.id'} colname={'sanpham.title'} defaultValue={formik.values['product_id']} name='product_id'
                                            onChange={async (e) => {
                                                await formik.setFieldValue('product_id', e)
                                            }}></SearchBoxModal>
                                        <AlertErrorForm formik={formik} name="product_id"></AlertErrorForm>
                                    </div>
                                    <div className="col-12"></div>
                                    <div className="col-xl-6 col-lg-6 col-12 form-group">
                                        <label>Số lượng</label>
                                        <input type="text" placeholder="Số lượng" className="form-control height32" {...formik.getFieldProps("meta_value")} autoComplete="off" />
                                        <AlertErrorForm formik={formik} name="meta_value"></AlertErrorForm>
                                    </div>
                                    <div className="col-12"></div>
                                    <div className="col-12 form-group mg-t-8">
                                        <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                        <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                                    </div>
                                </>
                            }

                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
