import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";
import { Link } from "react-router-dom";
import MyEditor2 from "../component/MyEditor2";
import SerchBoxMulti from "../component/SerchBoxMulti";
import UploadModal from "../component/UploadModal";
import UserDropDown from "../component/UserDropDown";

export default function FormSanPham() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [spThumb, setSpThumb] = useState(null);
    const [gallery, setGallery] = useState([]);
    const [dateSelect, setDateSelect] = useState({
        datestart: null, dateend: null, dateend_interest: null, datemoban: null,datestart_interest:null
    });
    const [listKyHan, setListKyHan] = useState([])
    const [kyHan, setKyHan] = useState('')
    const [laiSuat, setLaiSuat] = useState('')

    const defaultdetail = {
        author_id: user.data.id,
        title: '',
        alias: '',
        code: '',
        type: 1,
        soluong: '',
        sldadautu: '',
        description: '',
        body_content: '',
        price: '',
        interest_rate: '',
        ls_thuong: '',
        thumbnail: '',
        gallery: '',
        daban: '',
        ttgoc: 'Cuối kỳ',
        calc_interest: 0,
        status: 1
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "sanpham/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            // console.log(res.data.data)
                            if (mounted) {
                                setDetail(res.data.data);
                                const dataif = res.data.data
                                setSpThumb(dataif.thumbnail)
                                setGallery(JSON.parse(dataif.gallery))
                                if (dataif.kyhanlai) setListKyHan(JSON.parse(dataif.kyhanlai))
                                setDateSelect({
                                    dateend_interest: dataif.dateend_interest, datestart: dataif.datestart, dateend: dataif.dateend, datemoban: dataif.datemoban,datestart_interest: dataif.datestart_interest
                                })
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );
    let validForm = {
        title: Yup.string().trim().nullable().required('Nhập tên sản phẩm').max(250, "Độ dài không quá 250 ký tự"),
        code: Yup.string().trim().nullable().required('Nhập mã sản phẩm'),
        soluong: Yup.string().trim().nullable().required('Vui lòng nhập số lượng'),
        time_invest: Yup.string().trim().nullable().required('Vui lòng nhập thời gian đầu tư'),
        type: Yup.string().trim().nullable().required('Chọn loại sản phẩm')
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            delete values["id"];

            setLoading(true)
            values.thumbnail = spThumb
            values.gallery = gallery
            values.kyhanlai = listKyHan
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'sanpham/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        setAutoRefresh(!autoRefresh)
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật sản phẩm thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'sanpham', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới sản phẩm thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    const actionListFile = (data) => {
        let newData = gallery.slice();
        data.forEach(item => {
            newData.push(item)
        });
        setGallery(newData);
    }

    const deleteItem = (id) => {
        let newList = []
        gallery.forEach((item, i) => {
            if (i !== id) newList.push(item)
        })
        setGallery(newList)
    }

    const addKyHan = () => {
        let err = 0
        if (!kyHan || kyHan === '') {
            err = 1;
            alert('Vui lòng nhập kỳ hạn');
        }
        if (!laiSuat || laiSuat === '') {
            err = 1;
            alert('Vui lòng nhập lãi suất');
        }
        if (err === 0) {
            let newdata = { kyhan: kyHan, laisuat: laiSuat }
            let newList = listKyHan.slice()
            newList.push(newdata)
            setListKyHan(newList)
            setKyHan('')
            setLaiSuat('')
        }
    }

    const removeItem = (i) => {
        let newList = listKyHan.slice()
        let newData = []
        newList.forEach((item, index) => {
            if (index !== i) newData.push(item)
        });
        setListKyHan(newData)
    }

    const editItem = (key, value, i) => {
        let newList = listKyHan.slice()
        let newData = []
        newList.forEach((item, index) => {
            if (index !== i) {
                newData.push(item)
            } else {
                if (key === 'kyhan') newData.push({ kyhan: value, laisuat: item.laisuat })
                if (key === 'laisuat') newData.push({ kyhan: item.kyhan, laisuat: value })
            }
        });
        setListKyHan(newData)
    }

    return (
        <AkkhorLayout idrole="san-pham">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/admin/san-pham">Danh sách sản phẩm</Link>
                    </li>
                    <li>{id ? 'Sửa sản phẩm' : 'Thêm mới sản phẩm'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} Sản phẩm</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Tên sản phẩm <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Mã sản phẩm <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("code")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="code"></AlertErrorForm>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Giá <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("price")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="price"></AlertErrorForm>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Loại sản phẩm</label>
                                <select className="form-select height32" {...formik.getFieldProps("type_interest")}>
                                    <option value="Lãi suất cố định">Lãi suất cố định</option>
                                    <option value="Lãi suất thả nổi">Lãi suất thả nổi</option>
                                    <option value="Cam kết lãi suất tối thiểu">Cam kết lãi suất tối thiểu</option>
                                </select>
                            </div>
                            {formik.values['type_interest'] !== 'Lãi suất thả nổi' &&
                                <div className="col-xl-4 col-lg-6 col-12 form-group">
                                    <label>Lãi suất {formik.values['type_interest'] === 'Cam kết lãi suất tối thiểu' && 'tối thiểu'} (%)</label>
                                    <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("interest_rate")} autoComplete="off" />
                                    <AlertErrorForm formik={formik} name="interest_rate"></AlertErrorForm>
                                </div>
                            }
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Lãi suất thưởng <small style={{ fontSize: 10 }}>(trên mỗi sản phẩm bán được)</small></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("ls_thuong")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="ls_thuong"></AlertErrorForm>
                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Số lượng lưu hành<span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("soluong")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="soluong"></AlertErrorForm>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Thời gian đầu tư (tháng)<span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("time_invest")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="time_invest"></AlertErrorForm>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Số lượng đã đầu tư</label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("sldadautu")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="sldadautu"></AlertErrorForm>
                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày phát hành </label>
                                <DateTimePicker onChange={(value) => { handleDate('datestart', value) }} value={dateSelect.datestart ? new Date(dateSelect.datestart) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày hết hạn đăng ký đầu tư</label>
                                <DateTimePicker onChange={(value) => { handleDate('dateend', value) }} value={dateSelect.dateend ? new Date(dateSelect.dateend) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày bắt đầu hợp đồng</label>
                                <DateTimePicker onChange={(value) => { handleDate('datemoban', value) }} value={dateSelect.datemoban ? new Date(dateSelect.datemoban) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày kết thúc hợp đồng</label>
                                <DateTimePicker onChange={(value) => { handleDate('dateend_interest', value) }} value={dateSelect.dateend_interest ? new Date(dateSelect.dateend_interest) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>

                            <div className="col-12"></div>
                            <hr />
                            <h5><strong>Lãi suất rút sớm</strong></h5>

                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Thời gian (tháng)</label>
                                <input type="text" placeholder="VD: 03-05" className="form-control height32" value={kyHan} onChange={(e) => setKyHan(e.target.value)} autoComplete="off" />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Lãi suất (%/năm)</label>
                                <input type="text" placeholder="6.5" className="form-control height32" value={laiSuat} onChange={(e) => setLaiSuat(e.target.value)} autoComplete="off" />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>#</label>
                                <button type="button" className="btn btn-sm btn-success" onClick={() => { addKyHan() }}>Thêm</button>
                            </div>

                            {listKyHan && listKyHan.length > 0 && listKyHan.map((item, i) => {
                                return (
                                    <div className="col-12" key={i}>
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                                <input type="text" placeholder="VD: 03-05" className="form-control height32" value={item.kyhan} onChange={(e) => editItem('kyhan', e.target.value, i)} autoComplete="off" />
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                                <input type="text" placeholder="6.5" className="form-control height32" value={item.laisuat} onChange={(e) => editItem('laisuat', e.target.value, i)} autoComplete="off" />
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                                <button type="button" className="btn btn-sm btn-danger" onClick={() => { removeItem(i) }}>Xóa</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <h5><strong>Công thức tính lãi</strong></h5>
                            <div className="col-12 form-group">
                                <textarea className="form-control" {...formik.getFieldProps("congthuc")}></textarea>
                            </div>
                            <hr />
                            <div className="col-12"></div>
                            <div className="col-12 form-group">
                                <label>Mô tả sản phẩm</label>
                                <textarea className="form-control" {...formik.getFieldProps("description")}></textarea>
                            </div>
                            <div className="col-12 form-group">
                                <label>Chi tiết sản phẩm</label>
                                <MyEditor2 height={350} key={Math.random()} initialValue={formik.values.body_content}
                                    onValue={(value) => value && formik.setFieldValue("body_content", value)}
                                />
                            </div>

                            <div className="col-lg-8 col-12 form-group">
                                <label>Ảnh đại diện</label>
                                <UploadModal defaultValue="" multiple="false" button_title="Tải lên ảnh đại diện" onSellect={(val) => { setSpThumb(val[0]) }} />
                                <div className="row list-file mt-3">
                                    {spThumb &&
                                        <div className="col-3">
                                            <img src={spThumb} alt="" />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-8 col-12 form-group">
                                <label>Ảnh sản phẩm</label>
                                <UploadModal defaultValue="" multiple="false" button_title="Tải lên ảnh sản phẩm" onSellect={(val) => { actionListFile(val) }} />
                                <div className="row list-file mt-3">
                                    {gallery && gallery.map(function (item, i) {
                                        return <div className="col-3" key={i}>
                                            <img src={item} alt="" className="imageforupload" />
                                            <div className="w-100 deletegroup form-group">
                                                <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteItem(i)}>
                                                    <span className="fas fa-trash-alt"></span>
                                                </button>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className="col-12 form-group">
                                <label>Dự án: </label>
                                <SerchBoxMulti apiname={"duan"} placeholder="Chọn dự án" colvalue={'id'} colname={'title'} defaultValue={formik.values['duan']} name='duan'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('duan', e)
                                    }}></SerchBoxMulti>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Tỉnh thành</label>
                                <UserDropDown apiname='params?idtype=1&idparent=0&limit=1000&orderby_esc=id' colvalue="shorttitle" colname="title" className="w-100" placeholder="Chọn tỉnh thành" defaultValue={formik.values['city']} name="listCity"
                                    onChange={async (e) => { await formik.setFieldValue('city', e) }} all={true}
                                ></UserDropDown>
                            </div>
                            <div className="col-xl-8 col-lg-6 col-12 form-group">
                                <label>Địa chỉ</label>
                                <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("address")} autoComplete="off" />

                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trạng thái: </label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>
                                    <option value="4" >Đã kết thúc</option>
                                    <option value="3" >Sắp gọi đầu tư</option>
                                    <option value="2" >Đang gọi đầu tư</option>
                                    <option value="1" >Đã gọi đầu tư</option>
                                    <option value="0" >Ngưng hoạt động</option>
                                </select>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trạng thái tính lãi suất: </label>
                                <select className="form-select height32" {...formik.getFieldProps("calc_interest")}>
                                    <option value="1" >Kích hoạt tính lãi</option>
                                    <option value="0" >Ngưng kích hoạt tính lãi</option>
                                </select>
                            </div>

                            {formik.values['type_interest'] !== 'Lãi suất thả nổi' &&
                                <div className="col-xl-3 col-lg-6 col-12 form-group">
                                    <label>Ngày bắt đầu tính lãi</label>
                                    <DateTimePicker onChange={(value) => { handleDate('datestart_interest', value) }} value={dateSelect.datestart_interest ? new Date(dateSelect.datestart_interest) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                </div>
                            }

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Hình thức thanh toán gốc: </label>
                                <select className="form-select height32" {...formik.getFieldProps("ttgoc")}>
                                    <option value="Cuối kỳ" >Cuối kỳ</option>
                                </select>
                            </div>

                            <div className="col-12 mt-3" ></div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu dự án</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}

