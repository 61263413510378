import React from "react";
import MainFrontend from "./template/Main";

export default function AboutUs() {

    return (
        <MainFrontend>
            <header id="featured" className="site-featured">
                <div id="featured-media" className="featured-media has-media">
                    <div className="featured-text">
                        <div className="container">
                            <h2 className="entry-title">Về chúng tôi</h2>
                            <p className="tagline">Giới thiệu về chúng tôi.</p>
                        </div>
                    </div>
                    <img src={require('./tmp/sample-header.jpg')} alt="" />
                </div>
            </header>

            <div id="content" className="site-content">
                <main className="site-section section-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <p>
                                    Fmax được thành lập với đam mê, khát khao & nhiệt huyết của những chuyên gia, lãnh đạo trẻ với hơn 10 năm kinh nghiệm trong lĩnh vực bất động sản.
                                    Chúng tôi đã trải qua mọi thăng trầm của thị trường, từ cảm xúc vỡ oà với doanh thu và lợi nhuận cực lớn cho đến cảm giác xót xa chứng kiến thị trường bất động sản Việt Nam đóng băng, dòng tiền tắc nghẽn. Từ đó, chúng tôi rút ra được những kinh nghiệm thực tiễn quý giá, không chỉ từ những thành công vang dội mà từ cả những thất bại xương máu.
                                </p>
                                <p>
                                    Với đam mê & nhiệt huyết, Fmax ra đời hứa hẹn sẽ trở thành cầu nối, là người bạn đồng hành & sát cánh cùng Chủ đầu tư khơi thông dòng chảy bất động sản Việt Nam, mang lại cho khách hàng những bất động sản có giá trị và tính thanh khoản cao.
                                </p>
                                <p className="text-faded text-small">
                                    Mr. Le Xuan Nga, CEO
                                    <br />
                                    <img src={require('./tmp/sample-signature.svg').default} width="108" height="55" alt="" />
                                </p>
                            </div>
                            <div className="col-12 col-lg-6">
                                <p><img src={require('./tmp/about-fmax.jpg')} alt="" /></p>
                            </div>
                        </div>
                        <h2 className="entry-title">Giá trị chính của chúng tôi</h2>
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-3 d-flex">
                                <div className="featured-box">
                                    <em className="featured-box-icon mdi mdi-briefcase-outline"></em>
                                    <h5 className="featured-box-title">Đơn giản</h5>
                                    <p className="featured-box-content">Tìm hiểu thông tin về sản phẩm, chọn sản phẩm bạn muốn đầu tư và đăng ký đầu tư.</p>
                                    {/* <a href="/abc" className="button button-primary button-small button-outline">Learn more <em className="mdi mdi-trending-neutral"></em></a> */}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 d-flex">
                                <div className="featured-box">
                                    <em className="featured-box-icon mdi mdi-currency-eur"></em>
                                    <h5 className="featured-box-title">Minh bạch</h5>
                                    <p className="featured-box-content">Giá trị đầu tư được cập nhật hàng ngày. Giá mua lại được niêm yết công khai cho tất cả nhà đầu tư.</p>
                                    {/* <a href="/abc" className="button button-primary button-small button-outline">Learn more <em className="mdi mdi-trending-neutral"></em></a> */}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 d-flex">
                                <div className="featured-box">
                                    <em className="featured-box-icon mdi mdi-account-tie-outline"></em>
                                    <h5 className="featured-box-title">Chuyên nghiệp</h5>
                                    <p className="featured-box-content">Giao dịch nhanh gọn và thuận tiện, có thể tiến hành trực tiếp và online theo cách thuận tiện nhất cho bạn.</p>
                                    {/* <a href="/abc" className="button button-primary button-small button-outline">Learn more <em className="mdi mdi-trending-neutral"></em></a> */}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 d-flex">
                                <div className="featured-box">
                                    <em className="featured-box-icon mdi mdi-security"></em>
                                    <h5 className="featured-box-title">An toàn</h5>
                                    <p className="featured-box-content">Bạn có thể chuyển nhượng lại khoản đầu tư bất kỳ lúc nào trong thời gian đầu tư.</p>
                                    {/* <a href="/abc" className="button button-primary button-small button-outline">Learn more <em className="mdi mdi-trending-neutral"></em></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="site-section section-team dotted-pattern dotted-pattern-top-right">
                    <div className="container">
                        <h2 className="entry-title">Đội ngũ của chúng tôi</h2>
                        <p>Thật tự hào khi nói rằng nhiều gương mặt thân thiện này đã ở bên chúng tôi ngay từ những ngày đầu thành lập.</p>
                        <div className="row">
                            <div className="col-6 col-lg-3">
                                <div className="team-member">
                                    <img src={require('./assets/images/leader/nguyen-tho-tuyen.jpg')} alt="" />
                                    <h5 className="team-member-title">Nguyễn Thọ Tuyển</h5>
                                    <p className="team-member-position text-faded">Chủ tịch HĐQT</p>
                                    <nav className="team-member-links social-navigation">
                                        <ul className="menu">
                                            <li><a href="https://facebook.com"><span className="screen-reader-text">Facebook profile</span></a></li>
                                            <li><a href="mailto:contact@crowdinvest.com"><span className="screen-reader-text">Email Address</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="team-member">
                                    <img src={require('./assets/images/leader/Truong-hung-cuong.jpg')} alt="" />
                                    <h5 className="team-member-title">Trương Hùng Cường</h5>
                                    <p className="team-member-position text-faded">Phó chủ tịch HĐQT</p>
                                    <nav className="team-member-links social-navigation">
                                        <ul className="menu">
                                            <li><a href="https://linkedin.com"><span className="screen-reader-text">LinkedIn profile</span></a></li>
                                            <li><a href="mailto:contact@crowdinvest.com"><span className="screen-reader-text">Email Address</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="team-member">
                                    <img src={require('./assets/images/leader/can-cong-viet.jpg')} alt="" />
                                    <h5 className="team-member-title">Cấn Công Việt</h5>
                                    <p className="team-member-position text-faded">Phó chủ tịch HĐQT</p>
                                    <nav className="team-member-links social-navigation">
                                        <ul className="menu">
                                            <li><a href="https://twitter.com"><span className="screen-reader-text">Twitter profile</span></a></li>
                                            <li><a href="mailto:contact@crowdinvest.com"><span className="screen-reader-text">Email Address</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="team-member">
                                    <img src={require('./assets/images/leader/le-xuan-nga.jpg')} alt="" />
                                    <h5 className="team-member-title">Lê Xuân Nga</h5>
                                    <p className="team-member-position text-faded">Phó chủ tịch HĐQT</p>
                                    <nav className="team-member-links social-navigation">
                                        <ul className="menu">
                                            <li><a href="https://linkedin.com"><span className="screen-reader-text">LinkedIn profile</span></a></li>
                                            <li><a href="mailto:contact@crowdinvest.com"><span className="screen-reader-text">Email Address</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-section section-video-presentation">
                    <div className="container">
                        <h2 className="entry-title">Tìm hiểu thêm về chúng tôi</h2>
                        <div className="video-wrapper status-pause dotted-pattern dotted-pattern-top-right">
                            <img src={require('./tmp/about-fmax-dong-hanh.jpg')} width='85%' alt="" />
                            <h3 className="video-title">Cách chúng tôi giúp bạn thành công.</h3>
                            <a href="#sample-video" className="video-playback large"><span className="progress"></span><em className="mdi mdi-play"></em><em className="mdi mdi-pause"></em></a>
                        </div>
                    </div>
                </div>
            </div>
        </MainFrontend>
    );
}
