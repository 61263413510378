import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";
import SearchBoxModal from "../component/SearchBoxModal";
import { Link } from "react-router-dom";
import { checkRole } from "../model";

export default function FormUsersPage() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [dateSelect, setDateSelect] = useState({
        birthday: null, cccd_date: null, birthday_child_1: null, birthday_child_2: null, birthday_child_3: null, datejoining: null, ngaynghiviec: null,
        datehdtv_start: null, datehdtv_end: null, datehdld_start_1: null, datehdld_end_1: null, datehdld_start_2: null, datehdld_end_2: null, datehdld_start_3: null, datehdld_end_3: null, hd_status: null
    });
    const allowSetRoles = checkRole(user, 'list-users', 'roles');
    const defaultdetail = {
        author_id: user.data.id,
        fullname: '',
        sex: '',
        mobile: '',
        email: '',
        birthday: '',
        nation: '',
        address: '',
        status: 1,
        role_id: 2,
        typeaccount: 'admin',
        cccd: '',
        dateCCCD: '',
        cccd_address: '',
        address_tc: '',
        dctc: ''
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "users/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            // console.log(res.data.data)
                            if (mounted) {
                                setDetail(res.data.data);
                                const dataif = res.data.data
                                setDateSelect({
                                    birthday: dataif.birthday, cccd_date: dataif.cccd_date, birthday_child_1: dataif.birthday_child_1, birthday_child_2: dataif.birthday_child_2, birthday_child_3: dataif.birthday_child_3, datejoining: dataif.datejoining, ngaynghiviec: dataif.ngaynghiviec,
                                    datehdtv_start: dataif.datehdtv_start, datehdtv_end: dataif.datehdtv_end, datehdld_start_1: dataif.datehdld_start_1, datehdld_end_1: dataif.datehdld_end_1, datehdld_start_2: dataif.datehdld_start_2, datehdld_end_2: dataif.datehdld_end_2, datehdld_start_3: dataif.datehdld_start_3, datehdld_end_3: dataif.datehdld_end_3
                                })
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );
    let validForm = {
        fullname: Yup.string().trim().nullable().required('Nhập họ và tên ').max(150, "Độ dài không quá 150 ký tự"),
        sex: Yup.string().trim().nullable().required('Chọn giới tính'),
        password: Yup.string().trim().nullable().required('Nhập mật khẩu').min(6, "Độ dài mật khẩu từ 6 ký tự trở lên").max(150, "Độ dài không quá 150 ký tự"),
        repassword: Yup.string().trim().nullable().required('Nhập xác nhận mật khẩu').oneOf([Yup.ref('password'), null], 'Xác nhận mật khẩu không chính xác'),

        role_id: Yup.string().trim().nullable().when("typeaccount", (type) => {
            if (type < 2) return Yup.string().required("Chọn quyền hạn tài khoản")
        })
    }

    if (id > 0) {
        delete validForm.username
        delete validForm.password
        delete validForm.repassword
    }


    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            delete values["id"];

            setLoading(true)
            console.log(values);
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'users/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        setAutoRefresh(!autoRefresh)
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật tài khoản thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                values.username = values.email
                Axios.post(URL_LIST_ALL + 'users', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới tài khoản thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    return (
        <AkkhorLayout idrole="list-users">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-users">Quản lý nhân viên</Link>
                    </li>
                    <li>{id ? 'Sửa tài khoản' : 'Thêm mới tài khoản'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} tài khoản</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                            </div>
                           
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Giới tính <span className="text-danger">*</span></label>
                                <select className="form-select height32" {...formik.getFieldProps("sex")}>
                                    <option value="" disabled>Hãy chọn giới tính</option>
                                    <option value="Nam">Nam</option>
                                    <option value="Nữ">Nữ</option>
                                    <option value="Khác">Khác</option>
                                </select>
                                <AlertErrorForm formik={formik} name="sex"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày sinh</label>
                                <DateTimePicker onChange={(value) => { handleDate('birthday', value) }} value={dateSelect.birthday ? new Date(dateSelect.birthday) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                            </div>
                    
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Điện thoại</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} />
                                <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Email <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")} />
                                <AlertErrorForm formik={formik} name="email"></AlertErrorForm>
                            </div>

                            {!id &&
                                <>
                                   
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Mật khẩu <span className="text-danger">*</span></label>
                                        <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("password")} autoComplete="off" />
                                        <AlertErrorForm formik={formik} name="password"></AlertErrorForm>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Nhập lại mật khẩu <span className="text-danger">*</span></label>
                                        <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("repassword")} autoComplete="off" />
                                        <AlertErrorForm formik={formik} name="repassword"></AlertErrorForm>
                                    </div>
                                </>

                            }

                            {allowSetRoles && <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Quyền <span className="text-danger">*</span></label>
                                <SearchBoxModal apiname={"roles"} placeholder="Chọn quyền" colvalue={'id'} colname={'title'} defaultValue={formik.values['role_id']} name='role_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('role_id', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="role_id"></AlertErrorForm>
                            </div>}

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trạng thái: </label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>
                                    <option value="1" >Đang hoạt động</option>
                                    <option value="0" >Ngưng hoạt động</option>
                                </select>
                            </div>

                            <div className="col-12 mt-3" ></div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu tài khoản</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
