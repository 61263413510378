import React, { useState } from "react";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import { Link } from "react-router-dom";

export default function ListEmailBody() {

  const [params, setParam] = useState();
  const [subject, setSubject] = useState('');
  const [filterShow, setFilterShow] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (subject !== '') _param += "&subject=" + subject
   
    setParam(_param);
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "alias", label: "Rút gọn" },
    { name: "subject", label: "Tiêu đề email" },
    { name: "body_content", label: "Nội dung" },
    { name: "status", label: "Trạng thái", width: 250 },
    { name: "datecreate", label: "Ngày tạo" }

  ];

  return (<AkkhorLayout idrole="list-users">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Danh sách nội dung email
        </li>
      </ul>
    </div>

    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
              <label>Tiêu đề mail</label>
              <input type="text" placeholder="Tiêu đề mail" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="email_body" idrole="email-body" linkaction="email-body" params={params} title="nội dung email" defaultLimit={20} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
