import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import Moment from "react-moment";

export default function DuyetSLSPDaBan() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);

    const defaultdetail = {
        checker_comment: '',
        status: 0
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "product_meta/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            // console.log(res.data.data)
                            if (mounted) {
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );
    let validForm = {
        
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            delete values["id"];
            setLoading(true)
            console.log(values);
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'approve_product_meta/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        setAutoRefresh(!autoRefresh)
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const approveProduct = (value) => {
        formik.setFieldValue('status', parseInt(value))
    }

    return (
        <AkkhorLayout idrole="customer">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/admin/product_meta">Quản lý số lượng sản phẩm đã bán</Link>
                    </li>
                    <li>Duyệt sản phẩm</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Duyệt sản phẩm</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-8">
                                {detail &&
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td><b>Tên sản phẩm:</b> </td>
                                                <td>{detail.title ? detail.title : ''}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Mã sản phẩm:</b> </td>
                                                <td>{detail.code ? detail.code : ''}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Số lượng đã bán:</b> </td>
                                                <td>{detail.meta_value ? detail.meta_value : ''}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Người nhập liệu:</b> </td>
                                                <td>{detail.author_name ? detail.author_name : ''}</td>
                                            </tr>
                                            {detail.checker_name &&
                                                <tr>
                                                    <td><b>Người duyệt:</b> </td>
                                                    <td>{detail.checker_name ? detail.checker_name : ''}</td>
                                                </tr>
                                            }
                                            {detail.timecheck &&
                                                <tr>
                                                    <td><b>Duyệt lúc:</b> </td>
                                                    <td>{detail.timecheck &&
                                                        <Moment format="HH:mm DD/MM/YYYY">{new Date(detail.timecheck * 1000)}</Moment>
                                                    }</td>
                                                </tr>
                                            }

                                            <tr>
                                                <td><b>Trạng thái:</b> </td>
                                                <td>
                                                    {detail.status === 1 ?
                                                        <span className="text-success">Đã duyệt</span>
                                                        :
                                                        <div className="check">
                                                            <button type="button" className={"mr-2 btn " + (formik.values['status'] === 1 ? 'btn-success' : 'btn-light')} onClick={() => { approveProduct(1) }}>Phê duyệt</button>
                                                            <button type="button" className={"mr-2 btn " + (formik.values['status'] === 2 ? 'btn-danger' : 'btn-light')} onClick={() => { approveProduct(2) }}>Không duyệt</button>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                            {formik.values['status'] === 2 &&
                                                <tr>
                                                    <td><b>Lý do:</b> </td>
                                                    <td>
                                                    <textarea className="form-control" {...formik.getFieldProps("checker_comment")}></textarea>
                                                    </td>
                                                </tr>
                                            }
                                            {detail.status === 2 &&
                                                <tr>
                                                    <td><b>Lý do:</b> </td>
                                                    <td>{detail.checker_comment ? detail.checker_comment : ''}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                            {detail && detail.status !== 1 &&
                                <div className="col-12 form-group mg-t-8">
                                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                    <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
