import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect,Route } from "react-router-dom";
import { actions } from "../../redux/authRedux";

export default function Logout() {
  const dispatch = useDispatch();
  useEffect(function() {
    dispatch(actions.login(null));
  },[dispatch])

  return <Route exact path="/"><Redirect to="/auth/login" /></Route>;
}
