import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import SearchBoxModal from "../component/SearchBoxModal";

export default function FormChiPhi() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);

    const defaultdetail = {
        product_id: '',
        value: '',
        author_id: user && user.data.id
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "chiphisp/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );
    let validForm = {
        value: Yup.string().trim().nullable().required('Nhập chi phí'),
        product_id: Yup.string().trim().nullable().required('Chọn sản phẩm'),
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            delete values["id"];

            setLoading(true)
            console.log(values);
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'chiphisp/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        setAutoRefresh(!autoRefresh)
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật chi phí thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'chiphisp', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setAutoRefresh(!autoRefresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới chi phí thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="san-pham">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/admin/chiphi">Danh sách chi phí sản phẩm</Link>
                    </li>
                    <li>{id ? 'Sửa chi phí' : 'Thêm mới chi phí'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{!id ? 'Thêm mới' : 'Sửa'} chi phí</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chọn sản phẩm</label>

                                <SearchBoxModal apiname={"sanpham"} placeholder="Chọn sản phẩm" colvalue={'id'} colname={'title'} defaultValue={formik.values['product_id']} name='product_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('product_id', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="product_id"></AlertErrorForm>
                            </div>
                            <div className="col-12" ></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tổng chi phí</label>
                                <input type="text" placeholder="Nhập tổng chi phí" className="form-control height32" {...formik.getFieldProps("value")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="value"></AlertErrorForm>
                            </div>
                            <div className="col-12 mt-3" ></div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
